import Backbone from 'backbone'

var searchModel = Backbone.Model.extend({
  url: 'api/search',
  defaults: {
    stateCrit: null,
    sortOptions: null,
    customercrit: null,
    person_id: null,
    idcrit: null,
    page: 0,
  },
  urlString: function() {
    var array = []
    var string = ''
    if (this.get('page')) {
      array.push('page=' + this.get('page'))
    }
    if (this.get('stateCrit')) {
      array.push('stateCrit=' + this.get('stateCrit'))
    }
    if (this.get('sortOptions')) {
      array.push('sortOptions=' + this.get('sortOptions'))
    }
    if (this.get('customercrit')) {
      array.push('customerCrit=' + this.get('customercrit'))
    }
    if (this.get('person_id')) {
      array.push('person_id=' + this.get('person_id'))
    }
    if (this.get('idcrit')) {
      array.push('idcrit=' + this.get('idcrit'))
    }
    if (array.length > 0) {
      string = array.join('&')
    }
    return string
  },
})

export default searchModel
