import personItemView from 'views/admin/marionette/people/personItemView'
import sharedCustomersItemView from 'templates/admin/marionette/people/peopleComposite.html'

var peopleCompositeView = Backbone.Marionette.CompositeView.extend({
  childView: personItemView,
  childViewContainer: '.peopleList',
  initialize: function() {
    this.sortDebounce = _.debounce(this.doSortNow, 300)
  },
  template: function(serialized) {
    return _.template(sharedCustomersItemView)({
      items: serialized,
    })
  },
  events: {},
  onAddChild: function() {
    this.sortDebounce()
  },
  doSortNow: function() {
    $('.table').tablesorter()
  },
})
export default peopleCompositeView

