import Appregistry from 'libs/appregistry/appregistry'
import personReportCollection from 'collections/admin/personReportCollection'
import reportView from 'templates/admin/report/person-report.html'
import personRow from 'templates/admin/report/person-row.html'
import moment from 'moment'

var singleItem = Backbone.Marionette.View.extend({
  template: function(serialized) {
    var authtoken = Appregistry.models.person.get('authtoken')

    return _.template(personRow)({
      items: serialized,
      authtoken: authtoken,
    })
  },
  tagName: 'tr',
  initialize: function(options) {
    this.parent = options.parent
  },
  modelEvents: {
    'change': 'render',
  },
  onRender: function() {
    var from = $('#from-field').val()
    var to = $('#to-field').val()
    $(window).find([name = 'from'])
    var authtoken = Appregistry.models.person.get('authtoken')

    this.$el.find('.link-to-matkalasku-taxfree').attr('href', '/api/travel-expense/' + this.model.get('id') + '/taxfree/pdf?from=' + from + '&to=' + to + '&authtoken=' + authtoken)
    this.$el.find('.link-to-matkalasku-taxes').attr('href', '/api/travel-expense/' + this.model.get('id') + '/pdf?from=' + from + '&to=' + to + '&authtoken=' + authtoken)
    this.$el.find('.link-to-palkkakortti').attr('href', '/api/admin/report/people/' + this.model.get('id') + '/palkkakortti?from=' + from + '&to=' + to + '&authtoken=' + authtoken)

    return this
  },
})

var adminPersonReportView = Backbone.Marionette.CompositeView.extend({
  childView: singleItem,
  template: function(serialized) {
    var authtoken = Appregistry.models.person.get('authtoken')
    return _.template(reportView, {
      items: serialized,
      authtoken: authtoken,
    })
  },
  childViewContainer: 'tbody',
  onRender: function() {
    $(this.el).find('.date-picker').pickadate(window.helpers.pickadateOptions)
    $(this.el).find('[name=from]').val(moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY'))
    $(this.el).find('[name=to]').val(moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY'))
    return this
  },
  initialize: function() {
    this.collection = new personReportCollection()
  },
  events: {
    'click #calculate-person-report': 'fetchReport',
    'click #download-csv': 'downloadCsv',
  },
  fetchReport: function() {
    var from = $(this.el).find('[name=from]').val()
    var to = $(this.el).find('[name=to]').val()
    var state = $(this.el).find('[name=state]').val()

    var deferred = this.collection.fetch({
      data: $.param({
        from: $(this.el).find('[name=from]').val(),
        to: $(this.el).find('[name=to]').val(),
        state: $(this.el).find('[name=state]').val(),
      }),
    })
    var csvLink = '/api/admin/report/people/csv?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to + '&state=' + state
    var link = $('<a>Lataa csv</a>')
    link.attr('href', csvLink)
    this.$el.find('.csv-link').html(link)

    var taxLink = '/api/admin/report/tax?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to + '&state=' + state
    var taxLinkHtml = $('<a>Lataa veroraportti</a>')
    taxLinkHtml.attr('href', taxLink)
    this.$el.find('.tax-link').html(taxLinkHtml)

  },
  downloadCsv: function() {
    this.collection.fetch({
      data: $.param({
        from: $(this.el).find('[name=from]').val(),
        to: $(this.el).find('[name=to]').val(),
        state: $(this.el).find('[name=state]').val(),
      }),
    })
      .then(function(data) {
        var fields = Object.keys(data[0])
        var header = fields.join(';') + '\n'
        var replacer = function(key, value) {
          return value === null ? '' : value.replace('')
        }
        var csv = header + data.map(function(row) {
          return fields.map(function(fieldName) {
            return JSON.stringify(row[fieldName], replacer)
          }).join(';')
        }).join('\n')
        window.open('data:text/csv;charset=utf-8,' + encodeURIComponent(csv))

      })

  },
})


export default adminPersonReportView

