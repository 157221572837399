import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import './styles.css';
export const EditPerson = ({ personId, setPersonId, token }) => {
  const open = !!personId
  const [person, setPerson] = useState({})

  // Function to load options from your API
  const loadProfessionOptions = (inputValue, callback) => {
    axios
      .get(
        `https://data.stat.fi/api/classifications/v2/search/classifications/ammatti_17_20210101/classificationItems?searchParamValue=${encodeURIComponent(
          inputValue
        )}&returnChildClasses=true&content=data&meta=minmin&lang=fi`
      )
      .then(response => {
        // axios returns the data directly in response.data
        callback(
          response.data.map(item => ({
            value: item.code,
            label: `${item.name} - ${item.code}`
          }))
        )
      })
      .catch(error => {
        console.error('Error fetching profession codes:', error)
        callback([])
      })
  }

  // Fetch person data when personId changes
  useEffect(() => {
    if (personId) {
      axios
        .get(`/api/admin/people/${personId}`, {
          headers: { authtoken: token },
        })
        .then(({ data }) => {
          setPerson({
            ...data,
            personSettings: {
              taxCardType: 'YEARLY',
              ...data.personSettings,
            },
          })
        })
    }
  }, [personId, token])

  // Setup react-hook-form with defaultValues from person state.
  // (We let the professionCode field store the full option object.)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: person,
  })

  // Reset the form whenever person data changes.
  useEffect(() => {
    reset(person)
  }, [person, reset])

  // When closing, reset the form and clear person state.
  const closeSlideOver = () => {
    reset({}) // Clear form fields
    setPerson({})
    setPersonId(null)
  }

  // Called when the form is submitted.
  const onSubmit = (data) => {
    // Convert the professionCode field from an object to its string value.
    const transformedData = {
      ...data,
      professionCode: data.professionCode ? data.professionCode.value : null,
    }
    console.log('Päivitetty henkilö:', transformedData)
    axios
      .put(`/api/admin/person/${personId}`, { ...person, ...transformedData }, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        setPerson(data)
        closeSlideOver()
      })
  }

  if (!personId) {
    return <span></span>
  }

  return (
    <div className="fixed inset-0 z-10 overflow-hidden">
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeSlideOver}></div>
      <Dialog open={open} onClose={closeSlideOver} className="relative z-10">
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out translate-x-0 bg-white shadow-xl">
                <div className="flex h-full flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6 flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold text-gray-900">
                      Muokkaa henkilöä
                    </DialogTitle>
                    <button
                      type="button"
                      onClick={closeSlideOver}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">Sulje paneeli</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-6 px-4 sm:px-6 space-y-4">
                    {/* Peruskentät */}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Etunimi
                        </label>
                        <input
                          {...register('firstName')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                        {errors.firstName && (
                          <p className="text-sm text-red-500">{errors.firstName.message}</p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Sukunimi
                        </label>
                        <input
                          {...register('lastName')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Koko nimi
                      </label>
                      <input
                        {...register('fullName')}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Sotu
                      </label>
                      <input
                        {...register('sotu')}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Käyttäjätunnus
                      </label>
                      <input
                        {...register('username')}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Osoite
                      </label>
                      <input
                        {...register('address')}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Postinumero
                        </label>
                        <input
                          {...register('postalcode')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Kaupunki
                        </label>
                        <input
                          {...register('city')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Puhelin
                        </label>
                        <input
                          {...register('phone')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Sähköposti
                        </label>
                        <input
                          {...register('email')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          IBAN
                        </label>
                        <input
                          {...register('iban')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          BIC
                        </label>
                        <input
                          {...register('bic')}
                          className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="personSettings.taxCardType"
                      >
                        Rooli
                      </label>
                      <select
                        id="personSettings.taxCardType"
                        {...register('role')}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      >
                        <option value="">Valitse</option>
                        <option value="ROLE_UNAUTHORIZED">Ei oikeuksia</option>
                        <option value="ROLE_USER">Käyttäjä</option>
                        <option value="ROLE_ADMIN">Ylläpitäjä</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Ammattikoodi
                      </label>
                      <Controller
                        name="professionCode"
                        control={control}
                        render={({ field }) => {
                          // If the stored value is a string, convert it to an object.
                          const selectedOption =
                            typeof field.value === 'string'
                              ? { value: field.value, label: field.value }
                              : field.value;
                          return (
                            <AsyncSelect
                              {...field}
                              cacheOptions
                              loadOptions={loadProfessionOptions}
                              defaultOptions
                              placeholder="Valitse ammatti"
                              isClearable
                              onChange={(option) => field.onChange(option)}
                              value={selectedOption}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: '1px solid #ccc', // fixed border
                                  boxShadow: state.isFocused ? 'none' : provided.boxShadow,
                                  outline: 'none',
                                  '&:hover': {
                                    border: '1px solid #ccc',
                                  },
                                }),
                                input: (provided) => ({
                                  ...provided,
                                  "input:focus": {
                                    boxShadow: "none",
                                  },
                                }),
                              }}
                              theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#ccc', // use the same border color so focus doesn't show a bright ring
                                  primary25: '#eee', // lighter shade for hover, etc.
                                },
                              })}
                            />
                          );
                        }}
                      />
                    </div>

                    {/* Henkilöasetukset */}
                    <fieldset className="border border-gray-300 p-4">
                      <legend className="text-sm font-medium text-gray-700">
                        Henkilöasetukset
                      </legend>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Veroprosentti
                          </label>
                          <input
                            {...register('personSettings.taxpercent')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Kilometrikorvaus
                          </label>
                          <input
                            {...register('personSettings.kmreimbursement')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Minimipalkka
                          </label>
                          <input
                            {...register('personSettings.minimumhourlywage')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Palvelumaksu
                          </label>
                          <input
                            {...register('personSettings.osk')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            TyEl
                          </label>
                          <input
                            {...register('personSettings.tyEl')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            YEL-summa
                          </label>
                          <input
                            {...register('personSettings.yelsum')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Lomapalkkio
                          </label>
                          <input
                            {...register('personSettings.holidaybonus')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Tapaturmavakuutus
                          </label>
                          <input
                            {...register('personSettings.accidentInsurance')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Palkkakatto
                          </label>
                          <input
                            {...register('personSettings.salaryMax')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Lisäveroprosentti
                          </label>
                          <input
                            {...register('personSettings.extraTaxPercent')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="personSettings.taxCardType"
                          >
                            Verokortin tyyppi
                          </label>
                          <select
                            id="personSettings.taxCardType"
                            {...register('personSettings.taxCardType')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          >
                            <option value="YEARLY">Vuosiraja</option>
                            <option value="MONTHLY">Kuukausiraja</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Verokortti
                          </label>
                          <input
                            {...register('personSettings.taxCard')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label
                            className="block text-sm font-medium text-gray-700"
                            htmlFor="workerType"
                          >
                            Työntekijätyyppi
                          </label>
                          <select
                            id="workerType"
                            {...register('personSettings.workerType')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          >
                            <option value="">Valitse</option>
                            <option value="BILLER">Laskuttaja</option>
                            <option value="WORKER">Työntekijä</option>
                            <option value="ENTREPRENEUR">Yrittäjä</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Työntekijän TYEL
                          </label>
                          <input
                            {...register('personSettings.workerTYEL')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Työnantajan TYEL
                          </label>
                          <input
                            {...register('personSettings.employerTYEL')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Ulosoton asetus
                          </label>
                          <select
                            id="personSettings.distraintType"
                            {...register('role')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          >
                            <option value="NONE">Ei asetettu</option>
                            <option value="FOUR_PART">Suojaosuudellinen</option>
                            <option value="ONE_THIRD">Kolmasosa</option>
                            <option value="ONE_SIXTH">Kuudesosa</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Palvelumaksun enimmäismäärä
                          </label>
                          <input
                            {...register('personSettings.serviceChargeMax')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div className="flex items-center space-x-2">
                        <input
                            {...register('personSettings.useServiceChargeMax')}
                            type="checkbox"
                            className="mt-1"
                          />
                          <label className="text-sm text-gray-700">
                            Käytä palvelumaksun enimmäismäärää
                          </label>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Veroprosentin voimassaoloaika alkaen
                          </label>
                          <input
                            {...register('personSettings.taxpercentValidFrom')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Veroprosentin voimassaoloaika asti
                          </label>
                          <input
                            {...register('personSettings.taxpercentValidUntil')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Yrittäjän provisio
                          </label>
                          <input
                            {...register('personSettings.entrepreneurProvision')}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                        <div className="flex items-center space-x-2">
                          <input
                            {...register('personSettings.useExtraTaxPercent')}
                            type="checkbox"
                            className="mt-1"
                          />
                          <label className="text-sm text-gray-700">
                            Käytä lisäveroprosenttia
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div className="flex justify-end pt-4">
                      <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded">
                        Tallenna
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default EditPerson
