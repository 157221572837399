import confirmed from 'views/register/confirmed.html'
import modelBinder from 'backbone.modelbinder'

var eulaView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(confirmed, {
      items: serialized,
    }, { items: serialized })
  },
  className: 'row row-fluid',
})
export default eulaView

