import Appregistry from 'libs/appregistry/appregistry'
import reportView from './quicksight-salaries.html'
import converter from 'libs/converter'
import moment from 'moment'
import Marionette from 'backbone.marionette'
import Backbone from 'backbone'

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk')

var model = Backbone.Model.extend({
  url: '/api/admin/quicksight/salary',
})

var generalReport = Marionette.View.extend({
  initialize: function() {
    this.model = new model({})
    this.model.fetch({
      success: (s) => {
        this.render()
      },
    })
  }, template: function(model) {
    return _.template(reportView)({})
  }, events: {}, calculateReport: function() {
  }, onDashboardLoad(payload) {
    console.log('Do something when the dashboard is fully loaded.')
  },

  onError(payload) {
    console.log('Do something when the dashboard fails loading')
  }, onRender: function() {
    let embedUrl = this.model.get('embedUrl')
    if (embedUrl) {
      var containerDiv = document.getElementById('embeddingContainer')
      var options = {
        // replace this dummy url with the one generated via embedding API
        url: embedUrl, container: containerDiv, parameters: {
          country: 'Finland',
        }, scrolling: 'no', height: '3100px', width: '1600px', locale: 'en-US', footerPaddingEnabled: true,
      }
      this.dashboard = QuickSightEmbedding.embedDashboard(options)
      this.dashboard.on('error', this.onError)
      this.dashboard.on('load', this.onDashboardLoad)
    }
    return this
  },
})
export default generalReport

