import React from 'react'

import Appregistry from 'libs/appregistry/appregistry'
import { createRoot } from 'react-dom'
import Navigation from './header-react'

var headerMenuView = Backbone.Marionette.View.extend({
  initialize: function() {
    this.listenTo(Appregistry.global_event_obj, 'loggedIn', this.render)
  },
  template: _.template("ninjou"),
  onRender: function() {
    const person = Appregistry.models.person
    const root = createRoot(document.getElementById('navbar'));
    const current = Backbone.history;
    const hash = current.location.hash
    return root.render(<Navigation person={person} hash={hash} history={Backbone.history}/>);
  }

})
export default new headerMenuView()

