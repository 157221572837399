import Backbone from 'backbone'

var salaryUrl = Backbone.Model.extend({
  defaults: {},
  initialize: function() {
  },

})
export default salaryUrl


