import Backbone from 'backbone'
import personModel from 'models/person'
import authModel from 'models/authModel'
import relationalPublicSettingsModel from 'models/relational/relationalPublicSettingsModel'
import peopleSimpleDTOCollection from 'collections/admin/people/peopleSimpleDTOCollection'

var Appregistry = {}
Appregistry.global_event_obj = _.extend({}, Backbone.Events)
Appregistry.models = {}
Appregistry.newPerson = function() {
  Appregistry.models.person = new personModel({})
  Appregistry.authModel = new authModel({ Appregistry: Appregistry })
  Appregistry.global_event_obj.trigger('loggedIn')
}
Appregistry.models.person = new personModel({})
Appregistry.logout = function() {
  return Appregistry.models.person.logout()
}
Appregistry.popoverOpts = { container: 'body', trigger: 'hover', delay: { hide: 200 }, placement: 'right' }
Appregistry.peopleCollection = new peopleSimpleDTOCollection()
Appregistry.publicSettings = new relationalPublicSettingsModel()
Appregistry.timestampChange = function(element, value) {
  if (value != undefined || value != null) {
    var find = element.find('[name="lastSaved"]')
    find.html(value)
  }
  if (element != undefined && element != null) {
    element.find('.timestamped').css({ opacity: '1' })
    element.find('.timestamped').animate({
      opacity: '0.4',
    }, 1500, function() {
      element.find('.timestamped').css({ color: 'black' })
    })
  }
}
Appregistry.selectOpts = {
  containerCssClass: 'select2-container-class',
  placeholder: 'Valitse',
  theme: 'bootstrap',
  allowClear: false,
}
Appregistry.authModel = new authModel({ Appregistry: Appregistry })
export default Appregistry
    
