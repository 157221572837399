import Backbone from 'backbone'

var searchCustomer = Backbone.Model.extend({
  validation: {
    crit: {
      minLength: 3,
      required: true,
      msg: 'Pitää olla vähintään 3 merkkiä',
    },
  },
})
export default searchCustomer


