import * as React from 'react'
import { Controller } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import fi from 'date-fns/locale/fi'

registerLocale('fi', fi)

export const NinjouDatePicker = ({ submitFn, errors, control, name, className, validation }) => {
  const formatSelected = (value) => {
    if (typeof value === 'string' && value.length > 0) {
      const matches = value.match(/\d{2}\.\d{2}\.\d{4}/g)
      if (matches) {
        return DateTime.fromFormat(matches[0], 'dd.MM.yyyy').toJSDate()
      }
      if (value.match(/\d{4}-\d{2}-\d{2}/g)) {
        return DateTime.fromISO(value).toJSDate()
      }
      let newVar = value == 'Invalid DateTime' ? undefined : value
      return newVar
    }
    return value
  }
  const parseDate = (e, field) => {
    const fromJSDate = DateTime.fromJSDate(e)
    if (fromJSDate.isValid) {
      const date = fromJSDate.toFormat('dd.MM.yyyy')
      field.onChange(date)
      submitFn(date)
      return date
    }
    field.onChange('')
    return ''
  }
  const err = errors ? errors[name] : {}
  const error = err?.type ? 'border-red-500 border-1' : ' border-0'
  return (
    <Controller
      // defaultValue={defaultValue}
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <DatePicker
          locale="fi"
          className={`${error} ${className}`}
          dateFormat="dd.MM.yyyy"
          selected={formatSelected(field.value)}
          onChange={(e) => parseDate(e, field)}
        />
      )}
    />
  )
}
