import React from 'react'
import { Controller } from 'react-hook-form'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import TimeField from 'react-simple-timefield'

export const NinjouTimePicker = ({ control, name, className }) => {
  const formatSelected = (value) => {
    return value
  }
  const parseDate = (value) => {
    return value
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TimeField
          className={className}
          input={<input type="text" style="" />}
          value={formatSelected(field.value)}
          onChange={(e, value) => field.onChange(parseDate(value))}
          showSeconds
        />

      )}
    />
  )
}
