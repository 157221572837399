import checkEmail from 'views/register/checkEmail.html'
import modelBinder from 'backbone.modelbinder'

var registerView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(checkEmail, {
      items: serialized,
    }, { items: serialized })
  },
  className: 'row row-fluid',
})
export default registerView

