import _ from 'underscore'
import Backbone from 'backbone'
import templatesItemView from 'js/views/user/templates/templatesItemView'
import templates from 'templates/user/templates/templates.html'
import templateCollection from 'js/collections/templateCollection'

export default Backbone.Marionette.CompositeView.extend({
  template: function(serialized) {
    return _.template(templates)({
      items: serialized,
    })
  },
  childView: templatesItemView,
  childViewContainer: '.templates',
  initialize: function() {
    this.collection = new templateCollection()
    this.collection.fetch()
  },
})

