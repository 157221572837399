import Backbone from 'backbone'

var employerCostModel = Backbone.Model.extend({})

var EmployerCostCollection = Backbone.Collection.extend({
  model: employerCostModel,
  url: function() {
    return '/api/admin/employercost'
  },
})

var relationalPublicSettings = Backbone.Model.extend({
  initialize: function() {
  },
  url: '/api/publicsettings',
})
export default relationalPublicSettings


