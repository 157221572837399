import Appregistry from 'libs/appregistry/appregistry'
import adminSalaryListView from 'views/admin/salaries/adminSalaryListView'
import salaryLayout from 'templates/admin/salaries/salary-layout.html'
import adminSalaryListCollection from 'collections/admin/salary/adminSalaryListCollection'
import singleSalaryView from 'views/admin/salaries/singleSalaryView'
import salarySearchModel from 'models/search/salarySearchModel'

var adminSalaryLayout = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(salaryLayout)({
      items: serialized,
      people: Appregistry.peopleCollection,
    })
  },
  tagName: 'div',
  className: 'row-fluid',
  regions: {
    salaryListRegion: '.salaryListRegion',
    editSalaryRegion: '.editSalaryRegion',
  },
  initialize: function() {
    this.collection = new adminSalaryListCollection()
    this.searchModel = new salarySearchModel()
    this.adminListView = new adminSalaryListView({ collection: this.collection })
    this.modelBinder = new Backbone.ModelBinder()
    Appregistry.global_event_obj.off('editItem')
    Appregistry.global_event_obj.on('editItem', this.editItem, this)
    this.collection.fetch()
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  events: {
    'click .addSalary': 'addSalary',
    'click .searchOther': 'searchOther',
    'click .more-salaries': 'moreSalaries',
  },
  collectionEvents: {
    'editItem': 'editItem',
    'deleteSalary': 'deleteSalary',
  },
  onRender: function() {
    this.getRegion('salaryListRegion').show(this.adminListView)
    this.modelBinder.bind(this.searchModel, this.el)
    if (!Appregistry.isMobile) {
      var opts = Appregistry.selectOpts
      this.$el.find('select').select2(opts)
    }
    $(this.el).find('.date-input').pickadate(window.helpers.pickadateOptions)
  },
  addSalary: function() {
    var newModel = new this.collection.model
    this.collection.unshift(newModel)
    this.getRegion('editSalaryRegion').show(new singleSalaryView({ model: newModel }))
  },
  editItem: function(id, a, b) {
    if (!this.editSalaryRegion) {
      this.addRegion('editSalaryRegion', '.editSalaryRegion')
    }
    if (this.view) {
      this.view.destroy()
    }
    if (id) {
      var modelToEdit = this.collection.get(id)
      this.view = new singleSalaryView({ model: modelToEdit })
      this.getRegion('editSalaryRegion').show(this.view)
      //this.editSalaryRegion.reset()
      $(document).scrollTop(0)
    }
  },
  deleteSalary: function() {
    this.editSalaryRegion.reset()
  },
  searchOther: function() {
    var obj = { data: this.searchModel.urlString() }
    this.searchModel.set({ page: 1 })
    this.collection.reset()
    this.collection.fetch(obj)
  },
  moreSalaries: function() {
    var searchModel = this.searchModel
    var json = this.searchModel.toJSON()
    for (var i in json) {
      if (!json[i]) {
        delete json[i]
      }
    }
    var data = json
    this.collection.fetch({
      remove: false, add: true, data: data,
      success: function(collection, response) {
        if (response.length != 0) {
          searchModel.set({ 'page': searchModel.get('page') + 1 })
        } else {
          $.jGrowl('Kaikki palkat näkyvillä', { life: 10000 })
        }
      },
    })
  },
})

export default adminSalaryLayout
    
