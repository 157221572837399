import singleTemplate from 'templates/user/templates/singleTemplate.html'

var templatesItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(singleTemplate)({
      items: serialized,
    })
  },
  initialize: function() {
  },
  tagName: 'tr',
  events: {
    'click .templateCheckbox': 'checked',
    'click .remove': 'removeFromList',
  },
  checked: function(event) {
    this.model.set('showInResult', !this.model.get('showInResult'))
    this.model.save()
  },
  removeFromList: function(event) {
    this.model.destroy()
  },
  onRender: function() {
    return this
  },
})

export default templatesItemView

