import modelBinder from 'backbone.modelbinder'
import billTemplate from 'templates/bill/singlebillItem.html'
import deleteBillModal from 'templates/bill/delete-bill-modal.html'
import billableItemCompositeView from 'js/views/admin/marionette/billableItem/billableItemCompositeView'
import converter from 'js/libs/converter'
import moment from 'moment'
import models from './models'
/*
       Renders single bill and billableitems for it
       */
export default Backbone.Marionette.View.extend({
  saving: false,
  template: function(serialized) {
    return _.template(billTemplate)({
      items: serialized,
      canSendNotification: function() {
        if (this.items.sendDueDate) {
          var sendDueDate = moment(this.items.sendDueDate, 'DD.MM.YYYY')
          if (moment().diff(sendDueDate, 'days') >= 7) {
            var sendNoteRequestDate = this.items.sendNoteRequestDate
            if (sendNoteRequestDate) {
              if (moment().diff(moment(this.items.sendNoteRequestDate, 'DD.MM.YYYY'), 'days') >= 7) {
                return true
              }
            } else {
              return true
            }
          }
        }
        return false
      },
      billUrl: 'api/bill/' + serialized.id + '/pdf',
      person: Appregistry.models.person,
      authtoken: Appregistry.models.person.get('authtoken'),
    })
  },
  tagName: 'div',
  className: 'bill-container',
  regions: {
    billableItems: '.billable-items',
  },
  saveModel: function() {
    var self = this
    var biValid = this.allBillableItemsValid()
    if (!biValid) {
      $.jGrowl('Laskulla virheellisiä laskutusrivejä, ei voida tallentaa', { life: 5000 })
    }
    if (!this.saving && biValid) {
      this.saving = true
      this.$el.find('.new_billableitem').attr('disabled', true)
      this.$el.find('.approve_bill').attr('disabled', true)
      //$(".ajax-disabler").css("display", "block");
      $('.ajax-disabler').css('visibility', 'visible')
      $('.ajax-disabler').css('opacity', '0.5')
      this.model.save(
        { wait: true },
        {
          success: function() {
            self.saving = false
            self.$el.find('.new_billableitem').attr('disabled', false)
            self.$el.find('.approve_bill').attr('disabled', false)
            //$(".ajax-disabler").css("display", "none");
            $('.ajax-disabler').css('visibility', 'hidden')
            $('.ajax-disabler').css('opacity', '0')
          },
          error: function() {
            self.saving = false
            self.$el.find('.new_billableitem').attr('disabled', false)
            self.$el.find('.approve_bill').attr('disabled', false)
            //$(".ajax-disabler").css("display", "none");
            $('.ajax-disabler').css('visibility', 'hidden')
            $('.ajax-disabler').css('opacity', '0')
          },
        },
      )
    }
  },
  initialize: function() {
    this.modelBinder = new modelBinder()
    // TODO: move this to modelEvents etc
    this.model.on('change', this.modelChanged, this)
    Appregistry.global_event_obj.on('alvChanged', this.alvChanged, this)
  },
  alvChanged: function(id) {
    var self = this
    this.model.get('billableitems').each(function(item) {
      if (item.id === id) {
        self.saveModel()
      }
    })
  },
  ui: {
    openBillItem: '.open_bill_item',
  },
  events: {
    'click @ui.openBillItem': 'openBill',
    'click .new_billableitem': 'newBillableItem',
    'click .delete_bill_item': 'openDeleteModal',
    'click .approve_bill': 'openApprove',
    'click .sendToApprove': 'sendToApprove',
    'click .destroyBill': 'deleteBill',
    'click .open-request': 'openOpenRequestModal',
    'click .sendOpenRequest': 'sendOpenRequest',
    'click .sendNotification': 'sendNotification',
    'click .sendNotificationToCustomer': 'sendNotificationToCustomer',
    'submit form': 'submit',
    'click .date-input': 'openDateInput',
  },
  openDateInput: function() {
    this.$el.find('.date-input').stop()
    this.$el.find('.date-input').pickadate(window.helpers.pickadateOptions)
  },
  submit: function(event) {
    event.preventDefault()
  },
  onDestroy: function() {
    this.model.off()
    if (this.billableItems) {
      this.billableItems.close()
    }
    if (this.model.get('billableitems').off) {
      this.model.get('billableitems').off()
    }

    this.modelBinder.unbind()
  },
  billableItemChanged: function(event) {
    var price = 0
    if (this.model.get('billableitems').size() > 0) {
      this.model.get('billableitems').forEach(
        (element = function(bi) {
          price = parseFloat(bi.get('price')) + parseFloat(bi.get('compensationAlvAmount'))
        }),
      )
    }
    this.model.set({ totalprice: price })
  },
  modelChanged: function(model, event) {
    if (this.model.isValid(true) && this.relevantChanges() && event.changeSource === 'ModelBinder') {
      this.saveModel()
    }
  },
  relevantChanges: function() {
    var relevant = false
    _.each(Object.keys(this.model.changed), function(key) {
      if (key !== 'lastSaved' && key !== 'totalprice' && key !== 'billableitems') {
        relevant = true
      }
    })
    return relevant
  },
  openBill: function(e) {
    e.preventDefault()
    this.model.isValid(true)
    this.model.get('billableitems').billId = this.model.get('id')
    this.model.get('billableitems').fetch()
    this.bindModel()
    this.$el.find('.billableitems-container').toggle()
  },
  onRender: function() {
    this.bindModel()
    this.model.get('billableitems').on('add change remove reset destroy', this.billableItemChanged, this)
    this.billableItemCompositeView = new billableItemCompositeView({ collection: this.model.get('billableitems') })
    this.model.set({ newItem: false }, { silent: true })
    this.getRegion('billableItems').show(this.billableItemCompositeView)

    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    Backbone.Validation.bind(this, {
      valid: function(view, attr) {
        if (attr == 'sharedCustomer') {
          view.$el.find('.select2-container-class').removeClass('invalid')
          view.$el.find('.sharedcustomer-notification').html('')
        } else {
          view.$el.find('[name=' + attr + ']').removeClass('invalid')
          view.$el
            .find('[name=' + attr + ']')
            .parent()
            .find('.error-element')
            .html('')
        }
      },
      invalid: function(view, attr, error) {
        // do something
        if (attr == 'sharedCustomer') {
          view.$el.find('.select2-container-class').addClass('invalid')
          view.$el.find('.sharedcustomer-notification').html(error)
        } else {
          view.$el.find('[name=' + attr + ']').addClass('invalid')
          view.$el
            .find('[name=' + attr + ']')
            .parent()
            .find('.error-element')
            .html(error)
        }
      },
    })
    this.$el.append(deleteBillModal)
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    return this
  },
  allBillableItemsValid: function() {
    var biValid = true
    this.model.get('billableitems').each(function(bi) {
      if (!bi.isValid(true)) {
        biValid = false
      }
    })
    return biValid
  },
  newBillableItem: function(event) {
    event.preventDefault()
    var biValid = this.allBillableItemsValid()
    if (!biValid) {
      $.jGrowl('Laskulla virheellisiä laskutusrivejä', { life: 5000 })
    }
    if (!this.saving && biValid) {
      var item = parseInt(this.$el.find('[name=BITemplate]').val())
      var newItem = { person: this.model.get('person') }

      if (item) {
        newItem.template = item
      }
      this.model.get('billableitems').add(newItem)
      this.saveModel()
    }
  },
  openDeleteModal: function(event) {
    console.log('openDeleteModal')
    event.preventDefault()
    this.$el.find('.deleteBillModal').modal('show')
  },
  deleteBill: function() {
    var self = this
    var elem = this.$el.find('.deleteBillModal')
    elem.on('hidden.bs.modal', function() {
      elem.off()
      self.model.destroy().success(function() {
        $.jGrowl('Lasku poistettu', { life: 30000 })
      })
    })
    elem.modal('hide')
  },
  openApprove: function(event) {
    event.preventDefault()

    if (!this.allBillableItemsValid()) {
      $.jGrowl('Laskulla virheellisiä laskutusrivejä', { life: 5000 })
      return
    }

    var modl = new models.salaryCalculationModel(this.model.toJSON())
    $('.salary-estimation').hide()
    $('.reimbursement-estimation').hide()
    $('.error-notifications').hide()
    $('.reduction-estimation').hide()
    $('.sendToApprove').attr('disabled', true)
    modl
      .save()
      .success(function(model) {
        if (model.error) {
          $('.error-notifications').show()
          $('.error-notification').html(model.error)
        } else if (model.reductions) {
          $('.reduction-estimation').show()
          $('.reductions').html(converter.euroConverter('ModelToView', model.reductions))
          $('.sendToApprove').attr('disabled', false)
        } else {
          $('.salary-estimation').show()
          $('.reimbursement-estimation').show()
          $('.taxableamount').html(converter.euroConverter('ModelToView', model.salary.taxableamount))

          $('.reimbursements').html(converter.euroConverter('ModelToView', model.salary.total_reimbursements))
          $('.taxfree_reimbursements').html(converter.euroConverter('ModelToView', model.salary.billableAmountToBePaid))
          $('.taxpercent').html(converter.percentConverter('ModelToView', model.salary.taxpercent))
          $('.total-tax').html(converter.euroConverter('ModelToView', model.salary.taxamount))
          $('.costs').html(converter.euroConverter('ModelToView', model.salary.costs))
          $('.calculated-salary').html(converter.euroConverter('ModelToView', model.salary.salary))
          $('.sendToApprove').attr('disabled', false)
        }
      })
      .error(function(model) {
        var json = model.responseJSON
        if (json.error) {
          $('.error-notifications').show()
          $('.error-notification').html(json.error)
        }
      })
    this.$el.find('.sendToApproveModal').modal('show')
  },
  sendToApprove: function() {
    var self = this
    var elem = this.$el.find('.sendToApproveModal')
    elem.on('hidden.bs.modal', function() {
      self.model.set({ state: 'PENDING' }, { silent: true })
      self.model.save().success(function() {
        $.jGrowl('Lasku lähetetty laskutettavaksi', { life: 30000 })
        self.render()
      })
    })
    elem.modal('hide')
  },
  openOpenRequestModal: function(event) {
    event.preventDefault()
    this.$el.find('.sendOpenRequestModal').modal('show')
  },
  sendOpenRequest: function() {
    var self = this
    var elem = this.$el.find('.sendOpenRequestModal')
    elem.on('hidden.bs.modal', function() {
      var url = self.model.url
      self.model.url = 'api/person/bill/' + self.model.id + '/open'
      self.model.save().always(function() {
        self.model.url = url
      })
    })
    elem.modal('hide')
  },
  sendNotification: function() {
    this.$el.find('.sendNotificationModal').modal('show')
  },
  sendNotificationToCustomer: function() {
    var self = this
    var elem = this.$el.find('.sendNotificationModal')

    elem.on('hidden.bs.modal', function() {
      var url = self.model.url
      self.model.url = 'api/person/bill/' + self.model.id + '/notification'
      self.model.save().always(function() {
        self.model.url = url
        self.$el.find('.sendNotification').parent().hide()
      })
    })
    elem.modal('hide')
  },
  bindModel: function() {
    var binds = {
      id: [
        { selector: '[name=id ]' },
        {
          selector: '.previewUrl',
          elAttribute: 'href',
          converter: this.urlConverter,
        },
        {
          selector: '.previewUrl',
          elAttribute: 'hidden',
          converter: this.hiddenConverter,
        },
      ],
      billingdate: '[name=billingdate]',
      lastSaved: { selector: '[name=lastSaved]', converter: converter.timestampConverter },
      totalprice: { selector: '[name=totalprice]', converter: converter.euroConverter },
      dueDate: { selector: '[name=dueDate]', converter: converter.integerConverter },
      calculatedDueDate: { selector: '[name=calculatedDueDate]' },
      specificationDetails: { selector: '[name=specificationDetails]' },
      inverseALV: { selector: '[name=inverseALV]' },
      sharedCustomer: { selector: '[name=sharedCustomer]', converter: this.sharedCustomerConverter },
      sendNoteRequestDate: [
        {
          selector: '[name=sendNoteRequestDate]',
          elAttribute: 'class',
          converter: converter.noteRequestConverter,
        },
        {
          selector: '.sendNoteRequestContainer',
          elAttribute: 'class',
          converter: converter.noteRequestConverter,
        },
      ],
      openRequest: [
        {
          selector: '[name=openRequest]',
          elAttribute: 'class',
          converter: this.openReqClassConverter,
        },
      ],
      separateReimbursements: { selector: '[name=separateReimbursements]' },
      billReimbursementsFromCustomer: { selector: '[name=billReimbursementsFromCustomer]' },
      state: [
        {
          selector: '[name=state]',
          converter: converter.stateConverter,
        },
      ],
    }
    if (this.model) {
      //this.enableFields()
      this.modelBinder.bind(this.model, this.el, binds)
      this.disableFields()
    }
  },
  disableFields: function() {
    if (this.model.get('state') !== 'OPEN') {
      this.$el.find('input').attr('disabled', true)
      this.$el.find('select').attr('disabled', true)
      this.$el.find('textarea').attr('disabled', true)
    }
  },
  enableFields: function() {
    if (this.model.get('state') === 'OPEN') {
      this.$el.find('input').attr('disabled', false)
      this.$el.find('select').attr('disabled', false)
      this.$el.find('textarea').attr('disabled', false)
    }
  },
  openReqClassConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value === true) {
        return ''
      } else {
        return 'hidden'
      }
    }
  },
  customerConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value) {
        return value.id
      }
    } else {
      return Appregistry.models.person.get('customers').get(value)
    }
  },
  sharedCustomerConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value) {
        return value.id
      }
    } else {
      return Appregistry.models.person.get('sharedCustomers').get(value)
    }
  },
  urlConverter: function(direction, value, a, b) {
    if (direction === 'ModelToView') {
      if (value) {
        var auth = this.boundEls[0].getAttribute('data-auth')
        return 'api/bill/' + value + '/pdf?authtoken=' + auth
      } else {
        return ''
      }
    }
  },
  hiddenConverter: function(direction, value) {
    if (direction === 'ModelToView') {
      if (value) {
        return false
      }
    } else {
      return true
    }
  },
})

