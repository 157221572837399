import Backbone from 'backbone'

var notPaidModel = Backbone.Model.extend({})
var notPaidCollection = Backbone.Collection.extend({
  model: notPaidModel,
})

var salaryUrl = Backbone.Model.extend({
  url: '/api/info',
  constructor: function(attributes, options) {
    attributes = attributes || {}
    attributes.notPaidBills = new notPaidCollection(attributes.notPaidBills || [], { parse: true })
    Backbone.Model.call(this, attributes, options)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.notPaidBills) {
      this.attributes.notPaidBills.reset(data.notPaidBills)
    } else {
      this.attributes.notPaidBills = new notPaidCollection(data.notPaidBills || [], { parse: true })
    }
    data.notPaidBills = this.attributes.notPaidBills
    return data
  },
})
export default salaryUrl


