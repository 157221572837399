import modelBinder from 'backbone.modelbinder'
import remunerationEdit from 'templates/admin/remuneration/remuneration-edit.html'
import converter from 'libs/converter'

var remunerationEditItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(remunerationEdit)({
      items: serialized,
    })
  },
  initialize: function(arg) {
    this.model = arg.collection ? new arg.collection.model : arg.model
    this.modelBinder = new modelBinder()
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el, remunerationBinging)
  },
  className: 'section',
  events: {
    'click .addNewRemuneration': 'addNewRemuneration',
    'click .date-input': 'openDateInput',
  },
  openDateInput: function() {
    this.$el.find('.date-input').stop()
    this.$el.find('.date-input').pickadate(window.helpers.pickadateOptions)
  },
  successAndClose: function(self) {
    $.jGrowl('Tallennettu', { life: 3000 })
    self.destroy()
  },
  addNewRemuneration: function() {
    var self = this
    if (this.model.id) {
      this.model.save()
        .success(function() {
          self.successAndClose(self)
        })
    } else {
      this.collection.create(this.model, { wait: true })
      self.successAndClose(self)

    }
  },
})
var remunerationBinging = {
  allowanceName: '[name=allowanceName]',
  allowancePrice: { selector: '[name=allowancePrice]', converter: converter.euroConverter },
  remunerationPercent: { selector: '[name=remunerationPercent]', converter: converter.percentConverter },
  validFromDate: { selector: '[name=validFromDate]' },
  validToDate: { selector: '[name=validToDate]' },
  validFrom: { selector: '[name=validFrom]' },
  validTo: { selector: '[name=validTo]' },
  mondays: { selector: '[name=mondays]' },
  tuesdays: { selector: '[name=tuesdays]' },
  wednesdays: { selector: '[name=wednesdays]' },
  thursdays: { selector: '[name=thursdays]' },
  fridays: { selector: '[name=fridays]' },
  saturdays: { selector: '[name=saturdays]' },
  sundays: { selector: '[name=sundays]' },
}

export default remunerationEditItemView

