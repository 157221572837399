import React from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

export const Paging = ({ currentPage, totalPages, onPageChange }) => {
  // Helper to render a page button.
  const renderButton = (pageNumber) => {
    const isCurrent = pageNumber === currentPage
    return (
      <button
        key={pageNumber}
        onClick={() => onPageChange(pageNumber)}
        className={`inline-flex items-center border-t-2 px-3 pt-4 text-sm font-medium ${
          isCurrent
            ? 'border-indigo-500 text-indigo-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        }`}
        aria-current={isCurrent ? 'page' : undefined}
      >
        {pageNumber + 1}
      </button>
    )
  }

  const getPageButtons = () => {
    const buttons = []
    if (totalPages <= 7) {
      // Few pages: show all pages.
      for (let i = 0; i < totalPages; i++) {
        buttons.push(renderButton(i))
      }
      return buttons
    }

    // Always show first page.
    buttons.push(renderButton(0))

    // Determine left window start.
    let left = Math.max(currentPage - 1, 1)
    // Determine right window end.
    let right = Math.min(currentPage + 1, totalPages - 2)

    // If the window is too close to the beginning, extend it.
    if (currentPage <= 2) {
      left = 1
      right = 3
    }
    // If the window is too close to the end, extend it.
    if (currentPage >= totalPages - 3) {
      left = totalPages - 4
      right = totalPages - 2
    }

    // If there is a gap between the first page and the left window, show an ellipsis.
    if (left > 1) {
      buttons.push(
        <span
          key="left-ellipsis"
          className="inline-flex items-center border-t-2 border-transparent px-3 pt-4 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      )
    }

    // Render the window pages.
    for (let i = left; i <= right; i++) {
      buttons.push(renderButton(i))
    }

    // If there's a gap between the right window and the last page, show an ellipsis.
    if (right < totalPages - 2) {
      buttons.push(
        <span
          key="right-ellipsis"
          className="inline-flex items-center border-t-2 border-transparent px-3 pt-4 text-sm font-medium text-gray-500"
        >
          ...
        </span>
      )
    }

    // Always show last page.
    buttons.push(renderButton(totalPages - 1))

    return buttons
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      {/* Previous Button */}
      <div className="-mt-px flex w-0 flex-1">
        <button
          disabled={currentPage === 0}
          onClick={() => onPageChange(currentPage - 1)}
          className={`inline-flex items-center border-t-2 pr-1 pt-4 text-sm font-medium ${
            currentPage === 0
              ? 'border-transparent text-gray-400'
              : 'border-gray-300 text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }`}
        >
          <ArrowLongLeftIcon aria-hidden="true" className="mr-2 h-5 w-5" />
          Edellinen
        </button>
      </div>

      {/* Page Buttons */}
      <div className="hidden md:flex md:-mt-px">
        {getPageButtons()}
      </div>

      {/* Next Button */}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          onClick={() => onPageChange(currentPage + 1)}
          className={`inline-flex items-center border-t-2 pl-1 pt-4 text-sm font-medium ${
            currentPage === totalPages - 1 || totalPages === 0
              ? 'border-transparent text-gray-400'
              : 'border-gray-300 text-gray-500 hover:border-gray-300 hover:text-gray-700'
          }`}
        >
          Seuraava
          <ArrowLongRightIcon aria-hidden="true" className="ml-2 h-5 w-5" />
        </button>
      </div>
    </nav>
  )
}

export default Paging
