import Appregistry from 'libs/appregistry/appregistry'
import template from './contractor-item.html'

var billableItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(template)({
      item: serialized,
    })
  },
  initialize: function() {
  },
})

export default billableItemView

