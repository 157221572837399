import Backbone from 'backbone'
import Appregistry from 'libs/appregistry/appregistry'
import converter from '../libs/converter'

var sharedCustomerModel = Backbone.Model.extend({
  initialize: function(options, models) {
    // `parseInt()` is used for consistency that `id` is numeric, just to be sure
    this.personId = options.personId
    this.customerId = options.customerId
  },
  url: function() {
    return 'api/person/' + this.personId + '/sharedcustomers/' + this.customerId

  },
})

var personSharedCustomersCollection = Backbone.Collection.extend({
  model: sharedCustomerModel,
  url: function() {
    var id = this.personId || Appregistry.models.person.id
    return 'api/person/' + id + '/sharedcustomers'
  },
})

export default {
  personSharedCustomersCollection: personSharedCustomersCollection,
  sharedCustomerModel: sharedCustomerModel,
}

