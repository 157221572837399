import React, { Fragment, useState, useEffect } from 'react'
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'

export default function CustomerCard({ customer }) {
  return (
    <div className="overflow-hidden bg-gray-50 shadow sm:rounded-lg">
      <div className="px-4 py-4 sm:px-4">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Asiakas</h3>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Nimi</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.name}</dd>
          </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Y-tunnus</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.businessid}</dd>
            </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Laskun lähetys </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.sendBillMethod}</dd>
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Asiakas tarkastettu</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{customer?.checked ? 'Kyllä' : 'Ei'}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
