import Backbone from 'backbone'
import 'jsgrid'
import 'backbone.paginator'

var collection = Backbone.PageableCollection.extend({
  model: Backbone.Model.extend({}),
  state: {
    pageSize: 10,
    // You can use 0-based or 1-based indices, the default is 1-based.
    // You can set to 0-based by setting ``firstPage`` to 0.
    firstPage: 0,
  },
  queryParams: {
    currentPage: 'page',
    pageSize: 'limit',
  },
  url: 'api/admin/ropo/updates',
})
var updatesView = Backbone.Marionette.View.extend({
  template: '<div><div id="ninjou-status-jsgrid"></div></div>',
  initialize: function() {
    this.collection = new collection()
  },
  onRender: function() {
    var self = this
    var ell = this.$el.find('#ninjou-status-jsgrid')
    ell.jsGrid({
      height: '370px',
      width: '100%',

      autoload: true,
      paging: true,
      pageLoading: true,
      pageSize: 15,
      pageIndex: 1,

      controller: {
        loadData: function(filter) {
          var startIndex = (filter.pageIndex - 1) * filter.pageSize
          var promise = new Promise(function(resolve, reject) {
            // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
            // In this example, we use setTimeout(...) to simulate async code.
            // In reality, you will probably be using something like XHR or an HTML5 API.
            self.collection.getPage(filter.pageIndex - 1).done(function(data) {
              resolve({
                itemsCount: data.itemsCount,
                data: data.data,
              })
            })
          })

          return promise
        },
      },

      fields: [
        {
          name: 'id',
          type: 'text',
          valueField: 'id',
          width: 30,
        },
        {
          name: 'jobid',
          title: 'jobid',
          type: 'text',
          width: 80,
        },
        {
          name: 'billnum',
          title: 'billnum',
          type: 'text',
          width: 80,
        },
        {
          name: 'openamount',
          title: 'openamount',
          type: 'text',
          width: 80,
        },
        {
          name: 'origamount',
          type: 'text',
          width: 80,
        },
        {
          name: 'statustime',
          type: 'text',
          width: 120,
        },
        {
          name: 'statustext',
          type: 'text',
          width: 200,
        },
        {
          name: 'statuscode',
          type: 'text',
          width: 80,
        },
        {
          name: 'bill',
          type: 'text',
          width: 80,
        },
      ],
    })
  },
})
export default updatesView

