import React from 'react'
import { numToFixed } from './bills'
import { getVehicleType, stateElement } from './bill-side-over'
import { useFieldArray } from 'react-hook-form'
import { ControlledNumber } from '../form/number-input'

export default function BillableItems({ selectedBill, openReceipt, control, register, token, submitForm, getValues }) {
  if (!selectedBill?.billableitems?.length) {
    return undefined
  }
  const getEnnakko = (item) => {
    return selectedBill?.advancePayments
      .filter((paym) => {
        return paym.billableItemIdsAs.includes(item?.id)
      })
      .map((paym) => {
        return stateElement(paym.state)
      })
  }

  const getWaypoint = (points) => {
    const arr = points || []
    return arr.join(' - ')
  }
  const getReceipts = (receipts) => {
    const arr = receipts || []
    return arr.map((receipt) => {
      const url = `/api/billableitem/receipt/${receipt.id}?authtoken=${token}`
      return (
        <div className="text-gray-900">
          <a onClick={() => openReceipt(url)}>{receipt.destinationName}</a>
        </div>
      )
    })
  }
  const { replace, fields, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'billableitems', // unique name for your Field Array
    keyName: 'key',
  })

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <p className="text-xl">Laskutusrivit</p>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Päiväys
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kohde
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Hinta
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kulukorvaukset
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Reitti
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kuitit ja kulukorvaukset
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kuitit
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {fields.map((item, index) => (
                    <tr key={item.key}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div>{item.date}</div>
                        <div className="pt-2">{getEnnakko(item)}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-700">{item.destination}</div>
                        {item.startdate && (
                          <div>
                            {item.startdate} {item.time} - {item.returndate} {item.returntime}
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 w-32">
                          Alv:{' '}
                          <ControlledNumber
                            name={`billableitems.${index}.price`}
                            inputStyles="input-none w-14 float-right"
                            onBlur={submitForm}
                            control={control}
                          ></ControlledNumber>{' '}
                        </div>
                        <div className="text-gray-900 w-32">
                          Alv0%:
                          <span className="float-right">{numToFixed(item.priceWithoutAlv)} €</span>{' '}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 w-32">
                          <span>Kilometrit:</span>

                          <ControlledNumber
                            name={`billableitems.${index}.kilometers`}
                            inputStyles="input-none w-14 float-right"
                            onBlur={submitForm}
                            control={control}
                          ></ControlledNumber>
                        </div>

                        <div className="text-gray-900 w-32">
                          Osapäivä:
                          <ControlledNumber
                            name={`billableitems.${index}.partialdaycompensation`}
                            inputStyles="input-none w-14 float-right"
                            onBlur={submitForm}
                            control={control}
                          ></ControlledNumber>
                        </div>
                        <div className="text-gray-900 w-32">
                          Kokopäivä:
                          <ControlledNumber
                            name={`billableitems.${index}.fulldaycompensation`}
                            inputStyles="input-none w-14 float-right"
                            onBlur={submitForm}
                            control={control}
                          ></ControlledNumber>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900 w-32">
                          Ateriat:
                          <ControlledNumber
                            name={`billableitems.${index}.lunchcompensation`}
                            inputStyles="input-none w-14 float-right"
                            onBlur={submitForm}
                            control={control}
                          ></ControlledNumber>
                        </div>
                        <div className="text-gray-900 text-nowrap w-32">
                          <div>
                            Ulkm. pvr:
                            <ControlledNumber
                              name={`billableitems.${index}.foreignAllowance`}
                              inputStyles="input-none w-14 float-right"
                              onBlur={submitForm}
                              control={control}
                            ></ControlledNumber>
                          </div>
                        </div>
                        {item.kilometers > 0 && (
                          <div className="text-blue-900 w-32">
                            {getVehicleType(item.vehicle)}
                            <input
                              className="input-none w-24 float-right"
                              {...register(`billableitems.${index}.vehicleRegisterNumber`, { onBlur: submitForm })}
                            />
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{item.addressStart}</div>
                        <div className="text-gray-900">{getWaypoint(item.wayPoint)}</div>
                        <div className="text-gray-900"> {item.addressStop}</div>
                        {item.roundtrip && <div className="text-gray-900">{item.addressStart}</div>}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {numToFixed(item.billedReimbursements)} €
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {getReceipts(item.receipts)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
