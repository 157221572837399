import _ from 'underscore'
import Appregistry from 'libs/appregistry/appregistry'
import personTemplate from './person.html'
import converter from 'libs/converter'
import 'blueimp-file-upload'

var personSettingsBindings = {
  taxpercent: { selector: '[name=taxpercent]', converter: converter.percentConverter },
  taxpercentValidFrom: { selector: '[name=taxpercentValidFrom]' },
  taxpercentValidUntil: { selector: '[name=taxpercentValidUntil]' },
  copy_rows: { selector: '[name=copy_rows]' },
  primaryWork: '[name=primaryWork]',
}
// view for single billableitem
var personView = Backbone.Marionette.View.extend({
  initialize: function() {
    this.model = Appregistry.models.person
    this.model.bind('change', this.saveModel, this)
    this.model.get('personSettings').bind('change', this.saveModel, this)
  },
  events: {
    'click .save-person': 'saveNow',
  },
  template: function(serialized) {
    var taxCard = serialized ? serialized.personSettings.get('taxCard') : null
    return _.template(personTemplate)({
      person: serialized,
      taxCard: taxCard,
    })
  },
  onDestroy: function() {
    if (this.model.get('personSettings')) {
      this.model.get('personSettings').off('change')
    }
    if (this.model) {
      this.model.off('change')
    }
  },
  onRender: function() {
    this.personBinder = new Backbone.ModelBinder()
    this.personSettingsBinder = new Backbone.ModelBinder()


    var id = this.model.get('id')
    var self = this

    this.$el.find('#taxcard').fileupload({
      dataType: 'json',
      url: '/api/person/' + id + '/taxcard',
      disableImageResize: false,
      imageMaxWidth: 800,
      imageMaxHeight: 800,
      imageCrop: true,
      add: function(e, data) {
        self.$el.find('#progress').show()
        $('#progress').css('display', 'block')
        var jqXHR = data.submit()
          .success(function(result, textStatus, jqXHR) {
            self.model.get('personSettings').set({ taxCard: result.personSettings.taxCard }, { silent: true })
            $.jGrowl('Verokortti tallennettu', { life: 5000 })
            self.model.fetch({
              success: function() {
                self.render()
              },
            })

          })
          .error(function(jqXHR, textStatus, errorThrown) {
            $.jGrowl('Verokorttia ei voitu tallentaa', { life: 5000 })
          })
          .complete(function(result, textStatus, jqXHR) {
          })
      },
      progressall: function(e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10)
        $('#progress .bar').css('width', progress + '%')
      },
      done: function(e, data) {
        $('#progress').css('display', 'none')
      },

    })

    this.setContent()
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    return this
  },
  saveModel: function() {
    if (this.model.isValid(true)) {
      var self = this
      this.model.save(null, {
        silent: true,
        success: function(item) {
          Appregistry.models.person = self.model
        },
      })
    }
  },
  saveNow: function() {
    if (this.model.isValid(true)) {
      var self = this
      this.model.save(null, {
        silent: true,
        success: function(item) {
          Appregistry.models.person = self.model
          $.jGrowl('Tiedot tallennettu', { life: 1000 })
        },
      })
    } else {
      $.jGrowl('Ei voitu tallentaa', { life: 10000 })
    }
  },
  setContent: function() {
    var personBindings = {
      firstName: '[name=firstName]',
      lastName: '[name=lastName]',
      phone: '[name=phone]',
      email: '[name=email]',
      marketingname: '[name=marketingname]',
      address: '[name=address]',
      postalcode: '[name=postalcode]',
      iban: '[name=iban]',
      bic: '[name=bic]',
      password: '[name=password]',
      city: '[name=city]',
      sotu: '[name=sotu]',
    }
    this.personBinder.bind(this.model, this.el, personBindings)
    this.personSettingsBinder.bind(this.model.get('personSettings'), this.el, personSettingsBindings)
  },
})
export default personView

