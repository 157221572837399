import Backbone from 'backbone'

var personSettings = Backbone.Model.extend({
  validation: {
    taxpercent: {
      range: [0, 100],
      msg: 'Pitää olla välillä 0-100',
    },
  },
  urlRoot: '/api/personsettings',
  defaults: {
    opened: 'closed',
    taxpercent: 60.00,
    kmreimbursement: 0,
    minimumhourlywage: 0,
  },
  initialize: function() {
  },
})
export default personSettings


