import tableTemplate from 'templates/admin/remuneration/remuneration-table.html'
import remunerationItemView from 'views/admin/remuneration/remunerationItemView'

var remunerationCollectionView = Backbone.Marionette.CompositeView.extend({
  childView: remunerationItemView,
  template: tableTemplate,
  tagName: 'table',
  className: 'table table-hover',
  childViewContainer: '.remunerationList',
})
export default remunerationCollectionView

