import Appregistry from 'js/libs/appregistry/appregistry'
import searchCustomerView from 'js/views/user/customers/searchCustomerView'
import addedCustomersCompositeView from 'js/views/user/customers/addedCustomersCompositeView'
import newCustomerView from 'js/views/admin/marionette/sharedcustomers/newCustomerView'
import customerLayout from 'templates/user/customers/customer-layout.html'

/**
 * Layout for person customers and search new customers
 */
var customersLayout = Backbone.Marionette.View.extend({
  template: customerLayout,
  tagName: 'div',
  className: 'row',
  regions: {
    searchCustomer: '.search-customers',
    addedCustomers: '.added-customers',
    addCustomer: '.add-customer',
  },
  events: {
    'click .createCustomer': 'createCustomer',
  },
  createCustomer: function() {
    this.newCustomerView = new newCustomerView({ collection: Appregistry.models.person.get('sharedCustomers') })
    this.newCustomerView.saveFn = this.saveNewCustomer
    this.getRegion('addCustomer').show(this.newCustomerView)
  },
  initialize: function() {
    this.searchCustomerView = new searchCustomerView()
    this.addedCustomersView = new addedCustomersCompositeView()
  },
  onRender: function() {
    this.getRegion('searchCustomer').show(this.searchCustomerView)
    this.getRegion('addedCustomers').show(this.addedCustomersView)
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
  },
  saveNewCustomer: function(response) {
    Appregistry.models.person.get('sharedCustomers').add(response)
    $.jGrowl('Asiakas lisätty', { life: 1000 })
  },
})


export default customersLayout

