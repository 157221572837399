import adminBillsCollection from 'collections/admin/marionette/adminBillsCollection'
import adminBillLayoutView from 'views/admin/marionette/bills/adminBillLayoutView'
import billsTemplate from 'templates/admin/marionette/bills/bills-container.html'
import billSearchModel from 'models/search/billSearchModel'
import Appregistry from 'libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import moment from 'moment'

export default Backbone.Marionette.CompositeView.extend({
  childView: adminBillLayoutView,
  childViewContainer: '#bills-container',
  initialize: function() {
    this.collection = new adminBillsCollection()
    this.modelBinder = new modelBinder()
    this.searchModel = new billSearchModel()
    this.collection.fetch()
  },
  sortCollectionNow: function() {
    this.collection.sort()
    this.collection.trigger('reset')
  },
  template: function(serialized) {
    return _.template(billsTemplate)({
      people: Appregistry.peopleCollection,
      items: serialized,
    })
  },
  events: {
    'click .sort-bills': 'sortBills',
    'click .more-bills': 'moreBills',
    'click .searchState': 'searchState',
    'submit form': 'searchState',
    'click .fetch-new-bills': 'fetchNewBills',
    'click .fetch-openrequested-bills': 'fetchOpenRequested',
    'click .emptyCrit': 'emptyCrit',
  },
  emptyCrit: function() {
    this.searchModel.clear()
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
  },
  searchState: function(e) {
    e.preventDefault()
    var obj = { data: this.searchModel.urlString() }
    this.collection.reset()
    this.collection.fetch(obj)
  },
  fetchOpenRequested: function() {
    this.collection.fetch({ data: { openRequested: true } })
  },
  sortBills: function() {
    var sortValue = this.$el.find('select.bills-sort-option ').val()
    var reverse = false
    if (this.sortOption === sortValue) {
      reverse = true
      this.sortOption = ''
    } else {
      this.sortOption = sortValue
    }
    if (sortValue === 'person') {
      this.collection.comparator = function(item) {
        var sort = item.get('person').get('firstName') + item.get('person').get('lastName')

        if (reverse) {
          var str = sort
          str = str.toLowerCase()
          str = str.split('')
          str = _.map(str, function(letter) {
            return String.fromCharCode(-letter.charCodeAt(0))
          })
          return str
        }
        return sort
      }
    } else if (sortValue === 'billingdate' || sortValue === 'person') {
      this.collection.comparator = function(item) {
        if (reverse) {
          return -moment(item.get(sortValue), 'DD.MM.YYYY').unix()
        }
        return moment(item.get(sortValue), 'DD.MM.YYYY').unix()
      }
    } else {
      this.collection.comparator = function(item) {
        if (reverse) {
          if (isNaN(item)) {
            var str = item.get(sortValue)
            str = str.toLowerCase()
            str = str.split('')
            str = _.map(str, function(letter) {
              return String.fromCharCode(-letter.charCodeAt(0))
            })
            return str
          }
        }
        return item.get(sortValue)
      }
    }
    this.sortCollectionNow()
  },
  onShow: function() {
    this.collection.fetch()
  },
  onClose: function() {
    this.modelBinder.unbind()
  },
  onRender: function() {
    this.modelBinder.bind(this.searchModel, this.el)
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
  },
  moreBills: function() {
    var self = this
    var newItems = new adminBillsCollection()
    this.searchModel.set({ page: this.searchModel.get('page') + 1 })
    var obj = { data: this.searchModel.urlString() }
    newItems.fetch(obj).success(function(response) {
      if (newItems.models.length != 0) {
        self.collection.add(newItems.models)
      } else {
        $.jGrowl('Kaikki laskut näkyvillä', { life: 3000 })
      }
    })
  },
  fetchNewBills: function() {
    var obj = {}
    obj.data = {}
    obj.data.newItems = true
    this.collection.fetch(obj)
  },
})

