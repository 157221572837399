import Appregistry from 'js/libs/appregistry/appregistry'
import customerResult from 'templates/user/customers/addedCustomer.html'

var billableItemView = Backbone.Marionette.View.extend({
    template: function(serialized) {
      return _.template(customerResult)({
        items: serialized,
        person: Appregistry.models.person,
      })
    },
    tagName: 'tr',
  })


export default billableItemView

