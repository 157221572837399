import confirmed from 'templates/password/forgotPassword.html'
import modelBinder from 'backbone.modelbinder'

var forgotModel = Backbone.Model.extend({
  url: '/api/password/forgot',
})


var eulaView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(confirmed, {
      items: serialized,
    }, { items: serialized })
  },
  className: 'row row-fluid',
  initialize: function() {
    this.modelBinder = new modelBinder()
    this.model = new forgotModel()
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  events: {
    'click .forgotPassword': 'forgot',
  },
  forgot: function(e) {
    e.preventDefault()
    this.model.save()
      .success(function(response) {
        Backbone.history.navigate('forgot/checkemail', { trigger: true })
      })
      .error(function(response) {
        var response = response.responseJSON
        var element = $('[name=email]')
        element.addClass('invalid')
        var div = $('<div></div>')
          .addClass('error-element')
          .html(response.message)
        element.after(div)
      })
  },
})
export default eulaView

