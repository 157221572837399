import registerTemplate from 'templates/static/register.html'
import who from 'templates/static/who.html'

var templateView = Backbone.Marionette.View.extend({
  initialize: function() {
  },
  events: {},
  render: function() {
    if (this.whichView == 'register') {
      this.$el.html(registerTemplate)
    }
    if (this.whichView == 'who') {
      this.$el.html(who)
    }
    return this
  },
})
export default templateView

