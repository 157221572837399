import Backbone from 'backbone'
import Appregistry from 'js/libs/appregistry/appregistry'
import converter from 'js/libs/converter'

var model = Backbone.Model.extend({})
var collection = Backbone.Collection.extend({
  model: model,
})

var sharedCustomerModel = Backbone.Model.extend({})


var sharedCustomersCollection = Backbone.Collection.extend({
  model: sharedCustomerModel,
})

var personModel = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a = a || {}
    a.sharedCustomers = new sharedCustomersCollection(a.sharedCustomers || [], { parse: true })
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}

    if (this.attributes.sharedCustomers) {
      this.attributes.sharedCustomers.reset(data.sharedCustomers)
      data.sharedCustomers = this.attributes.sharedCustomers
    } else {
      this.attributes.sharedCustomers = new sharedCustomersCollection(data.sharedCustomers || [], { parse: true })
    }
    return data
  },
})

var receiptModel = Backbone.Model.extend({
  url: function() {
    return '/api/billableitem/receipt/' + this.id
  },
})

var receiptCollection = Backbone.Collection.extend({
  model: receiptModel,
})

var isHourlyPriceType = function(value) {
  return value === 'AMOUNT'
    || value === 'KMREIMBURSEMENT'
    || value === 'BILLABLE_FULL_DAY_COMPENSATION'
    || value === 'BILLABLE_PARTIAL_DAY_COMPENSATION'
    || value === 'BILLABLE_LUNCH_COMPENSATION'
}
var validateReceipt = function(value, that) {
  if (!parseFloat(value)) return null
  if (that.get('receipts').length === 0) {
    return 'Muista liittää kuitit'
  }
  return null
}

var relationalBillableItemModel = Backbone.Model.extend({
  defaults: {
    opened: 'closed',
    kilometers: 0,
    state: 'OPEN',
    vehicle: 0,
    hourlyprice: 0,
  },
  constructor: function(a, b, c) {
    a.receipts = new receiptCollection(a.receipts || [], { parse: true })
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.receipts) {
      this.attributes.receipts.reset(data.receipts)
      data.receipts = this.attributes.receipts

    }
    if (data && data.hourlyprice == null && this.attributes) {
      if (!this.get('hourlyprice')) {
        var hourlyPrice = parseFloat(data.alvPrice) / parseFloat(data.billableWorkHours)
        if (isFinite(hourlyPrice)) {
          data.hourlyprice = hourlyPrice
        } else {
          data.hourlyprice = 0.00
        }
      }
    }
    return data
  },
  urlRoot: '/api/billableitem',
  hasMoreThanOneReimbursements: function() {
    var full = this.get('fulldaycompensation') || this.get('partialdaycompensation') ? 1 : 0
    var fore = parseFloat(this.get('foreignAllowance')) > 0 ? 1 : 0
    var lunch = this.get('lunchcompensation') ? 1 : 0
    var count = full + fore + lunch
    return count >= 2

  },
  validation: {
    billableWorkHours: function(value, field, model) {
      if (isHourlyPriceType(model.type)) {
        if (isNaN(value) || value == 0) {
          return 'Määrä puuttuu'
        }
      }
      return null
    },
    fulldaycompensation: function(value) {
      if (!value) return
      if (!isFinite(value)) {
        return 'pitää olla numero'
      }
      if (value > 1) {
        return 'Vain yksi päiväraha'
      }
      if (this.hasMoreThanOneReimbursements()) {
        return 'Vain yksi päivärahalaji kerrallaan on mahdollinen'
      }
      return null
    },
    partialdaycompensation: function(value) {
      if (!value) return null
      if (!isFinite(value)) {
        return 'pitää olla numero'
      }
      if (value > 1) {
        return 'Vain yksi puolipäiväraha'
      }
      if (this.hasMoreThanOneReimbursements()) {
        return 'Vain yksi päivärahalaji kerrallaan on mahdollinen'
      }
      return null
    },
    foreignAllowance: function(value) {
      if (!value) return null
      if (this.hasMoreThanOneReimbursements()) {
        return 'Vain yksi päivärahalaji kerrallaan on mahdollinen'
      }
      return null
    },
    lunchcompensation: function(value) {
      if (!value) return null
      if (this.hasMoreThanOneReimbursements()) {
        return 'Ateriakorvausta ei voi maksaa päivärahojen lisäksi'
      }
      return null
    },
    vehicleRegisterNumber: function(value) {
      var kilometers = this.get('kilometers')
      if (!isFinite(kilometers) || kilometers === 0) return null
      if (value && value.length) return null
      return 'Rekisterinumero on pakollinen, jos olet ilmoittanut kilometrejä'
    },
    addressStart: function(value) {
      var kilometers = this.get('kilometers')
      if (!isFinite(kilometers) || kilometers === 0) return null
      if (value && value.length) return null
      return 'Reitti on pakollinen, jos olet ilmoittanut kilometrejä'
    },
    addressStop: function(value) {
      var kilometers = this.get('kilometers')
      if (!isFinite(kilometers) || kilometers === 0) return null
      if (value && value.length) return null
      return 'Reitti on pakollinen, jos olet ilmoittanut kilometrejä'
    },
    time: function(value) {
      var fulldaycompensation = this.get('fulldaycompensation')
      var partialdaycompensation = this.get('partialdaycompensation')
      if (fulldaycompensation || partialdaycompensation) {
        if (!value) {
          return 'Lähtöaika pitää ilmoittaa, jos on syötetty päivärahoja'
        }
      }
      return null
    },
    returntime: function(value) {
      var fulldaycompensation = this.get('fulldaycompensation')
      var partialdaycompensation = this.get('partialdaycompensation')
      if (fulldaycompensation || partialdaycompensation) {
        if (!value) {
          return 'Saapumisaika pitää ilmoittaa, jos on syötetty päivärahoja'
        }
      }
      return null
    },
    hotelcost: function(value) {
      return validateReceipt(value, this)
    },
    hotelcost_alv10: function(value) {
      return validateReceipt(value, this)
    },
    hotelcost_alv14: function(value) {
      return validateReceipt(value, this)
    },
    hotelcost_alv24: function(value) {
      return validateReceipt(value, this)
    },
    travelcost: function(value) {
      return validateReceipt(value, this)
    },
    travelcost_alv10: function(value) {
      return validateReceipt(value, this)
    },
    travelcost_alv14: function(value) {
      return validateReceipt(value, this)
    },
    travelcost_alv24: function(value) {
      return validateReceipt(value, this)
    },
    othercost: function(value) {
      return validateReceipt(value, this)
    },
    othercost_alv10: function(value) {
      return validateReceipt(value, this)
    },
    othercost_alv14: function(value) {
      return validateReceipt(value, this)
    },
    othercost_alv24: function(value) {
      return validateReceipt(value, this)
    },
    receipts: function(value) {
      return null
    },

  },
  setHourlyPrice: function(price) {
    var billableWorkHours = parseFloat(this.get('billableWorkHours'))
    if (isFinite(billableWorkHours) && isFinite(price)) {
      if (billableWorkHours == 0) {
        this.set('hourlyprice', 0)
      } else {
        this.set('hourlyprice', (price / billableWorkHours).toFixed(2))
      }
    }
  },
  alvPriceConv: function(direction, value, el, model) {
    var convertedValue = converter.euroConverter(direction, value, el, model)
    if (direction === 'ViewToModel') {
      var alv = parseFloat(model.get('alv'))
      var alvzero = parseFloat(convertedValue)
      if (alv) {
        var price = ((alv / 100.00) * alvzero) + alvzero
        model.setHourlyPrice(alvzero)
        model.set('price', price)
      } else {
        model.setHourlyPrice(alvzero)
        model.set('price', alvzero)
      }
    }
    return convertedValue
  },
  priceConv: function(direction, value, el, model) {
    var convertedValue = converter.euroConverter(direction, value, el, model)
    if (direction === 'ViewToModel') {
      var type = model.get('type')
      if (!isHourlyPriceType(type)) {
        var calc_value = (parseFloat(convertedValue) / parseFloat(model.get('billableWorkHours')))
        if (isFinite(calc_value)) {
          model.set('hourlyprice', calc_value.toFixed(2))
        } else {
          model.set('hourlyprice', (0).toFixed(2))
        }
      }
    }
    return convertedValue
  },
  hourlyPriceConv: function(direction, value, el, model) {
    var convertedValue = converter.euroConverter(direction, value, el, model)

    if (direction === 'ViewToModel') {
      var type = model.get('type')
      if (isHourlyPriceType(type)) {
        model.get('price')
        var calc_value = (convertedValue * parseFloat(model.get('billableWorkHours')))
        if (isFinite(calc_value)) {
          var alv = parseFloat(model.get('alv'))
          if (alv) {
            model.set('price', ((alv / 100.00) * calc_value) + calc_value)
            model.set('alvPrice', calc_value)
          } else {
            model.set('price', calc_value)
            model.set('alvPrice', calc_value)
          }
        } else {
          model.set('price', (0).toFixed(2))
          model.set('alvPrice', (0).toFixed(2))
        }
      }
    } else {
      if (!model.get('hourlyprice')) {
        var price = parseFloat(model.get('alvPrice'))
        var billableWorkHours = parseFloat(model.get('billableWorkHours'))
        var hourlyPrice = price / billableWorkHours
        if (isFinite(hourlyPrice)) {
          convertedValue = converter.euroConverter(direction, hourlyPrice, el, model)
        }
      }
    }
    return convertedValue
  },
  workhourConv: function(direction, value, el, model) {
    var convertedValue = converter.dotConverter(direction, value, el, model)
    if (direction === 'ViewToModel') {
      var type = model.get('type')
      if (isHourlyPriceType(type)) {
        var price = parseFloat(model.get('alvPrice'))
        var hourlyprice = parseFloat(model.get('hourlyprice'))
        var new_price = 0
        if (hourlyprice) {
          new_price = hourlyprice * parseFloat(convertedValue)
        } else {
          new_price = price / parseFloat(convertedValue)
        }
        if (isFinite(new_price)) {
          var alv = parseFloat(model.get('alv'))
          if (alv) {
            model.set('price', ((alv / 100.00) * new_price) + new_price)
            model.set('alvPrice', new_price)
          } else {
            model.set('price', new_price)
            model.set('alvPrice', new_price)
          }
        } else {
          model.set('price', (0).toFixed(2))
          model.set('alvPrice', (0).toFixed(2))
        }
      } else {
        var price = parseFloat(model.get('alvPrice'))
        var hourlyPrice = price / convertedValue
        if (isFinite(hourlyPrice)) {
          model.set('hourlyprice', (hourlyPrice).toFixed(2))
        } else {
          model.set('hourlyprice', 0)
        }
      }

    }
    return convertedValue
  },
  alvConverter: function(direction, value, el, model) {
    if (direction === 'ViewToModel') {
      var alv = parseFloat(value)
      var price = parseFloat(model.get('alvPrice'))
      if (alv) {
        model.setHourlyPrice(price)
        model.set('price', ((alv / 100) * price) + price)
      } else {
        model.setHourlyPrice(price)
        model.set('price', price)
      }
    }
    return value
  },
  hourlyType: function(direction, value) {
    if (direction == 'ModelToView') {
      if (value) {
        var isIt = isHourlyPriceType(value)
        if (isIt) {
          return true
        }

      }
    }
    return false
  },
  totalType: function(direction, value) {
    if (direction == 'ModelToView') {
      if (value) {
        if (isHourlyPriceType(value)) {
          return false
        }
      }

    }
    return true
  },
  disableNotOpen: function(direction, value) {
    if (direction === 'ModelToView') {
      if (value === 'OPEN') {
        return true
      }
    }
    return false
  },
})

var userBillableItemCollection = Backbone.Collection.extend({
  model: relationalBillableItemModel,
  url: function() {
    if (this.billId) {
      return '/api/person/bills/' + this.billId + '/billableitems'
    }
    throw 'no url found!'
  },
})

var BillModel = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a.billableitems = new userBillableItemCollection(a.billableitems, { parse: true })
    a.person = new personModel(a.person)
    a.sharedCustomer = new sharedCustomerModel(a.sharedCustomer)
    a.contractorBillableItems = new collection(a.contractorBillableItems)
    Backbone.Model.apply(this, arguments)
  },
  validation: {
    billingdate: {
      pattern: '[0-3][0-9].[0-1][0-9].[1-2][0-9][0-9][0-9]',
      msg: 'Pakollinen kenttä',
    },
    sharedCustomer: function(value) {
      if (!this.get('sharedCustomer').get('id')) {
        if (this.get('customer')) {
          return null
        }

        return 'Asiakas täytyy valita'
      }

    },

    person: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
  },
  defaults: {
    opened: 'closed',
    totalPrice: 0,
  },

  parse: function(data, options) {
    data = data || {}
    if (this.attributes.billableitems) {
      this.attributes.billableitems.reset(data.billableitems, { parse: true })
      data.billableitems = this.attributes.billableitems
    }
    if (this.attributes.sharedCustomer) {
      this.attributes.sharedCustomer.set(data.sharedCustomer, { parse: true })
      data.sharedCustomer = this.attributes.sharedCustomer
    }
    if (this.attributes.person) {
      var d = this.attributes.person.parse(data.person)
      this.attributes.person.set(data.person, { parse: true })
      data.person = this.attributes.person
    }
    return data
  },
})

var billsCollection = Backbone.Collection.extend({
  model: BillModel,
  url: function() {
    var id = this.personId || Appregistry.models.person.id
    return 'api/person/' + id + '/bills'
  },
})

var oldBillsCollection = Backbone.Collection.extend({
  model: BillModel,
  url: function() {
    var id = this.personId || Appregistry.models.person.id
    return 'api/person/' + id + '/oldbills'
  },
})

var salaryCalculationModel = Backbone.Model.extend({
  urlRoot: '/api/calc/frombill',
})


export default {
  billsCollection: billsCollection,
  relationalBillModel: BillModel,
  userBillableItemCollection: userBillableItemCollection,
  salaryCalculationModel: salaryCalculationModel,
  receiptCollection: receiptCollection,
  oldBillsCollection: oldBillsCollection,
}


