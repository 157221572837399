import tesDTOCollection from 'collections/admin/tes/tesDTOCollection'
import appregistry from 'libs/appregistry/appregistry'

var authModel = Backbone.Model.extend({
  initialize: function(args) {
    this.defers = []
    this.attributes.Appregistry.models.person.on('loggedIn', this.loginChanged, this)
    this.tesCollection = new tesDTOCollection()
  },
  loginChanged: function(args, what) {
    this.defers = []
    if (this.attributes.Appregistry.models.person.attributes.role === 'ROLE_ADMIN') {
      this.defers.push(this.tesCollection.fetch())
      this.defers.push(this.attributes.Appregistry.publicSettings.fetch())
      this.defers.push(this.attributes.Appregistry.peopleCollection.fetch())
    } else {
      var defer = $.Deferred()
      this.defers.push(defer)
      defer.resolve()
    }
    return this.defers
  },
  close: function() {
    this.get(this.attributes.Appregistry.models.person.off('loggedIn'))
  },

})
export default authModel


