import remunerationCollection from 'collections/admin/remuneration/remunerationCollection'
import remunerationModel from 'models/admin/remuneration/remunerationModel'

export default Backbone.Model.extend({
  constructor: function(a, b, c) {
    a.remunerations = new remunerationCollection(a.remunerations || {}, { parse: true })
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data) {
    data = data || {}
    if (this.attributes.remunerations) {
      this.attributes.remunerations.reset(data.remunerations)
      data.remunerations = this.attributes.remunerations
    }
    return data
  },
})

