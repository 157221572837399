import moment from 'moment'

var contractorModel = Backbone.Model.extend({})

var contractorCollection = Backbone.Collection.extend({
  model: contractorModel,
})

var billableItemModel = Backbone.Model.extend({})

var bllableItemCollection = Backbone.Collection.extend({
  model: billableItemModel,
})

var allowanceModel = Backbone.Model.extend({})

var allowancesCollection = Backbone.Collection.extend({
  model: allowanceModel,
})

var payrowModel = Backbone.Model.extend({})


var fullSalaryModel = Backbone.Model.extend({
  initialize: function(a, b, c) {
    this.set('billableitems', new bllableItemCollection([], { parse: true }))
    this.set('allowances', new bllableItemCollection([], { parse: true }))
    this.set('contractorBillableItems', new bllableItemCollection([], { parse: true }))
    this.set('subcontractorBillableItems', new bllableItemCollection([], { parse: true }))
    this.set('extContractorBillableItems', new bllableItemCollection([], { parse: true }))
    this.set('assignedItems', new bllableItemCollection([], { parse: true }))
    this.set('payRows', new payrowModel({}, { parse: true }))

  },
  parse: function(data) {
    data = data || {}
    if (data && data.billableitems) {
      this.attributes.billableitems.reset(data.billableitems)
    }
    if (data && data.allowances) {
      this.attributes.allowances.reset(data.allowances)
    }
    if (data && data.contractorBillableItems) {
      this.attributes.contractorBillableItems.reset(data.contractorBillableItems)
    }
    if (data && data.subcontractorBillableItems) {
      this.attributes.subcontractorBillableItems.reset(data.subcontractorBillableItems)
    }
    if (data && data.extContractorBillableItems) {
      this.attributes.extContractorBillableItems.reset(data.extContractorBillableItems)
    }
    if (data && data.assignedItems) {
      this.attributes.assignedItems.reset(data.assignedItems)
    }
    if (data && data.payRows) {
      this.attributes.assignedItems.reset(data.assignedItems)
      this.attributes.payRows.set(data.payRows)
    } else {
      this.attributes.payRows.clear()
    }

    data.billableitems = this.attributes.billableitems
    data.allowances = this.attributes.allowances
    data.contractorBillableItems = this.attributes.contractorBillableItems
    data.subcontractorBillableItems = this.attributes.subcontractorBillableItems
    data.extContractorBillableItems = this.attributes.extContractorBillableItems
    data.assignedItems = this.attributes.assignedItems
    data.payRows = this.attributes.payRows
    return data
  },
})
var salaryListModel = Backbone.Model.extend({
  urlRoot: '/api/admin/salarylist',
  initialize: function(a, b, c) {
    this.set('fullSalary', new fullSalaryModel({}))
    this.set('freeItems', new bllableItemCollection([]))
    this.set('contractorItems', new contractorCollection([], { parse: true }))
    this.set('subcontractorItems', new contractorCollection([], { parse: true }))
    this.set('extContractorItems', new contractorCollection([], { parse: true }))
  },
  parse: function(data) {
    if (this.attributes.fullSalary) {
      this.attributes.fullSalary.parse(data.fullSalary)
      this.attributes.fullSalary.set(data.fullSalary)
      data.fullSalary = this.attributes.fullSalary

      //data.fullSalary = this.attributes.fullSalary.parse(data.fullSalary)
    }
    if (this.attributes.freeItems) {
      this.attributes.freeItems.reset(data.freeItems)
      data.freeItems = this.attributes.freeItems
    }
    if (data && data.contractorItems) {
      this.attributes.contractorItems.reset(data.contractorItems)
      data.contractorItems = this.attributes.contractorItems
    }
    if (data && data.subcontractorItems) {
      this.attributes.subcontractorItems.reset(data.subcontractorItems)
      data.subcontractorItems = this.attributes.subcontractorItems
    }
    if (data && data.extContractorItems) {
      this.attributes.extContractorItems.reset(data.extContractorItems)
      data.extContractorItems = this.attributes.extContractorItems
    }
    return data
  },
  validation: {
    intervalstart: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
    intervalstop: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
    payday: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
    person: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
  },
  defaults: {
    state: 'OPEN',
    intervalstart: moment().startOf('month').format('DD.MM.YYYY'),
    intervalstop: moment().endOf('month').format('DD.MM.YYYY'),
  },
})

var adminSalaryListCollection = Backbone.Collection.extend({
  model: salaryListModel,
  url: '/api/admin/salarylist',
})
export default adminSalaryListCollection

