import Backbone from 'backbone'
import personReport from 'models/admin/personReport'

var peopleColleciton = Backbone.Collection.extend({
  url: '/api/admin/report/people', // TODO: change this model to container having person and calculations
  model: personReport,
  initialize: function() {

  },
})

export default peopleColleciton

