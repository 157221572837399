import reportView from './quicksight-advance.html'

import Marionette from 'backbone.marionette'
import Backbone from 'backbone'
const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk')

var model = Backbone.Model.extend({
  url: '/api/admin/quicksight/advance-salary',
})


var generalReport = Marionette.View.extend({
  initialize: function() {
    this.model = new model({})
    this.model.fetch({
      success: (s) => {
        this.render()
      },
    })
  }, template: function(serialized) {
    return _.template(reportView)({
      items: serialized,
    })
  }, events: {}, onRender: function() {
    let embedUrl = this.model.get('embedUrl')
    if (embedUrl) {
      var containerDiv = document.getElementById('embeddingContainer')
      var options = {
        // replace this dummy url with the one generated via embedding API
        url: embedUrl, container: containerDiv, parameters: {
          country: 'Finland',
        }, scrolling: 'no', height: '1800px', width: '1600px', locale: 'en-US', footerPaddingEnabled: true,
      }
      this.dashboard = QuickSightEmbedding.embedDashboard(options)
    }
    return this
  },
})
export default generalReport

