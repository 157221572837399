import Appregistry from 'libs/appregistry/appregistry'
import ropoLayout from './ropoLayout.html'
import statusView from './statusView'
import statusUpdatesView from './statusUpdatesView'


var updateModel = Backbone.Model.extend({
  urlRoot: '/api/admin/ropo/fetchupdates',
  url: function() {
    var url = this.urlRoot
    if (this.get('date')) {
      url = url + '?date=' + this.get('date')
    }
    return url
  },
})
var ropoView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(ropoLayout)({
      items: serialized,
    })
  },
  regions: {
    updates: '#ninjou-statuses',
    statusupdates: '#ninjou-status-updates',
  },
  initialize: function() {
    this.model = new updateModel()
  },
  changeDate: function(e) {
    var val = $(e.currentTarget).val()
    if (val) {
      var array = val.split('.')
      val = array[2] + '-' + array[1] + '-' + array[0]
    }
    this.model.set({ date: val })
  },
  onRender: function() {
    $(this.el).find('.dateInput').pickadate(window.helpers.pickadateOptions)
    this.showChildView('updates', new statusView())
    this.showChildView('statusupdates', new statusUpdatesView())
  },
  events: {
    'click .fetchUpdates': 'fetchUpdates',
    'click .get-updates': 'fetchWithDate',
    'change input#date': 'changeDate',
  },
  fetchWithDate: function(e) {
  },
  fetchUpdates: function(event) {
    event.preventDefault()
    this.model.fetch({
      success: function(mdl) {
      },
    })
  },
})
export default ropoView

