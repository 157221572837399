import Appregistry from 'libs/appregistry/appregistry'
import tesHtml from 'templates/admin/tes/tes-layout.html'
import tesCollection from 'collections/admin/tes/tesCollection'
import tesEditView from 'views/admin/tes/tesEditView'
import tesCompositeView from 'views/admin/tes/tesCompositeView'
/*
       Get render list view for tesis and show single tes edit view
       */
export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(tesHtml)({
      items: serialized,
    })
  },
  tagName: 'div',
  className: 'row-fluid',
  regions: {
    tes: '.tes',
    tesList: '.tesTable',
  },
  initialize: function() {
    this.collection = new tesCollection()
  },
  events: {
    'click .addTes': 'addTes',
  },
  onDestroy: function() {
    Appregistry.global_event_obj.off('editTesItem')
  },
  collectionEvents: {
    editItem: 'editItem',
  },
  onRender: function() {
    Appregistry.global_event_obj.on('editTesItem', this.editItem, this)
    this.getRegion('tesList').show(new tesCompositeView({ collection: this.collection }))
    this.collection.fetch()
  },
  addTes: function() {
    this.getRegion('tes').show(new tesEditView({ collection: this.collection }))
  },
  editItem: function(id) {
    this.getRegion('tes').show(new tesEditView({ model: this.collection.get(id) }))
  },
})

