import html from './payRowHtml.html'

var payrowView = Backbone.Marionette.View.extend({
  tagName: 'div',
  className: function() {

  },
  template: function(serialized) {
    return _.template(html)({
      payrow: serialized || [],
    })
  },
})
export default payrowView

