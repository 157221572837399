import Appregistry from 'libs/appregistry/appregistry'
import reportView from './general-report.html'
import converter from 'libs/converter'
import moment from 'moment'

var report = Backbone.Model.extend({
  defaults: {
    opened: 'closed',
  },
  urlRoot: '/api/admin/general-report',
})

var generalReport = Backbone.Marionette.View.extend({
  initialize: function() {
    this.model = new report()

    var self = this
    this.model.fetch({
      success: function() {
        self.render()
      },
    })
    this.modelBinder = new Backbone.ModelBinder()
  },
  template: function(serialized) {
    return _.template(reportView)({
      items: serialized,
    })
  },
  events: {
    'click #calculate-report': 'fetchReport',
  },
  onRender: function() {
    return this
  },
})
export default generalReport

