import 'backbone.paginator'

var workItemModel = Backbone.Model.extend({})
export default Backbone.PageableCollection.extend({
  queryParams: {
    currentPage: 'page',
    pageSize: 'limit',
    newItems: true,
  },
  state: {
    pageSize: 100,
    // You can use 0-based or 1-based indices, the default is 1-based.
    // You can set to 0-based by setting ``firstPage`` to 0.
    firstPage: 0,
    newItems: true,
  },
  model: workItemModel,
  url: 'api/admin/worklist',
  parseRecords: function(resp) {
    return resp
  },
})

