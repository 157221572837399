import Appregistry from 'libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import converter from 'libs/converter'
import receiptCollectionView from '../receipts/receiptCollectionView'
import billableItemTemplate from './billable-item.html'
import deleteBillModal from './delete-billableitem-modal.html'

export default Backbone.Marionette.View.extend({
  saving: false,
  template: function(serialized) {
    const fullPrice = serialized.compensationAlvAmount + serialized.price
    return _.template(billableItemTemplate)({
      billableItem: serialized,
      person: Appregistry.models.person,
      fullPrice
    })
  },
  initialize: function() {
    this.modelBinder = new modelBinder()
    this.save = this.saveItem
    var self = this
    /*
              this.model.get('receipts').on('add remove', function (change) {
                  self.model.validate()
              })
              */
    this.model.on('validated:valid', function(model) {
      self.$el.find('.billableite-content').removeClass('validation-error')
    })
    this.model.on('validated:invalid', function(model, errors) {
      self.$el.find('.billableite-content').addClass('validation-error')
    })
  },
  regions: {
    receipts: '.receipts-region',
  },
  modelEvents: {
    change: 'modelChanged',
  },
  events: {
    'click .open-reimbursements': 'openReimbursements',
    'click .open-workhours': 'openWorkhours',
    'click .save_item': 'saveItem',
    'click .delete_billable_item': 'deleteModel',
    'click .destroyBillableItem': 'destroyBillableItem',
    'click .date-input': 'openDateInput',
    'click .open-expence-reimbursement': 'openExpenceReimbursements',
  },
  openDateInput: function() {
    this.$el.find('.date-input').stop()
    this.$el.find('.date-input').pickadate(window.helpers.pickadateOptions)
  },
  className: 'row-fluid medium-margins billableItemContainer',
  openReimbursements: function() {
    this.$el.find('.work-hours').hide()
    this.$el.find('.reimbursement-of-expenses').hide()
    this.$el.find('.total_reimbursements').toggle()
  },
  openExpenceReimbursements: function() {
    this.$el.find('.total_reimbursements').hide()
    this.$el.find('.work-hours').hide()
    this.$el.find('.reimbursement-of-expenses').toggle()
  },
  openWorkhours: function() {
    this.$el.find('.reimbursement-of-expenses').hide()
    this.$el.find('.total_reimbursements').hide()
    this.$el.find('.work-hours').toggle()
  },
  modelChanged: function(model, event) {
    if (event && event.changeSource === 'ModelBinder' && this.relevantChanges()) {
      var kilometers = this.model.get('kilometers')
      var travelcost = this.model.get('travelcost')
      if (kilometers && travelcost > 0) {
        this.$el.find('.travel-notification').removeClass('hidden')
      } else {
        this.$el.find('.travel-notification').addClass('hidden')
      }

      var alvChanged = this.alvChanged()
      if (this.model.get('type') == 'AMOUNT' && isNaN(this.model.get('billableWorkHours'))) {
        this.model.set({ billableWorkHours: 1 }, { silent: true })
      }
      if (this.model.isValid(true)) {
        this.save(alvChanged)
        this.bindModel()
      } else {
        if (!this.$el.find('.total_reimbursements').is(':visible')) {
          var txt = this.model.validate() || []
          $.jGrowl(Object.values(txt).join('<br/>'), { life: 30000 })
        }
      }
    }
  },
  alvChanged: function() {
    var alvChanged = false
    _.each(Object.keys(this.model.changed), function(key) {
      if (key === 'alv') {
        alvChanged = true
      }
    })
    return alvChanged
  },
  setCopyString: function() {
    this.$el
      .find('[name="billString"]')
      .html(
        this.model.get('date') +
        ' ' +
        this.model.get('destination') +
        ' ' +
        (parseFloat(this.model.get('price')).toFixed(2) + ' €').replace('.', ','),
        (parseFloat(this.model.get('price')).toFixed(2) + ' €').replace('.', ','),
      )
  },
  relevantChanges: function() {
    var relevant = false
    _.each(Object.keys(this.model.changed), function(key) {
      if (key !== 'customer' && key !== 'lastSaved' && key !== 'state') {
        relevant = true
      }
    })
    return relevant
  },
  saveItem: function(triggerAlv) {
    if (!this.model.isValid(true)) return false
    $('.ajax-disabler').css('visibility', 'visible')
    $('.ajax-disabler').css('opacity', '0.8')
    var id = this.model.id

    this.model.save(
      { wait: true },
      {
        success: function() {
          if (triggerAlv) {
            Appregistry.global_event_obj.trigger('alvChanged', id)
          }
          $('[name=alv]').trigger('change.select2')
          $('.ajax-disabler').css('opacity', '0')
          $('.ajax-disabler').css('visibility', 'hidden')
        },
        failure: function() {
          $('.ajax-disabler').css('opacity', '0')
          $('.ajax-disabler').css('visibility', 'hidden')
        },
      },
    )
  },
  onDestroy: function() {
    this.model.off()
    this.modelBinder.unbind()
    if (this.model.get('receipts') && this.model.get('receipts').off) {
      this.model.get('receipts').off()
    }
  },
  deleteModel: function() {
    this.$el.find('.deleteBillableItemModal').modal('show')
  },
  destroyBillableItem: function() {
    var self = this
    var elem = this.$el.find('.deleteBillableItemModal')
    elem.on('hidden.bs.modal', function() {
      elem.off()
      self.model.destroy().success(function() {
        $.jGrowl('Laskutusrivi poistettu', { life: 2000 })
        self.destroy()
      })
    })
    this.$el.find('.deleteBillableItemModal').modal('hide')
  },
  onRender: function() {
    var self = this
    this.bindModel()
    this.$el.append(deleteBillModal)
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    this.$el.find('select').on('click', function(evt) {
      // Do something
    })
    this.model.get('receipts').on('add remove', function(change) {
      self.saveItem()
    })
    this.$el.find('.timeInput').pickatime()
    Backbone.Validation.bind(this)
    var index = this.model.collection.indexOf(this.model)
    if (isFinite(index)) {
      this.$el.find('.billable-item-index').text(index + 1)
    }
    // this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    var receipts = this.model.get('receipts')
    var receiptCollectionView2 = new receiptCollectionView({
      collection: receipts,
      billableItemId: this.model.id,
    })
    this.getRegion('receipts').show(receiptCollectionView2)
    this.model.validate()
    return this
  },
  bindModel: function() {
    var bindings = {
      date: '[name=date]',
      alv: { selector: '[name=alv]', converter: this.model.alvConverter },
      destination: '[name=destination]',
      totalPrice: [{ selector: '[name=totalPrice]', converter: this.model.priceConv }],
      alvPrice: [{ selector: '[name=alvPrice]', converter: this.model.alvPriceConv }],
      workhours: [{ selector: '[name=workhours]' }],
      billableWorkHours: [{ selector: '[name=billableWorkHours]', converter: this.model.workhourConv }],
      route: '[name=route]',
      addressStart: '[name=addressStart]',
      addressStop: '[name=addressStop]',
      kilometers: { selector: '[name=kilometers]', converter: converter.integerConverter },
      vehicle: '[name=vehicle]',
      startdate: '[name=startdate]',
      time: '[name=time]',
      returndate: '[name=returndate]',
      returntime: '[name=returntime]',
      fulldaycompensation: { selector: '[name=fulldaycompensation]', converter: converter.integerConverter },
      partialdaycompensation: {
        selector: '[name=partialdaycompensation]',
        converter: converter.integerConverter,
      },
      lunchcompensation: { selector: '[name=lunchcompensation]', converter: converter.integerConverter },
      stayOverNight: '[name=stayOverNight]',
      showInResult: '[name=showInResult]',
      vehicleRegisterNumber: '[name=vehicleRegisterNumber]',
      workShiftStarted: '[name=workShiftStarted]',
      workShiftStartedTime: '[name=workShiftStartedTime]',
      workShiftEnded: '[name=workShiftEnded]',
      workShiftEndedTime: '[name=workShiftEndedTime]',

      billed_reimbursements: { selector: '[name=billed_reimbursements]', converter: converter.euroConverter },
      billed_reimbursements_alv: {
        selector: '[name=billed_reimbursements_alv]',
        converter: converter.euroConverter,
      },
      billed_reimbursements_without_alv: {
        selector: '[name=billed_reimbursements_without_alv]',
        converter: converter.euroConverter,
      },

      foreignAllowance: { selector: '[name=foreignAllowance]', converter: converter.euroConverter },

      hotelcost: { selector: '[name=hotelcost]', converter: converter.euroConverter },
      hotelcost_alv10: { selector: '[name=hotelcost_alv10]', converter: converter.euroConverter },
      hotelcost_alv14: { selector: '[name=hotelcost_alv14]', converter: converter.euroConverter },
      hotelcost_alv24: { selector: '[name=hotelcost_alv24]', converter: converter.euroConverter },

      travelcost: { selector: '[name=travelcost]', converter: converter.euroConverter },
      travelcost_alv10: { selector: '[name=travelcost_alv10]', converter: converter.euroConverter },
      travelcost_alv14: { selector: '[name=travelcost_alv14]', converter: converter.euroConverter },
      travelcost_alv24: { selector: '[name=travelcost_alv24]', converter: converter.euroConverter },

      othercost: { selector: '[name=othercost]', converter: converter.euroConverter },
      othercost_alv10: { selector: '[name=othercost_alv10]', converter: converter.euroConverter },
      othercost_alv14: { selector: '[name=othercost_alv14]', converter: converter.euroConverter },
      othercost_alv24: { selector: '[name=othercost_alv24]', converter: converter.euroConverter },
      targetetAmount: { selector: '[name=targeted]', converter: converter.euroConverter },
      hourlyprice: [{ selector: '[name=hourlyprice]', converter: this.model.hourlyPriceConv }],
      state: [
        {
          selector: 'input',
          elAttribute: 'enabled',
          converter: this.model.disableNotOpen,
        },
        {
          selector: 'select',
          elAttribute: 'enabled',
          converter: this.model.disableNotOpen,
        },
      ],
      type: [
        { selector: '[name=type]' },
        {
          selector: '[name=alvPrice]',
          elAttribute: 'enabled',
          converter: this.model.totalType,
        },
        {
          selector: '[name=totalPrice]',
          elAttribute: 'disabled',
        },
        {
          selector: '[name=hourlyprice]',
          elAttribute: 'enabled',
          converter: this.model.hourlyType,
        },
      ],
    }
    this.enableFields()
    if (this.model) {
      this.modelBinder.bind(this.model, this.el, bindings)
    }
    this.disableFields()
    this.setCopyString()
  },
  disableFields: function() {
    if (this.model.get('state') !== 'OPEN') {
      this.$el.find('input').attr('disabled', true)
      this.$el.find('select').attr('disabled', true)
      //this.$el.find('.hide_not_open').hide()
    }
  },
  enableFields: function() {
    if (this.model.get('state') === 'OPEN') {
      this.$el.find('input').attr('disabled', false)
      this.$el.find('select').attr('disabled', false)
      //this.$el.find('.hide_not_open').show()
    }
  },
})

