import React from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

const currentPageStyle = (page, currentPage) => {
  return page == currentPage
    ? 'inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600'
    : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
}
export default function Pagination({ billReply, page, setPage }) {
  const pages = []
  const totalPages = Math.floor(billReply.doc_count / billReply.limit)
  pages.push(
    <a onClick={() => setPage(0)} key={0} className={currentPageStyle(0, billReply.page)} aria-current={page == billReply.page ? 'page' : 'false'}>
      {1}
    </a>
  )
  if (page < 4) {
    for (let i = 1; i < 6; i++) {
      pages.push(
        <a
          onClick={() => setPage(i)}
          key={i}
          className={currentPageStyle(i, billReply.page)}
          aria-current={page == billReply.page ? 'page' : 'false'}
        >
          {i + 1}
        </a>
      )
    }
    pages.push(<span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>)
  } else {
    pages.push(<span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>)
    pages.push(
      <a
        onClick={() => setPage(page - 1)}
        key={page - 1}
        className={currentPageStyle(page - 1, billReply.page)}
        aria-current={page == billReply.page ? 'page' : 'false'}
      >
        {page}
      </a>
    )
    pages.push(
      <a
        onClick={() => setPage(page)}
        key={page}
        className={currentPageStyle(page, billReply.page)}
        aria-current={page == billReply.page ? 'page' : 'false'}
      >
        {page + 1}
      </a>
    )
    pages.push(
      <a
        onClick={() => setPage(page + 1)}
        key={page + 1}
        className={currentPageStyle(page + 1, billReply.page)}
        aria-current={page == billReply.page ? 'page' : 'false'}
      >
        {page + 2}
      </a>
    )
    pages.push(<span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>)
  }
  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <a
          onClick={() => setPage(page > 0 ? page - 1 : 0)}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Edellinen
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages}
        {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}

        <a
          onClick={() => setPage(totalPages - 1)}
          className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          {totalPages || ''}
        </a>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          onClick={() => setPage(page < totalPages ? page + 1 : totalPages)}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          Seuraava
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  )
}
