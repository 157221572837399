import Appregistry from 'libs/appregistry/appregistry'
import personTemplate from 'templates/admin/marionette/people-select-template.html'

var singleSalryListView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(personTemplate)({
      people: Appregistry.peopleCollection,
      items: serialized,
    })
  },
  changed: function() {
  },
  onRender: function() {
    return this
  },
})
export default singleSalryListView

