import Appregistry from 'js/libs/appregistry/appregistry'
import addedCustomerItemView from 'js/views/user/customers/addedCustomerItemView'
import customerLayout from 'templates/user/customers/added-customer-layout.html'

/**
 * Here comes table of customers with remove one customer button
 */
export default Backbone.Marionette.CompositeView.extend({
  template: customerLayout,
  childView: addedCustomerItemView,
  childViewContainer: '.added-customers',
  className: 'col-md-12',
  initialize: function() {
    this.collection = Appregistry.models.person.get('sharedCustomers')
  },
  events: {
    'click .removeCustomer': 'removeCustomer',
  },
  removeCustomer: function(event) {
    var id = event.currentTarget.id
    var model = this.collection.get(id)
    model
      .destroy({
        url: 'api/person/' + Appregistry.models.person.id + '/sharedcustomers/' + id,
      })
      .then(
        function(a, b) {
          Appregistry.models.person.fetch()
        },
        function(a, b) {
        },
      )
  },
  onRender: function() {
    if (!Appregistry.models.person.get('sharedCustomers')) {
      this.$el.find('.table').addClass('hidden')
    } else {
      this.$el.find('.table').removeClass('hidden')
    }
  },
})

