import * as React from 'react'
import { Controller } from 'react-hook-form'

export const NinjouInput = ({ errors, name, validate, control, className, inputStyles, onBlur }) => {
  const err = errors ? errors[name] : {}
  const error = err?.type ? 'border-red-500 border-1' : ''
  return (
    <Controller
      render={({ field: { onChange, name, value } }) => (
        <input className={`${error} ${inputStyles} ${className}`} name={name} value={value} onChange={onChange} />
      )}
      onBlur={onBlur}
      name={name}
      rules={validate}
      control={control}
    />
  )
}
