import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Bank = ({ token }) => {
  const [transactions, setTransactions] = useState([])
  const [transaction, setTransaction] = useState([])
  const fetchBank = () => {
    console.log('fetch')
    axios
      .post(`api/admin/bank/filelist`, {}, {
        headers: {
          authtoken: token,
        },
      })
      .then(({ data }) => {
        console.log('reply', data)
        setTransactions(data)
      })
  }

  useEffect(() => {
    axios
      .get(`api/admin/bank/filelist`, {
        headers: {
          authtoken: token,
        },
      })
      .then(({ data }) => {
        console.log('reply', data)
        setTransactions(data)
      })
  }, [])

  const downloadFile = (item) => {
    console.log('downloadFile', item)
    axios
      .get(`api/admin/bank/filelist/${item.fileReference}`, {
        headers: {
          authtoken: token,
        },
      })
      .then(({ data }) => {
        console.log('reply', data)
        setTransaction(data)
      })
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Pankki</h1>
      <div className="mb-4 space-x-2">
        <button
          className={`px-4 py-2 bg-green-500 text-white`}
          onClick={fetchBank}
        >
          Hae tapahtumat
        </button>

      </div>

      {/* Table rendered with Tailwind */}
      <table className="min-w-full divide-y divide-gray-200 mb-4">
        <thead className="bg-gray-50">
        <tr>
          <th className="px-4 py-2 text-left">fileReference</th>
          <th className="px-4 py-2 text-left">targetId</th>
          <th className="px-4 py-2 text-left">serviceIdOwnerName</th>
          <th className="px-4 py-2 text-left">fileType</th>
          <th className="px-4 py-2">Toiminnot</th>
          <th className="px-4 py-2">Poista</th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {transactions.map((item) => (
          <tr key={item.id} className="hover:bg-gray-50">
            <td className="px-4 py-2"><a onClick={() => downloadFile(item)}>{item.fileReference}</a></td>
            <td className="px-4 py-2">{item.targetId}</td>
            <td className="px-4 py-2">{item.serviceIdOwnerName}</td>
            <td className="px-4 py-2">{item.fileType}</td>
            <td className="px-4 py-2">{item.status}</td>
            <td className="px-4 py-2">
            </td>
            <td className="px-4 py-2">

            </td>
          </tr>
        ))}
        </tbody>
      </table>

    </div>
  )
}
export default Bank