import itemTemplate from 'templates/admin/remuneration/remuneration-item.html'

var remunerationItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(itemTemplate)({
      items: serialized,
    })
  },
  initialize: function() {
  },
  tagName: 'tr',
  modelEvents: {
    'change': 'render',
  },
  modelChanged: function() {
  },
  onRender: function() {
    return this
  },
  events: {
    'click .deleteRemuneration': 'deleteRemuneration',
    'click .editRemuneration': 'editRemuneration',
  },
  deleteRemuneration: function() {
    this.model.destroy()
  },
  editRemuneration: function() {
    this.model.collection.trigger('editItem', this.model)
  },

})

export default remunerationItemView

