import Backbone from 'backbone'
import sharedCustomerCollection from 'collections/user/sharedCustomerCollection'
import personSettings from 'models/personSettings'
import sharedCustomerModel from 'models/relational/relationalSharedCustomerModel'

var successLogin = function() {
}

var personModel = Backbone.Model.extend({
  constructor: function(attributes, options) {
    attributes = attributes || {}
    options = options || {}
    attributes.sharedCustomers = new sharedCustomerCollection(attributes.sharedCustomers || [], { parse: true })
    attributes.personSettings = new personSettings(attributes.personSettings || {}, { parse: true })
    Backbone.Model.call(this, attributes, options)
  },
  initIfNeeded: function() {
    if (!this.attributes) {
      this.attributes = {}
    }
    if (!this.attributes.sharedCustomers) {
      this.attributes.sharedCustomers = new sharedCustomerCollection([])
    }
    if (!this.attributes.personSettings) {
      this.attributes.personSettings = new personSettings({})
    }
  },
  parse: function(data) {
    data = data || {}
    this.initIfNeeded()
    if (this.attributes.sharedCustomers) {
      this.attributes.sharedCustomers.reset(data.sharedCustomers)
      data.sharedCustomers = this.attributes.sharedCustomers
    }
    if (this.attributes.personSettings) {
      this.attributes.personSettings.set(data.personSettings)
      data.personSettings = this.attributes.personSettings
    }
    return data
  },
  validation: {
    firstName: {
      required: true,
      maxLength: 20,
    },
    lastName: {
      required: true,
      maxLength: 20,
    },
    email: {
      pattern: 'email',
    },
    password: {
      required: true,
      minLength: 4,
    },
  },
  url: '/api/person',
  defaults: {
    loggedIn: false,
  },
  checkLogin: function() {
    var self = this
    var options = options || {}
    options.url = '/api/login'
    var fetch = this.fetch({
      url: '/api/login',
      success: function(model) {
        self.setAuth()
        self.trigger('loggedIn')
        return true
      },
      error: function() {
        self.clear()
        self.trigger('loggedIn')
        //window.location = "#"
      },
    })
    return fetch
  },
  trylogin: function() {
    var self = this
    var options = options || {}
    options.url = '/api/login'
    options.success = function(model,
                               status) {
      self.setAuth()
      $('.clear-when-logged').addClass('hidden')
      self.trigger('loggedIn')
      return true
    }
    options.error = function() {
      localStorage.setItem('authtoken', null)
      self.trigger('loggedIn')
      $.jGrowl('Email tai salasana on väärin', { life: 3000 })
    }
    options.wait = true
    return this.save(null, options)
  },
  logout: function() {
    localStorage.setItem('authtoken', null)
    var self = this
    return this.destroy({
      url: '/api/login',
      success: function() {
        self.clear()
        self.trigger('loggedIn')
      },
    })
  },
  setAuth: function() {
    var self = this
    localStorage.setItem('authtoken', self.get('authtoken'))
    $.ajaxSetup({
      beforeSend: function(xhr) {
        xhr.setRequestHeader('authtoken', self.get('authtoken'))
      },
    })
  },
  hasRole: function(role) {
    if (this.get('role') == role) {
      return true
    }
    return false
  },
})
export default personModel


