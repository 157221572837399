import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { Document, Page, pdfjs } from 'react-pdf'
import { PersonInfo } from './person-info-react'
import CustomerCard from './customer-card-react'
import BillCard from './bill-card-react'
import { Ostolaskut } from './ostolaskut-react'
import { Alihankinnat } from './alihankinnat-react'
import { useWindowWidth } from '@wojtekmaj/react-hooks'
import { useForm } from 'react-hook-form'
import { NinjouDatePicker } from '../ninjou-react-inputs'
import BillableItems from './billable-items'
import { NinjouInput } from '../form/text-input'
import { ControlledNumber } from '../form/number-input'
import { inputStyles } from '../form/input-styles'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

export const getVehicleType = (item) => {
  switch (item) {
    case '0':
      return 'Auto'
    case '1':
      return 'Moottorivene, enintään 50 hv'
    case '2':
      return 'Moottorivene, yli 50 hv'
    case '3':
      return 'Moottorikelkka'
    case '4':
      return 'Mönkijä'
    case '5':
      return 'Moottoripyörä'
    case '6':
      return 'Mopo'
    case '7':
      return 'Muu kulkuneuvo'
  }
  return ''
}

export const stateElement = (state) => {
  let options = {}
  switch (state) {
    case 'REQUESTED': // avoin
      options = {
        state: 'Ennakkoa pyydetty',
        bgColor: 'bg-cyan-50',
        textColor: 'text-gray-600',
      }
      break
    case 'APPROVED': // hyväksyttävänä
      options = {
        state: 'Ennakkoa hyväksytty',
        bgColor: 'bg-lime-300',
        textColor: 'text-gray-600',
      }
      break
    case 'DECLINED': // ei käytössä
      options = {
        state: 'Ennakkoa hylätty',
        bgColor: 'bg-orange-300',
        textColor: 'text-gray-900',
      }
      break
  }
  const classNames = `inline-flex items-center rounded-md ${options.bgColor} px-2 py-1 text-xs font-medium ${options.textColor} ring-1 ring-inset ring-green-600/20`
  return <span className={classNames}>{options.state}</span>
}

export const SideView = ({ bill, setBill, token, show, setShow }) => {
  if (!bill?.id) {
    return undefined
  }
  const [receiptUrl, setReceiptUrl] = useState('')
  const [renderNavButtons, setRenderNavButtons] = useState(false)
  const [selectedBill, setSelectedBill] = useState(bill)
  const width = useWindowWidth()
  const calculatedwidth = Math.min(width * 0.9, 828)

  const { control, getValues, register, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...selectedBill,
    },
  })

  const openReceipt = (url) => {
    setReceiptUrl(url)
  }
  useEffect(() => {
    console.log('bill', bill?.id)
    if (bill?.id) {
      axios
        .get(`/api/admin/bills/v2/${bill.id}`, {
          headers: {
            authtoken: token,
          },
        })
        .then((reply) => {
          setSelectedBill(reply.data)
          reset(reply.data)
        })
    }
  }, [bill])

  const closeSide = () => {
    console.log('closeSide')
    setReceiptUrl('')
    setSelectedBill({})
    setBill({})
    setShow(false)
  }
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
    setRenderNavButtons(true)
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }
  const previousPage = () => {
    changePage(-1)
  }
  const nextPage = () => {
    changePage(+1)
  }

  const onSubmit = (values) => {
    axios
      .put(`/api/admin/bills/v2/${selectedBill.id}`, values, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        setSelectedBill(reply.data)
        reset(reply.data)
      })
  }

  const onSubmitForm = (values) => {
    axios
      .put(`/api/admin/bills/v2/${selectedBill.id}`, values, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        setSelectedBill(reply.data)
        reset(reply.data)
      })
  }

  const loginUrl = `/api/admin/bills/redirect/${selectedBill.id}?authtoken=${token}`
  if (!selectedBill) {
    return undefined
  }
  const submitFn = handleSubmit(onSubmit)
  const formSubmit = handleSubmit(onSubmitForm)
  const handleBlur = () => {
    handleSubmit(onSubmitForm)
  }
  const okFunction = () => {
    handleSubmit(onSubmitForm)
    closeSide()
  }
  const inverseALV = register(`inverseALV`)
  const changeCheckbox = (event) => {
    inverseALV.onChange(event)
    submitFn()
  }
  const vatZeroReasonCode = register(`vatZeroReasonCode`)
  const selectChaged = (event) => {
    vatZeroReasonCode.onChange(event)
    submitFn()
  }

  const billReimbursementsFromCustomer = register('billReimbursementsFromCustomer')
  const billReimbursementsFromCustomerCheckbox = (event) => {
    billReimbursementsFromCustomer.onChange(event)
    submitFn()
  }

  const printRopo= () => {
    axios
      .get(`/api/admin/bills/${selectedBill.id}/ropo`, {
        headers: {
          authtoken: token,
        },
      })
      .then((reply) => {
        console.log(reply.data)
      })
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeSide}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                  <form onSubmit={formSubmit} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-4">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Lasku {getValues('id')} asiakkaalle {getValues('sharedCustomer.name')}
                            <p>
                              Alv%
                              <input
                                onBlur={handleBlur}
                                className="input-none w-14 ml-1"
                                {...register('alv', { required: true, onBlur: formSubmit })}
                              />
                            </p>
                            <select
                              id="vatZeroReasonCode"
                              {...vatZeroReasonCode}
                              name="vatZeroReasonCode"
                              autoComplete="vatZeroReasonCode"
                              onChange={(e) => selectChaged(e)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option value={1}>Esiintymispalkkio, arvonlisäverolaki 45§</option>
                              <option value={2}>Tekijänoikeusmaksu, arvonlisäverolaki 45§</option>
                              <option value={3}>
                                Käännetty EU-maiden välisen myyynnin verovelvollisuus, arvonlisäverodirektiivi 44 art.
                              </option>
                              <option value={4}>Kiinteistöjen myynti- tai vuokraus, arvonlisäverolaki 27 §</option>
                              <option value={5}>
                                Itse poimitut, luonnonvaraiset marjat ja sienet, jotka poimija myy sellaisenaan muualta
                                kuin erityisestä myyntipaikasta. Arvonlisäverolaki 59 §
                              </option>
                              <option value={6}>Varhaiskasvatuspalvelu, arvonlisäverolaki 38 a §</option>
                              <option value={7}>Koulutuspalvelu, arvonlisäverolaki 40 §</option>
                              <option value={8}>Vakuutuspalvelu, arvonlisäverolaki 44 §</option>
                              <option value={9}>
                                Sosiaalipalvelut, tulkkaus kuulo-, kuulonäkö- tai puhevamma tai muun vastaava vamma,
                                arvonlisäverolaki 38 §
                              </option>
                              <option value={10}>Vapaaehtoinen palveluraha</option>
                            </select>
                            <p>
                              {loginUrl && (
                                <a className="text-base font-semibold leading-6 text-blue-500" href={loginUrl}>
                                  Tarkastele laskua käyttäjän näkymässä
                                </a>
                              )}
                            </p>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="relative text-gray-400 hover:text-gray-500"
                            onClick={() => closeSide()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-wrap w-full">
                        <div className="flex-none w-64 m-4">
                          <div className="sm:col-span-1">Päiväys</div>
                          <div className="sm:col-span-1">
                            <NinjouDatePicker
                              submitFn={submitFn}
                              className={`width-110 ${inputStyles}`}
                              control={control}
                              name={`billingdate`}
                            />
                          </div>
                        </div>
                        <div className="flex-none w-64  m-4">
                          <div className="sm:col-span-1">Eräpäivä</div>
                          <div className="sm:col-span-1">
                            <NinjouDatePicker
                              submitFn={submitFn}
                              className={`width-110 ${inputStyles}`}
                              control={control}
                              name={`exactDueDate`}
                            />
                          </div>
                        </div>

                        <div className="flex-none w-64 m-4">
                          <div className="sm:col-span-1">Viitteenne</div>
                          <div className="sm:col-span-1">
                            <NinjouInput
                              inputStyles={inputStyles}
                              control={control}
                              name="yourcode"
                              onBlur={submitFn}
                            ></NinjouInput>
                          </div>
                        </div>
                        <div className="flex-none w-64 m-4">
                          <div className="sm:col-span-1">Laskun numero</div>
                          <div className="sm:col-span-1">
                            <ControlledNumber
                              inputStyles={inputStyles}
                              decimalScale={0}
                              control={control}
                              name="billNumber"
                              onBlur={submitFn}
                            ></ControlledNumber>
                          </div>
                        </div>
                        <div className="flex-none w-64 m-4">
                          <div className="sm:col-span-1">Laskuta kulukorvaukset asiakkaalta</div>
                          <div className="sm:col-span-1">
                            <input
                              id={`billReimbursementsFromCustomer`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              {...billReimbursementsFromCustomer}
                              onChange={(e) => billReimbursementsFromCustomerCheckbox(e)}
                            />
                          </div>
                        </div>
                        <div className="flex-none w-64  m-4">
                          <div className="sm:col-span-1">Rakennusalan käänteinen ALV</div>
                          <div className="sm:col-span-1">
                            <input
                              id={`inverseALV`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              {...inverseALV}
                              onChange={(e) => changeCheckbox(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-3 pt-6 px-4 sm:px-6 lg:px-8">
                      <BillCard bill={selectedBill} />
                      <PersonInfo person={selectedBill.person} />
                      <CustomerCard customer={selectedBill.sharedCustomer} />
                    </div>

                    {/*  person info */}

                    {/* Divider container */}
                    <BillableItems
                      control={control}
                      register={register}
                      submitForm={formSubmit}
                      selectedBill={selectedBill}
                      openReceipt={openReceipt}
                      token={token}
                      getValues={getValues}
                    ></BillableItems>
                    <Ostolaskut ostolaskut={selectedBill.extContractorBillableItems} token={token} />
                    <Alihankinnat alihankinnat={selectedBill.contractorBillableItems} />
                    {receiptUrl && (
                      <div>
                        <Document onLoadSuccess={onDocumentLoadSuccess} file={receiptUrl}>
                          <Page
                            pageNumber={pageNumber}
                            width={calculatedwidth}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </Document>
                        <p>
                          Sivu {pageNumber} / {numPages}
                        </p>
                      </div>
                    )}
                    {renderNavButtons && (
                      <div className="ml-10">
                        <button
                          className="text-blue-700 hover:text-blue-500"
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        >
                          Edellinen
                        </button>

                        <button
                          className="ml-4 text-blue-700 hover:text-blue-500"
                          disabled={pageNumber === numPages}
                          onClick={nextPage}
                        >
                          Seuraava
                        </button>
                      </div>
                    )}
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => closeSide()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={printRopo}
                          className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Tulosta ropo
                        </button>
                        <button
                          type="button"
                          onClick={okFunction}
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Ok
                        </button>
                        <button
                          type="submit"
                          style={{ display: 'none' }}
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
