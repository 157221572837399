import _ from 'underscore'
import Backbone from 'backbone'
import Appregistry from 'libs/appregistry/appregistry'
import 'backbone-validation'
import 'pickadate/lib/picker.date'
import 'pickadate/lib/picker.time'



(function(a, b) {
  let c = a.Router.prototype.route,
    d = function() {
    }
  b.extend(a.Router.prototype, {
    before: d,
    after: d,
    route: function(a, e, f) {
      f || (f = this[e])
      let g = b.bind(function() {
        var c = [a, b.toArray(arguments)],
          e
        b.isFunction(this.before) ? (e = this.before) : typeof this.before[a] != 'undefined' ? (e = this.before[a]) : (e = d)
        if (e.apply(this, c) === !1) return
        f && f.apply(this, arguments)
        var g
        b.isFunction(this.after) ? (g = this.after) : typeof this.after[a] != 'undefined' ? (g = this.after[a]) : (g = d), g.apply(this, c)
      }, this)
      return c.call(this, a, e, g)
    },
  })
})(Backbone, _)

_.extend(Backbone.Validation.callbacks, {
  valid: function(view, attr) {
    var element = view.$el.find('[name=' + attr + ']')
    element.removeClass('invalid')

    if (element.parent().find('.error-element')) {
      element.parent().find('.error-element').remove()
    }
  },
  invalid: function(view, attr, error) {
    var element = view.$el.find('[name=' + attr + ']')
    element.addClass('invalid')
    if (element.next().hasClass('error-element')) {
      element.next().remove()
    }
    var div = $('<div></div>').addClass('error-element').html(error)
    element.after(div)
  },
})
var permitted = [
  'about',
  'rekisteriseloste',
  'logout',
  'register',
  'email',
  'forgot/password',
  'forgot/checkemail',
  'forgot/checkemail',
  'registration/confirm',
  'password/changed',
  'calculation',
  '*actions',
]

var permittedRoutes = function(route) {
  return (
    route === 'about' ||
    route === 'rekisteriseloste' ||
    route === 'logout' ||
    route === 'register' ||
    route === 'email' ||
    route === 'forgot/password' ||
    route === 'forgot/checkemail' ||
    route === 'forgot/password/:id' ||
    route === 'registration/confirm/:id' ||
    route === 'password/changed' ||
    route === 'calculation' ||
    route === '*actions'
  )
}

var messages = {
  error_in_service: 'Palvelussa tapahtui virhe',
}
var translate = function(key) {
  return messages[key]
}

$(document).ajaxError(function(e, xhr, options) {
  if (xhr.status === 401) {
    if (console && console.log) {
      console.log('not logged in', e, xhr, options)
    }

    if (Appregistry.models.person.attributes.loggedIn) {
      Appregistry.models.person.clear({ silent: true })
      Appregistry.global_event_obj.trigger('loggedIn')
      $.jGrowl('Ole hyvä ja kirjaudu sisään')
    }

    setTimeout(function() {
      Appregistry.models.person.clear({ silent: true })
      var currentRoute = Backbone.history.fragment
      const rte = _.find(permitted, function(route) {
        if (route) {
          return currentRoute.indexOf(route) > -1
        }
        return false
      })
      if (!rte || rte.length <= 0) {
        Backbone.history.navigate('login', { trigger: true })
      }
    }, 250)
  }
  if (xhr.status == 400) {
    var errorMsg = 'Odottamaton virhe'
    var responseJson = xhr.responseJSON || {}
    console.log('error', responseJson)
    if (responseJson.message) {
      errorMsg = responseJson.message
    }
    $.jGrowl(errorMsg, { life: 10000 })
  }

  if (xhr.status == 406) {
    var errorMsg = 'Odottamaton virhe'
    var responseJson = xhr.responseJSON || []
    var msg = ''
    for (let indx in responseJson) {
      msg += responseJson[indx] + '\n'
    }

    $.jGrowl(msg || errorMsg, { life: 10000 })
  }

  if (xhr.status == 500) {
    var errorMsg = 'Odottamaton virhe'
    var responseJson = xhr.responseJSON || {}
    console.log('error', responseJson)
    if (responseJson.message) {
      errorMsg = translate(responseJson.message)
    }
    $.jGrowl(errorMsg, { life: 10000 })
  }

  if (xhr.status == 0) {
    var errorMsg = 'Ei yhteyttä palvelimeen'
    var responseJson = xhr.responseJSON || {}
    if (responseJson.message) {
      errorMsg = responseJson.message
    }
    $.jGrowl(errorMsg, { life: 10000 })
  }

  if (xhr.status == 403) {
    console.log(e, xhr, options)
    // window.location = "#"
    Backbone.history.navigate('logout', { trigger: true })
    $.jGrowl('Ei oikeutta katsella tietoja!', { life: 4000 })
  }
})

var $loading = $('.ajax-loader').hide()
$(document).ajaxStart(function() {
  $loading.fadeIn()
  setTimeout(function() {
    $loading.fadeOut()
  }, 5000)
})
$(document).ajaxStop(function() {
  $loading.fadeOut()
})

window.payment = function(sendBillMethod) {
  if (sendBillMethod === 'EMAIL') {
    return 'Sähköposti'
  } else if (sendBillMethod === 'PAPERBILL') {
    return 'Posti'
  } else if (sendBillMethod === 'EBILL') {
    return 'Verkkolasku'
  }
  return ''
}

window.helpers = {}
window.helpers.permittedRoutes = permittedRoutes
window.helpers.state = function(state) {
  if (state === 'OPEN') {
    return 'Avoin'
  } else if (state === 'PAID') {
    return 'Maksettu'
  } else if (state === 'LOCKED') {
    return 'Lukittu'
  } else if (state === 'APPROVED') {
    return 'Hyväksytty'
  } else if (state === 'PENDING') {
    return 'Hyväksyttävänä'
  } else if (state === 'CLOSED') {
    return 'Lähetetty'
  } else if (state === 'HYVITETTY') {
    return 'Hyvitetty'
  } else if (state === 'WAITING_FOR_PAYMENT') {
    return 'Urakoitsija lähettänyt maksettavaksi'
  } else if (state === 'WAITING_SUBCONTRACTOR') {
    return 'Odottaa alihankkijaa'
  } else if (state === 'DECLINED') {
    return 'Alihankkija hylännyt'
  } else if (state === 'APPROVED') {
    return 'Alihankkija hyväksynyt'
  } else if (state === 'PAY_NOW') {
    return 'Maksa heti'
  } else if (state === 'PAID') {
    return 'Maksettu'
  }
  return ''
}

window.helpers.rowType = function(type) {
  if (type === 'BILLABLE_ITEM') {
    return 'Laskutus'
  } else if (type === 'SUBCONTRACTOR_ITEM') {
    return 'Alihankinta'
  } else if (type === 'CONTRACTOR_ITEM') {
    return 'Päälasku'
  } else if (type === 'EXT_CONTRACTOR_ITEM') {
    return 'Ostolasku'
  }
}

window.helpers.extContractigState = function(state) {
  if (state === 'OPEN') {
    return 'Avoin'
  } else if (state === 'WAITING_FOR_PAYMENT') {
    return 'Urakoitsija lähettänyt maksettavaksi'
  } else if (state === 'PAY_NOW') {
    return 'Maksa heti'
  } else if (state === 'PAID') {
    return 'Maksettu'
  }

  return ''
}
var tarkistus = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
]
window.helpers.isSotu = function(str) {
  if (!str || str.length != 11) {
    return 'Sotu pituus on väärin'
  }
  /*
      var nmbrs = str.substr(0,6) + str.substr(7,3)
      var numbersStr = nmbrs.match(/\d+/g).map(Number).join('')
      var number = parseInt(numbersStr)
      var jako = number / 31
      var kokonaisosa = parseInt(jako)
      var digits = jako - kokonaisosa
      var luku = Math.round(digits * 31)
      if( str[10] != tarkistus[luku]) {
        return "Sotu on väärä"
      }
      */
  return null
}

window.helpers.type = function(type) {
  if (type === 'CONTRACT') {
    return 'Urakka'
  } else if (type === 'AMOUNT') {
    return 'Tuntilaskutus'
  } else if (type === 'STUDY') {
    return 'Opintomatka'
  } else if (type === 'NEGOTIATION') {
    return 'Neuvottelu'
  } else if (type === 'SUBSTRACTION') {
    return 'Vähennyslaskutus'
  } else if (type === 'KMREIMBURSEMENT') {
    return 'Kilometrikorvaus'
  } else if (type === 'BILLABLE_FULL_DAY_COMPENSATION') {
    return 'Kokopäiväraha'
  } else if (type === 'BILLABLE_PARTIAL_DAY_COMPENSATION') {
    return 'Puolipäiväraha'
  } else if (type === 'BILLABLE_LUNCH_COMPENSATION') {
    return 'Ateriakorvaus'
  }

  return ''
}

window.helpers.sendMethod = function(method) {
  if (method == 'EBILL') {
    return 'e-lasku'
  } else if (method == 'PAPERBILL') {
    return 'Paperilasku'
  } else if (method == 'EMAIL') {
    return 'Email'
  }
  return 'N/A'
}

window.helpers.percent = function(sum) {
  if (!sum) {
    return ''
  }
  if (sum.toFixed) {
    return (sum * 100).toFixed(2).toString().replace('.', ',') + ' %'
  }
  return ''
}

window.helpers.euro = function(str) {
  var return_value = ''
  if (isNaN(parseFloat(str))) {
    return_value = '0 €'
  } else {
    return_value = (parseFloat(str).toFixed(2) + ' €').replace('.', ',')
  }

  return return_value
}
window.helpers.pickadateOptions = {
  container: '.ninjou-pickadate-container',
  selectYears: true,
  selectMonths: true,
  onClose: function() {
    $(document.activeElement).blur()
  },
}

window.translate = {
  osk_cost_1: 'Ninjou osuus',
  osk_cost_2: 'Laitekulut',
  osk_cost_3: 'Toimistokulut',
  osk_cost_4: 'Web osuus',
  sharedcustomer_has_bills: 'Ei voi poistaa: asiakkaalle on luotu laskuja',
}


jQuery.extend(jQuery.fn.pickadate.defaults, {
  monthsFull: ['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu', 'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'],
  monthsShort: ['tammi', 'helmi', 'maalis', 'huhti', 'touko', 'kesä', 'heinä', 'elo', 'syys', 'loka', 'marras', 'joulu'],
  weekdaysFull: ['sunnuntai', 'maanantai', 'tiistai', 'keskiviikko', 'torstai', 'perjantai', 'lauantai'],
  weekdaysShort: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
  today: 'tänään',
  clear: 'tyhjennä',
  firstDay: 1,
  format: 'dd.mm.yyyy',
  formatSubmit: 'yyyy/mm/dd',
})

jQuery.extend(jQuery.fn.pickatime.defaults, {
  clear: 'tyhjennä',
  interval: 15,
  format: 'H.i',
  onClose: function() {
    $('.timeInput').blur()
  },
})


/**
 * jQuery.browser.mobile will be true if the browser is a mobile device
 *
 **/
;(function(a) {
  ;(jQuery.browser = jQuery.browser || {}).mobile =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      a,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4),
    )
})(navigator.userAgent || navigator.vendor || window.opera)

;(function(e) {
  var t = (function() {
      return !1 === e.support.boxModel && e.support.objectAll && e.support.leadingWhitespace
    })()
  ;(e.jGrowl = function(t, i) {
    0 == e('#jGrowl').size() &&
    e('<div id="jGrowl"></div>')
      .addClass(i && i.position ? i.position : e.jGrowl.defaults.position)
      .appendTo('body'),
      e('#jGrowl').jGrowl(t, i)
  }),
    (e.fn.jGrowl = function(t, i) {
      if (e.isFunction(this.each)) {
        var o = arguments
        return this.each(function() {
          void 0 == e(this).data('jGrowl.instance') &&
          (e(this).data(
            'jGrowl.instance',
            e.extend(new e.fn.jGrowl(), {
              notifications: [],
              element: null,
              interval: null,
            }),
          ),
            e(this).data('jGrowl.instance').startup(this)),
            e.isFunction(e(this).data('jGrowl.instance')[t])
              ? e(this).data('jGrowl.instance')[t].apply(e(this).data('jGrowl.instance'), e.makeArray(o).slice(1))
              : e(this).data('jGrowl.instance').create(t, i)
        })
      }
    }),
    e.extend(e.fn.jGrowl.prototype, {
      defaults: {
        pool: 0,
        header: '',
        group: '',
        sticky: !1,
        position: 'top-right',
        glue: 'after',
        theme: 'default',
        themeState: 'highlight',
        corners: '10px',
        check: 250,
        life: 3e3,
        closeDuration: 'normal',
        openDuration: 'normal',
        easing: 'swing',
        closer: !0,
        closeTemplate: '&times;',
        closerTemplate: '<div>[ close all ]</div>',
        log: function() {
        },
        beforeOpen: function() {
        },
        afterOpen: function() {
        },
        open: function() {
        },
        beforeClose: function() {
        },
        close: function() {
        },
        animateOpen: { opacity: 'show' },
        animateClose: { opacity: 'hide' },
      },
      notifications: [],
      element: null,
      interval: null,
      create: function(t, i) {
        var i = e.extend({}, this.defaults, i)
        i.speed !== void 0 && ((i.openDuration = i.speed), (i.closeDuration = i.speed)),
          this.notifications.push({
            message: t,
            options: i,
          }),
          i.log.apply(this.element, [this.element, t, i])
      },
      render: function(t) {
        var i = this,
          o = t.message,
          n = t.options
        n.themeState = '' == n.themeState ? '' : 'ui-state-' + n.themeState
        var t = e('<div/>')
          .addClass('jGrowl-notification ' + n.themeState + ' ui-corner-all' + (void 0 != n.group && '' != n.group ? ' ' + n.group : ''))
          .append(e('<div/>').addClass('jGrowl-close').html(n.closeTemplate))
          .append(e('<div/>').addClass('jGrowl-header').html(n.header))
          .append(e('<div/>').addClass('jGrowl-message').html(o))
          .data('jGrowl', n)
          .addClass(n.theme)
          .children('div.jGrowl-close')
          .bind('click.jGrowl', function() {
            e(this).parent().trigger('jGrowl.beforeClose')
          })
          .parent()
        e(t)
          .bind('mouseover.jGrowl', function() {
            e('div.jGrowl-notification', i.element).data('jGrowl.pause', !0)
          })
          .bind('mouseout.jGrowl', function() {
            e('div.jGrowl-notification', i.element).data('jGrowl.pause', !1)
          })
          .bind('jGrowl.beforeOpen', function() {
            n.beforeOpen.apply(t, [t, o, n, i.element]) !== !1 && e(this).trigger('jGrowl.open')
          })
          .bind('jGrowl.open', function() {
            n.open.apply(t, [t, o, n, i.element]) !== !1 &&
            ('after' == n.glue ? e('div.jGrowl-notification:last', i.element).after(t) : e('div.jGrowl-notification:first', i.element).before(t),
              e(this).animate(n.animateOpen, n.openDuration, n.easing, function() {
                e.support.opacity === !1 && this.style.removeAttribute('filter'),
                null !== e(this).data('jGrowl') && (e(this).data('jGrowl').created = new Date()),
                  e(this).trigger('jGrowl.afterOpen')
              }))
          })
          .bind('jGrowl.afterOpen', function() {
            n.afterOpen.apply(t, [t, o, n, i.element])
          })
          .bind('jGrowl.beforeClose', function() {
            n.beforeClose.apply(t, [t, o, n, i.element]) !== !1 && e(this).trigger('jGrowl.close')
          })
          .bind('jGrowl.close', function() {
            e(this).data('jGrowl.pause', !0),
              e(this).animate(n.animateClose, n.closeDuration, n.easing, function() {
                e.isFunction(n.close) ? n.close.apply(t, [t, o, n, i.element]) !== !1 && e(this).remove() : e(this).remove()
              })
          })
          .trigger('jGrowl.beforeOpen'),
        '' != n.corners && void 0 != e.fn.corner && e(t).corner(n.corners),
        e('div.jGrowl-notification:parent', i.element).size() > 1 &&
        0 == e('div.jGrowl-closer', i.element).size() &&
        this.defaults.closer !== !1 &&
        e(this.defaults.closerTemplate)
          .addClass('jGrowl-closer ' + this.defaults.themeState + ' ui-corner-all')
          .addClass(this.defaults.theme)
          .appendTo(i.element)
          .animate(this.defaults.animateOpen, this.defaults.speed, this.defaults.easing)
          .bind('click.jGrowl', function() {
            e(this).siblings().trigger('jGrowl.beforeClose'),
            e.isFunction(i.defaults.closer) && i.defaults.closer.apply(e(this).parent()[0], [e(this).parent()[0]])
          })
      },
      update: function() {
        e(this.element)
          .find('div.jGrowl-notification:parent')
          .each(function() {
            void 0 != e(this).data('jGrowl') &&
            void 0 !== e(this).data('jGrowl').created &&
            e(this).data('jGrowl').created.getTime() + parseInt(e(this).data('jGrowl').life) < new Date().getTime() &&
            e(this).data('jGrowl').sticky !== !0 &&
            (void 0 == e(this).data('jGrowl.pause') || e(this).data('jGrowl.pause') !== !0) &&
            e(this).trigger('jGrowl.beforeClose')
          }),
        this.notifications.length > 0 &&
        (0 == this.defaults.pool || e(this.element).find('div.jGrowl-notification:parent').size() < this.defaults.pool) &&
        this.render(this.notifications.shift()),
        2 > e(this.element).find('div.jGrowl-notification:parent').size() &&
        e(this.element)
          .find('div.jGrowl-closer')
          .animate(this.defaults.animateClose, this.defaults.speed, this.defaults.easing, function() {
            e(this).remove()
          })
      },
      startup: function(i) {
        ;(this.element = e(i).addClass('jGrowl').append('<div class="jGrowl-notification"></div>')),
          (this.interval = setInterval(function() {
            e(i).data('jGrowl.instance').update()
          }, parseInt(this.defaults.check))),
        t && e(this.element).addClass('ie6')
      },
      shutdown: function() {
        e(this.element).removeClass('jGrowl').find('div.jGrowl-notification').trigger('jGrowl.close').parent().empty(), clearInterval(this.interval)
      },
      close: function() {
        e(this.element)
          .find('div.jGrowl-notification')
          .each(function() {
            e(this).trigger('jGrowl.beforeClose')
          })
      },
    }),
    (e.jGrowl.defaults = e.fn.jGrowl.prototype.defaults)
})(jQuery)

export default {
  permittedRoutes,
  ...window.helpers,
}

