import React from 'react'
import gocardless from './gocardless.html'
import Appregistry from 'libs/appregistry/appregistry'
import { createRoot } from 'react-dom'
import GoCardless from './GoCardless'

export default Backbone.Marionette.View.extend({
  initialize: function () {},
  template: function (serialized) {
    return _.template(gocardless)({
      items: serialized,
    })
  },
  onRender: function () {
    const token = Appregistry.models.person.get('authtoken')
    const root = createRoot(document.getElementById('react'))
    root.render(<GoCardless token={token} />)
  },
  onDestroy: function () {
    var doc = document.getElementById('react')
    if (doc) {
      doc.removeChild(doc.childNodes[0])
    }
  },
})
