import confirmed from 'views/register/eula.html'

export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(
      confirmed,
      {
        items: serialized,
      },
      { items: serialized },
    )
  },
  className: 'row row-fluid',
})

