import React, { useState, useEffect } from 'react'
import axios from 'axios'

const Callback = ({ token }) => {
  const [settings, setSettings] = useState(null)
  const [loading, setLoading] = useState(true)
  const [countdown, setCountdown] = useState(5)

  // Extract query parameters from window.location.hash
  const extractQueryParamsFromHash = () => {
    const hash = window.location.hash // e.g., "#/admin/gocardless/callback?ref=123"
    const queryStart = hash.indexOf('?')
    return queryStart !== -1 ? hash.substring(queryStart) : ''
  }

  const queryParams = new URLSearchParams(extractQueryParamsFromHash())
  const ref = queryParams.get('ref')

  console.log('Extracted ref from hash:', ref)

  // Fetch settings on mount
  useEffect(() => {
    if (!ref) {
      console.error("No 'ref' parameter found in the URL hash!")
      setLoading(false)
      return
    }
    axios
      .post(`api/admin/psd2/finished?ref=${ref}`, {}, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        console.log('Settings reply', data)
        setSettings(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching settings:', error)
        setLoading(false)
      })
  }, [token, ref])

  // Countdown effect when loading is finished
  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        setCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(interval)
            window.location.href = '#/admin/gocardless'
            return 0
          }
          return prevCount - 1
        })
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [loading])

  return (
    <div className="p-4">
      {loading ? (
        // Loading spinner and notification
        <div className="flex flex-col items-center justify-center h-64">
          {/* Tailwind spinner using animate-spin */}
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mb-4"></div>
          <h2 className="text-xl font-bold mb-2">Yhdistetään pankkitiliä järjestelmään...</h2>
        </div>
      ) : (
        // When finished, show success message with happy picture and countdown
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-bold mb-4">Pankkiin yhdistäminen onnistui!</h2>
          {/* Use a better happy image (replace the URL with your asset if desired) */}
          <img
            src="https://i.imgur.com/4NZ6uLY.png" // Example happy image URL
            alt="Success"
            className="mb-4 w-32 h-32 object-contain"
          />
          <p className="text-lg">
            Ohjataan takaisin {countdown} sekunnissa{countdown !== 1}...
          </p>
        </div>
      )}
    </div>
  )
}

export default Callback
