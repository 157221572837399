import Appregistry from 'libs/appregistry/appregistry'
import adminPeopleCollection from 'collections/admin/adminPeopleCollection'
import peopleCompositeView from 'views/admin/marionette/people/peopleCompositeView'
import editPersonView from 'views/admin/marionette/people/editPersonView'
import newPersonView from 'views/admin/marionette/people/newPersonView'
import peopleLayoutHtml from 'templates/admin/marionette/people/peopleLayout.html'
import deletePersonModal from 'templates/admin/marionette/people/deletePersonModal.html'

var peopleLayout = Backbone.Marionette.View.extend({
  childViewContainer: '#people-container',
  initialize: function() {
    this.collection = new adminPeopleCollection()
    this.collection.fetch()
    this.peopleCompositeView = new peopleCompositeView({ collection: this.collection })
  },
  regions: {
    editPersonRegion: '.edit-person',
    peopleRegion: '.people-container',
  },
  template: function(serialized) {
    return _.template(peopleLayoutHtml)({
      items: serialized.items,
    })
  },
  events: {
    'click .createNewPerson': 'createPerson',
    'click .editPerson': 'editPerson',
    'click .deletePerson': 'deletePerson',
    'click .destroyPerson': 'destroyPerson',
  },
  onRender: function() {
    this.getRegion('peopleRegion').show(this.peopleCompositeView)
    this.$el.append(deletePersonModal)
  },
  createPerson: function() {
    var createPerson = new newPersonView({
      publicSettings: Appregistry.publicSettings,
      collection: this.collection,
    })
    this.getRegion('editPersonRegion').show(createPerson)
    $(document).scrollTop(0)
    $('#page').scrollLeft(0)
  },
  editPerson: function(event) {
    var personDTO = this.collection.get(event.currentTarget.parentNode.dataset.id)
    var editView = new editPersonView({ model: personDTO.get('person') })
    editView.personDTO = personDTO
    this.getRegion('editPersonRegion').show(editView)
    $(document).scrollTop(0)
    $('#page').scrollLeft(0)
  },
  deletePerson: function(event) {
    var id = $(event.currentTarget.parentNode).attr('data-id')
    this.personDTO = this.collection.get(id)
    this.$el.find('.myModal').modal('show')
  },
  destroyPerson: function() {
    var self = this
    this.$el.find('.myModal').on('hidden.bs.modal', function() {
      self.personDTO.destroy({ wait: true })
        .error(function() {
          $.jGrowl('Heinkilöä ei voitu poistaa', {
            header: 'Virhe',
            theme: 'failed-notification',
            sticky: true,
            life: 30000,
          })
        })
        .success(function() {
          $.jGrowl('Henkilö poistettu', { life: 3000 })
        })
      self.personDTO = null
    })
    this.$el.find('.myModal').modal('hide')
  },

})
export default peopleLayout

