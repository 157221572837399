import Appregistry from 'libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import tesEditView from 'templates/admin/tes/edit-tes.html'
import remunerationLayout from 'views/admin/remuneration/remunerationLayout'

export default Backbone.Marionette.View.extend({
  className: 'section section-content',
  template: function(serialized) {
    return _.template(tesEditView)({
      items: serialized,
    })
  },
  initialize: function(arg) {
    this.modelBinder = new modelBinder()
    if (arg.model) {
      this.model = arg.model
      this.collection = this.model.get('remunerations')
    }
    if (arg.collection) {
      this.collection = arg.collection
      this.model = new this.collection.model()
    }
  },
  collectionEvents: {
    'change reset add remove': 'collectionChanged',
  },
  regions: {
    remunerations: '.remunerations',
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
    this.getRegion('remunerations').show(new remunerationLayout({ collection: this.model.get('remunerations') }))
  },
  events: {
    'click .saveTes': 'saveTes',
  },
  collectionChanged: function() {
    Appregistry.authModel.tesCollection.fetch()
  },
  successAndClose: function(self) {
    $.jGrowl('Tallennettu', { life: 10000 })
    self.destroy()
  },
  saveTes: function() {
    var self = this
    if (this.model.id) {
      this.model.save().success(function() {
        self.successAndClose(self)
      })
    } else {
      this.collection.create(this.model, { wait: true })
      self.successAndClose(self)
    }
  },
})

