import tableTemplate from 'templates/admin/tes/tes-table.html'
import tesItemView from 'views/admin/tes/tesItemView'

export default Backbone.Marionette.CompositeView.extend({
  childView: tesItemView,
  template: tableTemplate,
  tagName: 'table',
  className: 'table table-hover',
  childViewContainer: '.tesList',
})

