import Backbone from 'backbone'
import 'backbone.paginator'

var ropoModel = Backbone.Model.extend({})
var collection = Backbone.PageableCollection.extend({
  model: ropoModel,
  state: {
    pageSize: 10,
    // You can use 0-based or 1-based indices, the default is 1-based.
    // You can set to 0-based by setting ``firstPage`` to 0.
    firstPage: 0,
  },
  queryParams: {
    currentPage: 'page',
    pageSize: 'limit',
  },
  url: 'api/admin/ropo',
})

export default collection

