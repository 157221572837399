// Filename: app.js
import Router from './router'
import Backbone from 'backbone'
import Marionette from 'backbone.marionette'
import Appregistry from './libs/appregistry/appregistry'
import FastClick from 'fastclick'
import helpers from './components/helpers'
import 'pickadate/lib/picker.date'
import 'pickadate/lib/picker.time'
import 'jquery'
import 'bootstrap3/dist/js/bootstrap'
import 'fonts/fonts'
import 'tablesorter'
import 'css/styles.css'
import 'less/build.less'
import 'select2/dist/js/select2.full'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
import 'underscore'

if (!Appregistry.models.person.get('authtoken') && localStorage.getItem('authtoken')) {
  Appregistry.models.person.set('authtoken', localStorage.getItem('authtoken'), { silent: true })
  Appregistry.models.person.setAuth()
  Appregistry.isMobile = jQuery.browser.mobile
}
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function() {
    FastClick.attach(document.body)
  }, false)
}
//Backbone.Relational.store.removeModelScope()
$('.ajax-loader').affix({ offset: { top: 50 } })
// Pass in our Router module and call it's initialize function
Router.initialize()


