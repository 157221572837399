import Appregistry from 'libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import converter from 'libs/converter'
import receiptTemplate from './receiptTemplate.html'

export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    var authtoken = Appregistry.models.person.get('authtoken')
    return _.template(receiptTemplate)({
      model: serialized,
      authtoken: authtoken,
    })
  },
  events: {
    'click .remove-receipt': 'deleteModel',
  },
  tagName: 'tr',
  deleteModel: function() {
    this.model.destroy({ wait: true })
  },
  initialize: function() {
  },
  onRender: function() {
  },

})
