import Backbone from 'backbone'

var requiredField = function(person, field) {
  if (person && person.get(field)) {
    return false
  }
  return 'Pakollinen kenttä'
}

var personModel = Backbone.Model.extend({
  urlRoot: '/api/admin/people',
  initialize: function() {
  },
  validation: {
    firstName: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
    lastName: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
  },
})

var personDtoModel = Backbone.Model.extend({
  defaults: {
    opened: 'closed',
  },
  validation: {
    person: {
      required: true,
    },
    firstName: function() {
      return requiredField(this.get('person'), 'firstName')
    },
    lastName: function() {
      return requiredField(this.get('person'), 'lastName')
    },
    username: function() {
      return requiredField(this.get('person'), 'username')
    },
    password: function() {
      return requiredField(this.get('person'), 'password')
    },
  },
  initialize: function() {
  },
  relations: [
    {
      type: 'HasOne',
      key: 'person',
      relatedModel: personModel,
    },
  ],
})

var simplePeopleCollection = Backbone.Collection.extend({
  url: '/api/admin/peoplelist', // TODO: change this model to container having person and calculations
  model: personDtoModel,
  initialize: function() {
  },
})

export default simplePeopleCollection

