import Appregistry from 'libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import customerTemplate from 'views/admin/marionette/sharedcustomers/customer-edit.html'
import personSharedCustomerModel from 'models/personSharedCustomerModel'
import addCostUnitTemplate from './costUnits/add-cost-unit.html'
import deleteCostUnitTemplate from './costUnits/delete-cost-unit.html'
import modifyCostUnitTemplate from './costUnits/modify-cost-unit.html'

var peopleList = []
var costunit = Backbone.Model.extend({
  url: function() {
    return '/api/admin/sharedcustomer/' + this.sharedcustomerid + '/costunit'
  },
})

var sharedCustomerEditView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    var costUnits = serialized.costUnits || []
    return _.template(customerTemplate)({
      items: serialized,
      peopleList: peopleList,
      people: Appregistry.peopleCollection,
      costUnits: costUnits,
    })
  },
  initialize: function() {
    this.modelBinder = new modelBinder()
    var self = this
    this.model.fetch({
      success: function() {
        self.render()
      },
    })
  },
  className: 'section-content row row-fluid edit-customer-container',
  events: {
    'click .saveCustomer': 'saveCustomer',
    'click .saveCustomerAndClose': 'saveCustomerAndClose',
    'click .addPerson': 'addPersonToCustomer',
    'click .remove-person': 'removePerson',
    'click .addCostUnit': 'addCostUnit',
    'click .createCostUnit': 'createCostUnit',
    'click .remove-costunit': 'openDeleteCostUnitModal',
    'click .deleteCostUnit': 'deleteCostUnit',
    'click .modify-costunit': 'openModifyModal',
    'click .modifyCostUnit': 'modifyCostUnit',
  },
  modelEvents: {},
  addPersonToCustomer: function() {
    var personId = this.$el.find('[name=person_id]').val()
    var person = Appregistry.peopleCollection.get(personId)
    if (this.model.get('people').find(function(p) {
      return p.id === person.id
    })) {
      $.jGrowl('Henkilö on jo lisätty asiakkaalle', { life: 10000 })
    } else {
      var sha = new personSharedCustomerModel.sharedCustomerModel({
        personId: person.id,
        customerId: this.model.id,
      })
      var self = this
      sha.save()
        .success(function() {
          self.model.get('people').add(person)
          self.render()
        })
    }
  },
  removePerson: function(event) {
    var personId = event.currentTarget.id
    var personModel = this.model.get('people').get(personId)
    if (personModel) {
      var self = this
      var model = this.model.get('people').get(personModel)
      model.destroy({
        url: 'api/admin/sharedcustomer/' + this.model.id + '/person' + '/' + model.id,
      })
        .success(function(a, b) {
          var a = self.model.get('people').remove(personModel)
          $.jGrowl('Työntekijä poistettu', { life: 1000 })
          self.render()
        })
        .fail(function(a, b) {
          $.jGrowl('Työntekijää ei voitu poistaa', { life: 10000 })
        })
    }
  },
  saveCustomer: function() {
    var self = this
    if (this.model.isValid(true)) {
      var a = this.model.save({ wait: true })
        .success(function(response) {
          $.jGrowl('Tallennettu', { life: 1000 })
        })
        .error(function(response) {
          console.log('error', response)
        })
    }
  },
  saveCustomerAndClose: function() {
    var self = this
    if (this.model.isValid(true)) {
      this.model.save()
        .success(function(response) {
          $.jGrowl('Tallennettu', { life: 1000 })
          self.destroy()
        })
        .error(function(response) {
        })
    }
  },
  modelChanged: function() {
  },
  onDestroy: function() {
    this.modelBinder.unbind()
    Backbone.Validation.unbind(this)
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
    peopleList = this.model.get('people')
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    Backbone.Validation.bind(this)
    this.$el.append(addCostUnitTemplate)
    this.$el.append(deleteCostUnitTemplate)
    this.$el.append(modifyCostUnitTemplate)

    return this

  },
  addCostUnit: function() {
    this.$el.find('.addCostUnitModal').modal('show')
  },
  createCostUnit: function() {
    var value = this.$el.find('[name=costUnitName]').val()
    if (!value) {
      $.jGrowl('Nimi täytyy antaa', { life: 10000 })
    } else {
      var self = this
      var model = new costunit({
        name: value,
      })
      model.sharedcustomerid = this.model.id

      model.save()
        .success(function(data) {
          self.model.get('costUnits').add(data)

          var elem = self.$el.find('.addCostUnitModal')
          elem.modal('hide')
          elem.on('hidden.bs.modal', function() {
            elem.off()
            self.render()
          })
        })
        .error(function(e) {
          $.jGrowl('Ei voitu tallentaa: ' + e, { life: 10000 })
        })
    }
  },
  openDeleteCostUnitModal: function(event) {
    this.toBeDeleted = $(event.currentTarget).data('id')
    this.$el.find('.deleteCostUnitModal').modal('show')

  },
  deleteCostUnit: function() {
    var self = this
    var elem = self.$el.find('.deleteCostUnitModal')
    var model = this.model.get('costUnits').get(this.toBeDeleted)
    model.destroy({ url: '/api/admin/sharedcustomer/' + this.model.id + '/costunit/' + this.toBeDeleted })
      .success(function(data) {
        elem.modal('hide')
        elem.on('hidden.bs.modal', function() {
          elem.off()
          self.render()
        })
      })
      .error(function(e) {
        $.jGrowl('Ei voitu tallentaa' + e, { life: 10000 })
      })
  },
  openModifyModal: function(event) {
    this.toBeModified = this.model.get('costUnits').get($(event.currentTarget).data('id'))
    this.$el.find('.modifyCostUnitTemplate').modal('show')
    this.$el.find('[name=modifyCostUnitName]').val(this.toBeModified.get('name'))
  },
  modifyCostUnit: function() {
    var self = this
    this.toBeModified.set({ name: this.$el.find('[name=modifyCostUnitName]').val() })
    var elem = this.$el.find('.modifyCostUnitTemplate')
    this.toBeModified.save({}, { url: '/api/admin/sharedcustomer/' + this.model.id + '/costunit/' + this.toBeModified.id })
      .success(function() {
        elem.modal('hide')
        elem.on('hidden.bs.modal', function() {
          elem.off()
          self.render()
        })
      })
      .error(function(e) {
        $.jGrowl('Ei voitu tallentaa' + e, { life: 10000 })
      })
  },

})

export default sharedCustomerEditView

