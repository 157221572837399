import contractorCollection from './contractorCollection'
import contractorTemplate from './contractorTemplate.html'
import Appregistry from 'libs/appregistry/appregistry'

var model = Backbone.Model.extend({
  initialize: function() {
    this.attributes.items = new contractorCollection()
  },
})
export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    var paid = !!serialized.paid
    var list = serialized.items.toJSON()
    var toBeShown = list.filter(function(item) {
      return !!item.paid === paid
    })
    var authtoken = Appregistry.models.person.get('authtoken')
    const compiledTemplate = _.template(contractorTemplate)
    return compiledTemplate({
      items: toBeShown || [],
      paid: serialized.paid,
      authtoken: authtoken,
    })
  },
  events: {
    'click .delete': 'delete',
    'click .onPaid': 'onPaid',
    'click .ready-list': 'showReadyList',
    'click .done-list': 'showDoneList',
  },
  delete: function(e) {
    let confirmAction = confirm('Poistetaanko varmasti?')
    if (confirmAction) {
      var deletemodel = this.model.get('items').get(parseInt(e.currentTarget.dataset.id))
      deletemodel.destroy()
      this.render()
    }
  },
  onPaid: function(e) {
    var self = this
    const targetId = parseInt(e.currentTarget.dataset.id)
    var paid_model = this.model.get('items').get(targetId)
    paid_model.save({ paid: e.currentTarget.checked }, { wait: true, silent: true }).then(function(success) {
      paid_model.clear({ silent: true })
      paid_model.set(success)
      self.render()
    })
  },
  initialize: function() {
    this.model = new model({ paid: false })
    var self = this

    this.model
      .get('items')
      .fetch()
      .then(function(success) {
        self.render()
      })
    this.listenTo(this.model, 'change', this.render)
  },
  showDoneList: function() {
    this.model.set('paid', true)
    this.render()
  },
  showReadyList: function() {
    this.model.set('paid', false)
    this.render()
  },
})

