import Backbone from 'backbone'

var relationalPersonModel = Backbone.Model.extend({
  initialize: function() {
  },
  relations: [
    {
      type: 'HasMany',
      key: 'sharedCustomers',
      relatedModel: Backbone.Model.extend({}),
    },
    {
      type: 'HasOne',
      key: 'personSettings',
      relatedModel: Backbone.Model.extend({}),
    },
  ],
})
export default relationalPersonModel


