import personDtoModel from 'models/admin/personDtoModel'
import personModel from 'models/admin/personModel'

var peopleCollection = Backbone.Collection.extend({
  url: '/api/admin/people',
  model: personDtoModel,
  initialize: function() {
  },
  parsePersonSettings: function(model,
                                publicSettings) {
    model.get('person').get('personSettings').set({
      taxpercent: publicSettings.get('taxpercent'),
      kmreimbursement: publicSettings.get('kmreimbursement'),
      minimumhourlywage: publicSettings.get('minimumhourlywage'),
      osk: publicSettings.get('osk'),
      unemploymentInsurance: publicSettings.get('unemploymentInsurance'),
      holidaybonus: publicSettings.get('holidaybonus'),
      tyEl: publicSettings.get('tyEL'),
      accidentInsurance: publicSettings.get('accidentInsurance'),
    })
  },
  newModel: function(publicSettings) {
    var model = new personDtoModel({}, { parse: true })
    if (publicSettings) {
      this.parsePersonSettings(model, publicSettings)
    }
    return model
  },

})

export default peopleCollection

