import Appregistry from 'libs/appregistry/appregistry'
import itemTemplate from 'templates/admin/tes/tes-item.html'

export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(itemTemplate)({
      items: serialized,
    })
  },
  tagName: 'tr',
  modelEvents: {
    change: 'render',
  },
  onRender: function() {
    return this
  },
  events: {
    'click .editTes': 'editTes',
    'click .deleteTes': 'deleteTes',
  },
  editTes: function() {
    Appregistry.global_event_obj.trigger('editTesItem', this.model.id)
  },
  deleteTes: function() {
    this.model.destroy()
  },
})

