import salaryurlCollection from 'js/collections/salaryurlCollection'
import salaryTemplate from 'templates/salary/salary.html'
import appregistry from 'js/libs/appregistry/appregistry'

var salaryView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(salaryTemplate)({
      salaries: serialized,
      authtoken: appregistry.models.person.get('authtoken'),
    })
  },
  initialize: function() {
    var self = this
    this.collection = new salaryurlCollection()
    this.collection.fetch()
      .success(function() {
        self.render()
      })
  },
})
export default salaryView

