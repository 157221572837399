import Appregistry from 'libs/appregistry/appregistry'
import allowanceItemView from 'views/admin/salaries/allowances/allowanceItemView'
import allowanceContainerHtml from 'templates/admin/salaries/allowances/allowance-container.html'

export default Backbone.Marionette.CompositeView.extend({
  childView: allowanceItemView,
  className: 'section',
  template: function(serialized) {
    return _.template(
      allowanceContainerHtml,
      {
        items: serialized,
        tes: Appregistry.authModel.tesCollection,
      },
      { items: serialized },
    )
  },
  childViewContainer: function() {
    return '.allowances'
  },
  events: {
    'click .addAllowance': 'addAllowance',
    'click .saveAllowances': 'saveAllowances',
  },
  modelEvents: {
    change: 'modelChanged',
  },
  collectionEvents: {
    change: 'modelChanged',
    remove: 'modelRemoved',
  },
  addAllowance: function() {
    this.collection.add({})
    this.saveAllowances()
  },
  modelChanged: function(model, event, joku) {
    if (event.changeSource == 'ModelBinder') {
      this.saveAllowances()
    }
  },
  modelRemoved: function(a, b) {
    if (this.collectionSize != this.collection.size()) {
      this.saveAllowances()
      this.collectionSize = this.collection.size()
    }
  },
  saveAllowances: function(a, b) {
    this.collection.parent.trigger('allowaces:changed', this.collection)
  },
  onRender: function() {
    this.$el.find('.date-picker').pickadate(window.helpers.pickadateOptions)
  },
})

