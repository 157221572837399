import Marionette from 'backbone.marionette'
import Appregistry from 'libs/appregistry/appregistry'
import mainHomeTemplate from 'templates/home/main.html'
import notPaid from 'templates/home/notPaid.html'
import notPaidItem from 'templates/home/notPaidItem.html'
import loggedMain from 'templates/home/logged_main.html'
import infoDaoModel from 'models/infoDaoModel'
import modelBinder from 'backbone.modelbinder'
import converter from 'libs/converter'

var bindings = {
  openAmount: { selector: '[name=openAmount]', converter: converter.euroConverter },
  approvedAmount: { selector: '[name=approvedAmount]', converter: converter.euroConverter },
  paidAmount: { selector: '[name=paidAmount]', converter: converter.euroConverter },
  paidSalaries: { selector: '[name=paidSalaries]', converter: converter.euroConverter },
  provision: { selector: '[name=provision]', converter: converter.percentConverter },
  paidSalariesThisYear: { selector: '[name=paidSalariesThisYear]', converter: converter.euroConverter },
  taxAmountThisYear: { selector: '[name=taxAmountThisYear]', converter: converter.euroConverter },
}

var drawBills = function(response) {
  var chart = $('#myChart')
  var parent = chart.parent()
  chart.remove()
  parent.append(' <canvas id="myChart" width="480" height="300"></canvas>')
  chart = $('#myChart')
  var ctx = chart.get(0).getContext('2d')
  var labels = _.map(response.datePrices, 'date')
  var prices = _.map(response.datePrices, 'price')
  var chartData = {
    labels: labels,
    datasets: [
      {
        fillColor: 'rgba(220,220,220,0.5)',
        strokeColor: 'rgba(220,220,220,1)',
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: '#fff',
        data: prices,
      },
    ],
  }
  return new Chart(ctx).Line(chartData)
}
var drawSalaries = function(response) {
  var chart = $('#salaryChart')
  var parent = chart.parent()
  chart.remove()
  parent.append(' <canvas id="salaryChart" width="480" height="300"></canvas>')
  chart = $('#salaryChart')
  var ctx = chart.get(0).getContext('2d')

  var labels = _.map(response.salaryPrices, 'date')
  var prices = _.map(response.salaryPrices, 'price')
  var chartData = {
    labels: labels,
    datasets: [
      {
        fillColor: 'rgba(220,220,220,0.5)',
        strokeColor: 'rgba(220,220,220,1)',
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: '#fff',
        data: prices,
      },
    ],
  }
  return new Chart(ctx).Line(chartData)
}

var notPaidItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(notPaidItem)({
      items: serialized,
    })
  },
  tagName: 'tr',
  modelEvents: {
    change: 'render',
  },
  onRender: function() {
    return this
  },
})


var notPaidCompositeView = Backbone.Marionette.CompositeView.extend({
  childView: notPaidItemView,
  template: _.template(notPaid),
  templateContext: function() {
    var length = this.collection ? this.collection.length : 0
    return {
      items: this.collection,
      render: length,
    }
  },
  tagName: 'span',
  childViewContainer: '.billsList',
})

export default Backbone.Marionette.View.extend({
  getTemplate: function() {
    var isLogged = Appregistry.models.person.get('loggedIn') ? true : false
    if (isLogged) {
      return _.template(loggedMain)
    } else {
      return _.template(mainHomeTemplate)
    }
  },
  templateContext: {
    userName: function() {
      return Appregistry.models.person.fullName
    },
  },
  regions: {
    notPaidBills: '.notPaidBills',
  },
  initialize: function() {
    var isLogged = Appregistry.models.person.get('loggedIn') ? true : false
    if (!isLogged && Backbone.history.fragment != 'login') {
      Backbone.history.navigate('login')
    }
    this.modelBinder = new modelBinder()
    this.model = Appregistry.models.person
    this.infoDaoModel = new infoDaoModel()

    this.notPaidView = new notPaidCompositeView({ collection: this.infoDaoModel.get('notPaidBills') })
  },
  events: {
    'click #login_link': 'try_login',
    'click .getBillReport': 'getBillReport',
    'click .showBillGraph': 'showBillGraph',
    'click .showSalaryGraph': 'showSalaryGraph',
    'submit form': 'try_login',
  },
  drawGraph: function(response, that) {
    drawBills(response)
    drawSalaries(response)
  },
  showBillGraph: function() {
    var self = this
    $('#billGraphModal').on('show.bs.modal', function() {
      setTimeout(function() {
        drawBills(self.dataResponse)
      }, 300)
    })
    $('#billGraphModal').modal('toggle')
  },
  showSalaryGraph: function() {
    var self = this
    $('#salaryGraphModal').on('show.bs.modal', function() {
      setTimeout(function() {
        drawSalaries(self.dataResponse)
      }, 300)
    })
    $('#salaryGraphModal').modal('toggle')
  },
  onRender: function() {
    var isLogged = Appregistry.models.person.get('loggedIn') ? true : false
    if (isLogged) {
      var self = this
      this.getRegion('notPaidBills').show(this.notPaidView)
      this.modelBinder.bind(this.infoDaoModel, this.el, bindings)
      this.infoDaoModel.fetch().success(function(response) {
        self.dataResponse = response
        self.notPaidView.render()
      })
    }
    //            this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  getBillReport: function(event) {
    var months = $(event.currentTarget).data().bind
    this.infoDaoModel
      .fetch({
        data: $.param({
          months: months,
        }),
      })
      .success(this.drawGraph)
  },
  try_login: function(e) {
    e.preventDefault()
    Appregistry.models.person.set({ password: $('#login-password').val(), username: $('#login-username').val() })
    var promise = Appregistry.models.person.trylogin()
    promise.success(function() {
      Backbone.history.navigate('home', { trigger: true })
      Appregistry.global_event_obj.trigger('loggedIn')
    })
    promise.error(function() {
      Appregistry.global_event_obj.trigger('loggedIn')
    })
  },
  tryLogin: function(e) {
    if (e.keyCode == 13) {
      this.try_login()
    }
  },
})

