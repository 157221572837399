import ropoCollection from './ropoCollection'
import 'jsgrid'

var updatesView = Backbone.Marionette.View.extend({
  template: '<div><div id="ninjou-jsgrid"></div></div>',
  initialize: function() {
    this.collection = new ropoCollection()
  },
  onRender: function() {
    var self = this
    var ell = this.$el.find('#ninjou-jsgrid')
    ell.jsGrid({
      height: '370px',
      width: '100%',

      autoload: true,
      paging: true,
      pageLoading: true,
      pageSize: 15,
      pageIndex: 1,

      controller: {
        loadData: function(filter) {
          var startIndex = (filter.pageIndex - 1) * filter.pageSize
          var promise = new Promise(function(resolve, reject) {
            // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
            // In this example, we use setTimeout(...) to simulate async code.
            // In reality, you will probably be using something like XHR or an HTML5 API.
            self.collection.getPage(filter.pageIndex - 1)
              .done(function(data) {
                var values = _.map(data.data, function(value) {
                  var error = _.reduce(value.errors, function(result, value) {
                    return result + ' ' + value.error
                  }, '')
                  return {
                    id: value.id,
                    accepted: value.accepted ? 'Kyllä' : 'Ei',
                    errors: error,
                    receiver: value.result[0].receiver,
                    jobid: value.result[0].jobid,
                    reference: value.result[0].reference,
                    sendtype: value.result[0].sendtype,
                    amount: value.result[0].amount,
                    created: value.created,
                    billid: value.billid,
                  }
                })

                resolve({
                  itemsCount: data.itemsCount,
                  data: values,
                })

              })
          })

          return promise
        },
      },

      fields: [{
        name: 'billid',
        type: 'text',
        valueField: 'id',
        width: 40,
      },
        {
          name: 'accepted',
          title: 'Ok',
          type: 'text',
          width: 50,
        },
        {
          name: 'errors',
          title: 'Virheet',
          type: 'text',
          width: 300,
        },
        {
          name: 'receiver',
          title: 'Kohde',
          type: 'text',
          width: 200,
        },
        {
          name: 'jobid',
          type: 'text',
          width: 80,
        },
        {
          name: 'reference',
          type: 'text',
          width: 130,
        },
        {
          name: 'sendtype',
          type: 'text',
          width: 80,
        },
        {
          name: 'amount',
          type: 'text',
          width: 80,
        },
        {
          name: 'created',
          type: 'text',
          width: 100,
        },

      ],
    })
  },
})
export default updatesView

