import salaryUrl from 'models/salaryUrl'
import Appregistry from 'libs/appregistry/appregistry'

var salaryurlCollection = Backbone.Collection.extend({
  url: function() {
    return 'api/person/' + Appregistry.models.person.get('id') + '/salaries'
  },
  model: salaryUrl,
  initialize: function() {

  },
})

export default salaryurlCollection

