import allowanceItem from 'templates/admin/salaries/allowances/allowance-item.html'
import converter from 'libs/converter'


var allowanceItemView = Backbone.Marionette.View.extend({
  template: allowanceItem,
  className: 'row',
  initialize: function() {
    this.modelBinder = new Backbone.ModelBinder()
  },
  events: {
    'click .removeAllowance': 'removeAllowance',
  },
  removeAllowance: function() {
    this.model.trigger('destroy')
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  onShow: function() {
    this.modelBinder.bind(this.model, this.el, modelBinds)
  },
})

var modelBinds = {
  allowanceName: { selector: '[name=allowanceName]' },
  allowanceAmount: { selector: '[name=allowanceAmount]', converter: converter.integerConverter },
  allowancePrice: { selector: '[name=allowancePrice]', converter: converter.euroConverter },
  allowanceTotal: { selector: '[name=allowanceTotal]', converter: converter.euroConverter },
  allowancePercent: { selector: '[name=allowancePercent]', converter: converter.percentConverter },
}
export default allowanceItemView

