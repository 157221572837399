import Appregistry from 'libs/appregistry/appregistry'
import worklistCollection from './worklistCollection'
import listTemplate from './workListPageable.html'
import receiptsTemplate from './receipts.html'
import 'jsgrid'
import { toInteger } from 'lodash/lang'

var workItemModel = Backbone.Model.extend({
  urlRoot: 'api/admin/worklist',
})

var workItemsDoneModel = Backbone.Model.extend({
  urlRoot: 'api/admin/worklist/done',
})

var workItemsReadyModel = Backbone.Model.extend({
  urlRoot: 'api/admin/worklist/ready',
})


var stateModel = Backbone.Model.extend({})

function getCheckbox(item, self, collection) {
  var $customEditButton = $(`<input class='ninjou-checkbox' data-id='${item.id}' type=\'checkbox\'>`)
    .on(
      'click',
      function(e) {
        e.stopPropagation()
      }.bind(self),
    )

  return $('<div>').append($customEditButton)
}

function getHeaderCheckbox(item, self, collection) {
  var $customEditButton = $('<input class="ninjou-header-checkbox" type=\'checkbox\'>')
    .on(
      'click',
      function(e) {
        e.stopPropagation()
        $('.ninjou-checkbox').prop('checked', $('.ninjou-header-checkbox:checked').length === 1)
      }.bind(self),
    )

  return $('<div>').append($customEditButton)
}

const getApproveDeclineButtons = (item, self) => {
  const div = $('<div>')
  var $approveButton = $('<button>Valmis</button>')
    .text('Hyväksy')
    .attr({
      class: 'customGridEditbutton jsgrid-button jsgrid-edit-button btn btn-success', style: 'width: 70px',
    })
    .on(
      'click',
      function(e) {
        e.stopPropagation()
        $(e.currentTarget).prop('disabled', true)
        var model = new workItemModel({
          ...item, done: !item.done, decision: 'APPROVED',
        })
        model.save(null, {
          wait: true,
        }).then(done => {
          $('#ninjou-workitem-jsgrid').jsGrid('loadData')
        })
      }.bind(self),
    )
  div.append($approveButton)
  var $declineButton = $('<button>Valmis</button>')
    .text('Hylkää')
    .attr({ class: 'customGridEditbutton jsgrid-button jsgrid-edit-button btn btn-info', style: 'width: 70px' })
    .on(
      'click',
      function(e) {
        e.stopPropagation()
        $(e.currentTarget).prop('disabled', true)
        var model = new workItemModel({
          ...item, done: !item.done, decision: 'DECLINED',
        })
        model.save(null, {
          wait: true,
        }).then(done => {
          $('#ninjou-workitem-jsgrid').jsGrid('loadData')
        })
      }.bind(self),
    )
  div.append($declineButton)
  console.log(div)
  return div
}

function getDoneButton(item, self) {
  var teksti = item.done ? 'Palauta' : 'Valmis'
  if (item.itemType === 'AdvancePayment') {
    if (item.done) {
      return $('<div>')
    }
    return getApproveDeclineButtons(item, self)
  }
  var $customEditButton = $('<button>Valmis</button>')
    .text(teksti)
    .attr({ class: 'customGridEditbutton jsgrid-button jsgrid-edit-button btn btn-success' })
    .on(
      'click',
      function(e) {
        e.stopPropagation()
        $(e.currentTarget).prop('disabled', true)
        var model = new workItemModel({ ...item, done: !item.done })
        model.save(null, {
          wait: true,
        }).then(done => {
          $('#ninjou-workitem-jsgrid').jsGrid('loadData')
        })
      }.bind(self),
    )

  return $('<div>').append($customEditButton)
}

function getDeleteButton(item, self) {
  return $('<button>Poista</button>')
    .attr({ class: 'customGridDeletebutton jsgrid-button jsgrid-delete-button btn btn-warning' })
    .on(
      'click',
      function(e) {
        e.stopPropagation()
        $(e.currentTarget).prop('disabled', true)
        var model = new workItemModel(item)
        var self = this
        $('#ninjou-workitem-jsgrid').jsGrid('deleteItem', item)
        model.destroy({
          contentType: false,
          processData: false,
          wait: true,
          success: function() {
            $('#ninjou-workitem-jsgrid').jsGrid('loadData')
            self.render()
          },
          error: function(error) {
            console.log('error', error)
          },
        })
      }.bind(self),
    )
}

function getReceipts(value, item) {
  var self = this
  return $('<a>')
    .text(value)
    .click(
      function(e) {
        var template = _.template(receiptsTemplate)({
          item: item,
          authtoken: Appregistry.models.person.get('authtoken'),
        })
        $('.receipts').html(template)
        $('.showReceipts').modal('show')
      }.bind(self),
    )
}

function getTaxCard(value, item) {
  return $('<a>')
    .text(value)
    .attr('href', item.href + '?authtoken=' + Appregistry.models.person.get('authtoken'))
}

export default Backbone.Marionette.View.extend({
  initialize: function() {
    this.collection = new worklistCollection()
    this.oldCollection = new worklistCollection()
    this.oldCollection.queryParams.newItems = false
    this.collection.fetch()
    this.stateModel = new stateModel({
      show: 'new',
    })
  },
  template: function(show, a) {
    const newClass = show == 'new' ? 'btn-success' : 'btn-default'
    const oldClass = show == 'old' ? 'btn-success' : 'btn-default'
    return _.template(listTemplate)({
      newClass: newClass,
      oldClass: oldClass,
    })
  },
  serializeData: function(data) {
    return this.stateModel.get('show')
  },
  events: {
    'click .showNew': 'showNew',
    'click .showOld': 'showOld',
    'click .move-to-done': 'moveToDone',
    'click .move-to-new': 'moveToNew',
    'click .destroy-all-humans': 'destroyAll',
  },
  moveToDone: function() {
    const element = this.$el.find('.ninjou-checkbox:checked')
    let ids = []
    for (let box of element) {
      ids.push(toInteger(box.dataset.id))
    }
    this.$el.find('#ninjou-workitem-jsgrid').jsGrid('moveToDone', ids)
  },
  moveToNew: function() {
    const element = this.$el.find('.ninjou-checkbox:checked')
    let ids = []
    for (let box of element) {
      ids.push(toInteger(box.dataset.id))
    }
    this.$el.find('#ninjou-workitem-jsgrid').jsGrid('moveToReady', ids)
  },
  destroyAll: function() {
    console.log('destroyAll', this.$el)
  },
  showNew: function() {
    this.stateModel.set('show', 'new')
    this.render()
  },
  showOld: function() {
    this.stateModel.set('show', 'old')
    this.render()
  },
  onRender: function(args) {
    var self = this
    var ell = this.$el.find('#ninjou-workitem-jsgrid')
    ell.jsGrid({
      width: '100%',
      selecting: true,
      autoload: true,
      paging: true,
      pageLoading: true,
      pageSize: 50,
      pageIndex: 1,
      moveToDone: function(dones) {
        const model = new workItemsDoneModel({
          ids: dones,
        })
        model.save(null, {
          wait: true,
        }).then(() => {
          $('#ninjou-workitem-jsgrid').jsGrid('loadData')
        })

      },
      moveToReady: function(data) {
        const model = new workItemsReadyModel({
          ids: data,
        })
        model.save(null, {
          wait: true,
        }).then(() => {
          $('#ninjou-workitem-jsgrid').jsGrid('loadData')
        })
      },
      css: 'table-stripped',
      pagerFormat: 'Sivut: {first} {prev} {pages} {next} {last}    {pageIndex} / {pageCount}',
      controller: {
        loadData: function(filter) {
          return new Promise(function(resolve, reject) {
            // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
            // In this example, we use setTimeout(...) to simulate async code.
            // In reality, you will probably be using something like XHR or an HTML5 API.
            var collection = self.stateModel.get('show') == 'new' ? self.collection : self.oldCollection
            collection.getPage(filter.pageIndex - 1).done(function(data) {
              resolve({
                itemsCount: data.itemsCount,
                data: data.data,
              })
            })
          })
        },
      },

      fields: [
        {
          name: 'done',
          headerTemplate: function(value, item) {
            return getHeaderCheckbox(item, self)
          },
          width: 10,
          itemTemplate: function(value, item, collection) {
            return getCheckbox(item, self, collection)
          },
        },
        {
          name: 'customer',
          title: 'Asiakas',
          type: 'text',
          width: 100,
        },
        {
          name: 'description',
          title: 'Kuvaus',
          type: 'text',
          width: 400,
          itemTemplate: function(value, item) {
            if (item.receipts) {
              return getReceipts(value, item)
            } else if (item.href) {
              return getTaxCard(value, item)
            } else {
              return value
            }
          },
        },
        {
          name: 'entityId',
          title: 'Kohde id',
          type: 'text',
          width: 60,
          itemTemplate: function(value, item) {
            // <a href="api/admin/bills/redirect/<%= items.id %>?authtoken=<%= person.get('authtoken') %>">Näytä</a>
            const authtoken = Appregistry.models.person.get('authtoken')
            if (item?.itemType === 'AdvancePayment') {
              var a = document.createElement('a')
              var linkText = document.createTextNode('lasku ' + item.entityId)
              a.appendChild(linkText)
              a.title = linkText
              a.href = `/api/admin/bills/redirect/${item.entityId}?authtoken=${authtoken}`
              return a
            }
            return value
          },
        },
        {
          title: 'Summa',
          name: 'amount',
          type: 'text',
          width: 40,
        },
        {
          title: 'Päivitetty',
          name: 'updated',
          type: 'text',
          width: 60,
        },
        {
          title: 'Eräpäivä',
          name: 'duedate',
          type: 'text',
          width: 120,
        },
        {
          type: 'control',
          width: 60,
          editButton: false,
          self: self,
          deleteButton: false,
          itemTemplate: function(value, item) {
            return getDoneButton(item, self)
          },
        },
        {
          type: 'control',
          width: 60,
          editButton: false,
          deleteButton: false,
          itemTemplate: function(value, item) {
            var $customDeleteButton = getDeleteButton(item, self)
            return $('<div>').append($customDeleteButton)
          },
        },
      ],
    })
  },
})

