import Appregistry from 'js/libs/appregistry/appregistry'
import modelBinder from 'backbone.modelbinder'
import customerResult from 'js/views/user/customers/customerResult'
import searchCustomerModel from 'js/models/search/searchCustomerModel'
import sharedCustomerCollection from 'js/collections/user/sharedCustomerCollection'
import searchCustomer from 'templates/user/customers/searchCustomer.html'
import personSharedCustomerModel from 'js/models/personSharedCustomerModel'

export default Backbone.Marionette.CompositeView.extend({
  template: searchCustomer,
  childView: customerResult,
  appregistry: Appregistry,
  className: 'col-md-12',
  childViewContainer: '.search-customers-container',
  collection: new sharedCustomerCollection(),
  initialize: function() {
    this.model = new searchCustomerModel()
    this.modelBinder = new modelBinder()
  },
  modelEvents: {
    change: 'modelChanged',
  },
  events: {
    'click .searchCustomers': 'searchCustomers',
    'click .addCustomer': 'addCustomer',
  },
  collectionEvents: {
    reset: 'collectionChanged',
    add: 'collectionChanged',
  },
  addCustomer: function(event) {
    var customerId = $(event.currentTarget).attr('data-bind')
    var selectedCustomer = this.collection.get(customerId)
    var personSharedCustomers = this.appregistry.models.person.get('sharedCustomers')
    var self = this
    if (personSharedCustomers.contains(selectedCustomer)) {
      $.jGrowl('Asiakas on jo lisätty', { life: 3000 })
    } else {
      var sha = new personSharedCustomerModel.sharedCustomerModel({
        personId: this.appregistry.models.person.id,
        customerId: selectedCustomer.id,
      })
      sha.save().success(function() {
        self.collection.reset()
        personSharedCustomers.add(selectedCustomer)
      })
    }
  },
  searchCustomers: function() {
    var isValid = this.model.isValid(true)
    this.collection.reset()
    if (isValid) {
      this.collection.fetch({ data: { crit: this.model.get('crit') } }).success(function(response) {
        if (!response.length) {
          $.jGrowl('Asiakasta ei löytynyt', { life: 2000 })
          $('.createCustomer').prop('disabled', false)
        } else {
          $('.createCustomer').prop('disabled', true)
        }
      })
    }
  },
  collectionChanged: function(event) {
    if (!this.collection.length) {
      this.$el.find('.table').addClass('hidden')
    } else {
      this.$el.find('.table').removeClass('hidden')
    }
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
    Backbone.Validation.bind(this)
    this.collectionChanged()
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
    Backbone.Validation.unbind(this)
  },
})

