import Backbone from 'backbone'
import relationalPersonModel from 'models/relational/relationalPersonModel'

var businessIdPattern = '^[0-9]{7,7}[-][0-9]$'
var sotu = '^(0[1-9]|[12]\\d|3[01])(0[1-9]|1[0-2])([5-9]\\d+|\\d\\d-|[01]\\dA)\\d{3}[\\dA-Z]$'

var personDTOModel = Backbone.Model.extend({})
var peopleCollection = Backbone.Collection.extend({
  model: personDTOModel,
})

var costUnitModel = Backbone.Model.extend({})
var costUnitCollection = Backbone.Collection.extend({
  model: costUnitModel,
})

var relationalSharedCustomerModel = Backbone.Model.extend({
  initialize: function(a) {
    this.set('people', new peopleCollection([]))
    this.set('costUnits', new costUnitCollection([]))
  },
  parse: function(data, options) {
    if (this.attributes.people) {
      this.attributes.people.reset(data.people)
      data.people = this.attributes.people
    }
    if (this.attributes.costUnits) {
      this.attributes.costUnits.reset(data.costUnits)
      data.costUnits = this.attributes.costUnits
    }
    return data
  },
  validBusinessId: function(value) {
    if (!value.match(businessIdPattern)) {
      return false
    }
    var array = _.toArray(value)
    var checker = [7, 9, 10, 5, 8, 4, 2]
    var sum = 0
    for (var i = 0; i < checker.length; i++) {
      sum += parseInt(array[i]) * checker[i]
    }
    var checkNumber = sum % 11
    if (checkNumber >= 2) {
      checkNumber = 11 - checkNumber
    }
    if (array[8] != checkNumber) {
      return false
    }
    return true
  },
  validSotu: function(value) {
    return !!value.match(sotu)
  },
  validation: {
    name: {
      required: true,
      msg: 'Nimi on pakollinen',
    },
    businessid: function(value) {
      if (this.get('partyRole') == 'CUSTOMER') {
        return
      }
      if (this.get('vat')) {
        return
      }
      if (!this.validBusinessId(value)) {
        return 'Täytyy olla y-tunnus'
      }
    },
    vat: function(value) {
      if (this.get('partyRole') == 'CUSTOMER') {
        return
      }
      if (this.get('businessid')) {
        return
      }
      if (!value) {
        return 'VAT tai Y-tunnus pitää syöttää'
      }
    },
    personSotu: function(value) {
      if (this.get('partyRole') == 'COMPANY') {
        return
      }
    },
    phonenumber: function(value) {
      if (this.get('email')) {
        return false
      }
      if (!value) {
        return 'Pakollinen tieto'
      }
    },
    email: {
      required: true,
      msg: 'Syötä email osoite',
    },
    operator: function(value) {
      if (this.get('sendBillMethod') != 'EBILL') {
        return
      }
      if (!value) {
        return 'Pakollinen tieto'
      }
    },
    netbankaddress: function(value) {
      if (this.get('sendBillMethod') != 'EBILL') {
        return
      }
      if (!value) {
        return 'Pakollinen tieto'
      }

    },
    termofpayment: function(value) {
      var number = parseInt(value)
      if (!isFinite(number)) {
        return 'Täytyy olla numeerinen'
      }
      if (number < 1 || number > 45) {

        return 'Täytyy olla välillä 1-45'
      }
    },
    address: function(value) {
      if (!value) {
        return 'Pakollinen tieto'
      }
    },
    postalcode: function(value) {
      if (!value) {
        return 'Pakollinen tieto'
      }
    },
    city: function(value) {
      if (!value) {
        return 'Pakollinen tieto'
      }
    },

  },
  defaults: {
    termofpayment: 14,
    partyRole: 'COMPANY',
    personSotu: '',
    businessid: '',
    sendBillMethod: 'PAPERBILL',

  },
})
export default relationalSharedCustomerModel


