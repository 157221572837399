import Backbone from 'backbone'
import Appregistry from 'js/libs/appregistry/appregistry'
import singleBillLayout from 'js/views/user/bill/singleBillLayout'
import billsTemplate from 'templates/bill/bills.html'
import billsCollection from 'js/views/user/bill/models'

export default Backbone.Marionette.CompositeView.extend({
  template: billsTemplate,
  childView: singleBillLayout,
  childViewContainer: '#bills-collection',
  collection: billsCollection.billsCollection,
  className: 'section',
  events: {
    'click .new_bill': 'newItem',
  },
  initialize: function() {
    var self = this
    this.collection = new billsCollection.billsCollection()
    Appregistry.models.person.checkLogin().success(function() {
      self.collection.fetch()
    })
  },
  newItem: function() {
    this.collection.add({ opened: 'opened', state: 'OPEN', person: Appregistry.models.person.toJSON() })
  },
})

