import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useFieldArray, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import NestedTable from './nestedTable'
import '../react.css'
import Loading from '../components/loading-spinner'

const { DateTime } = require('luxon')

export const TesSideOver = ({ selectedTes, setSelectedTes, token, updateData, loading }) => {
  const show = !!selectedTes?.id

  if (!show) return undefined
  const { values, errors, control, register, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      ...selectedTes,
      remunerations: selectedTes.remunerations?.map((remu) => {
        return {
          ...remu,
          notValid: remu.notValid?.map((notV) => {
            return {
              value: notV,
              id: uuidv4(),
            }
          }),
          validOnDays: remu.validOnDays?.map((notV) => {
            return {
              value: notV,
              id: uuidv4(),
            }
          }),
        }
      }),
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'remunerations',
  })

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type))
    return () => subscription.unsubscribe()
  }, [ watch ])
  if (!show) return
  const closeSide = () => {
    setSelectedTes({})
  }
  const onSubmit = async (data) => {
    const newData = {
      ...data,
      remunerations: data.remunerations?.map((remu) => {
        return {
          ...remu,
          notValid: remu.notValid?.map((not) => not.value),
          validOnDays: remu.validOnDays?.map((not) => not.value),
        }
      }),
    }
    await updateData(newData)
  }

  const close = async (data) => {
    setSelectedTes({})
  }

  const addNew = () => {
    append({
      allowanceName: '',
      allowancePrice: null,
      mondays: null,
      tuesdays: null,
      wednesdays: null,
      thursdays: null,
      fridays: null,
      saturdays: null,
      sundays: null,
      validFromDate: null,
      validToDate: null,
      validFrom: '00:00:00',
      remunerationPercent: null,
      priority: null,
      validTo: '00:00:00',
    })
  }

  const removeRemuneration = (index) => {
    remove(index)
  }

  return (
    <Transition.Root show={ show } as={ Fragment }>
      <Dialog as="div" className="relative z-10" onClose={ closeSide }>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={ Fragment }
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                  <form
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    onSubmit={ handleSubmit(onSubmit) }
                  >
                    <div className="flex-1 mx-4">
                      {/* Header */ }
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex space-x-4">

                          <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              TES nimi
                            </label>
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              <input { ...register(`name`) } />
                            </Dialog.Title>
                          </div>
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              Ammattiluokkakoodi
                            </label>
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              <input { ...register(`professionCode`) } />
                            </Dialog.Title>
                          </div>

                          <div style={ {marginLeft: "auto"}}>
                            <div>
                              <Loading loading={ loading }></Loading>
                            </div>
                            <button
                              type="submit"
                              className="mr-6 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Tallenna
                            </button>
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={ () => closeSide() }
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <table className="tes-table min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Nimi
                          </th>
                          <th
                            scope="col"
                            className="width-160 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Tulolaji
                          </th>
                          <th
                            scope="col"
                            className="width-160 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Voimassa päivinä
                          </th>
                          <th
                            scope="col"
                            className="width-160 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Ei voimassa päivinä
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Päivät
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Alku ja loppuaika
                          </th>

                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Voimassa
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Hinta
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Prosenttikorotus
                          </th>

                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        { fields?.map((remuneration, nestIndex) => (
                          <NestedTable
                            { ...{
                              control,
                              register,
                              errors,
                              remuneration,
                              nestIndex,
                              setValue,
                              removeRemuneration: remove,
                            } }
                          />
                        )) }
                        </tbody>
                      </table>

                      {/*  Add new */ }
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={ () => addNew() }
                        >
                          Lisää uusi
                        </button>
                      </div>
                    </div>
                    {/* Action buttons */ }
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={ () => closeSide() }
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Tallenna
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
