import _ from 'underscore'
import Backbone from 'backbone'
import singleBillLayout from 'views/user/bill/singleBillLayout'
import billsTemplate from 'templates/oldbills/oldbills.html'
import models from 'views/user/bill/models'

var searchModel = Backbone.Model.extend({
  defaults: {
    startDate: null,
    endDate: null,
    billId: null,
    page: 0,
  },
})

export default Backbone.Marionette.CompositeView.extend({
  template: function(serialized) {
    return _.template(billsTemplate)({
      items: serialized,
      bills: this.collection,
    })
  },
  childView: singleBillLayout,
  childViewContainer: '.bills-collection',
  initialize: function(billId) {
    this.modelBinder = new Backbone.ModelBinder()
    this.model = new searchModel()
    this.searchObject = {
      data: {},
    }
    if (!_.isEmpty(billId)) {
      this.model.set('billId', billId.billId)
      this.searchObject.data.billId = billId.billId
    }
    var self = this
    this.collection = new models.oldBillsCollection()
    this.collection.fetch(this.searchObject).success(function(response) {
      if (response.length) {
        self.$el.find('.no-bills').addClass('hidden')
        self.$el.find('.found-bills').removeClass('hidden')
      } else {
        self.$el.find('.no-bills').removeClass('hidden')
        self.$el.find('.found-bills').addClass('hidden')
      }
    })
  },
  onRender: function() {
    $(this.el).find('.date-picker').pickadate(window.helpers.pickadateOptions)
    this.modelBinder.bind(this.model, this.el)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  events: {
    'click .fetchBills': 'fetchBills',
    'click .moreOldBills': 'moreOldBills',
  },
  moreOldBills: function() {
    var self = this
    var newItems = new models.oldBillsCollection()
    this.model.set('page', this.model.get('page') + 1)
    this.searchObject.data = this.model.toJSON()
    newItems.fetch(this.searchObject).success(function(response) {
      if (newItems.models.length != 0) {
        self.collection.add(newItems.models)
      } else {
        $.jGrowl('Kaikki laskut näkyvillä', { life: 3000 })
      }
    })
  },
  fetchBills: function(e) {
    e ? e.preventDefault() : $.noop()
    var self = this
    this.model.set('page', 0)
    this.searchObject.data = this.model.toJSON()
    this.collection.fetch(this.searchObject).success(function(response) {
      if (response.length) {
        self.$el.find('.no-bills').addClass('hidden')
        self.$el.find('.found-bills').removeClass('hidden')
      } else {
        self.$el.find('.no-bills').removeClass('hidden')
        self.$el.find('.found-bills').addClass('hidden')
      }
    })
  },
})

