import Appregistry from 'libs/appregistry/appregistry'

var converter = {}

converter.stateConverter = function(direction, value) {
  // direction is either ModelToView or ViewToModel
  // Return either a formatted value for the view or an un-formatted value for the model
  if (direction === 'ModelToView') {
    switch (value) {
      case 'OPEN':
        return 'Avoin'
      case 'CLOSED':
        return 'Lähetetty'
      case 'APPROVED':
        return 'Hyväksytty'
      case 'PAID':
        return 'Maksettu'
      case 'PENDING':
        return 'Hyväksyttävänä'
      default:
        return 'Lukittu'
    }
  } else {
    switch (value) {
      case 'Avoin':
        return 'OPEN'
      case 'Lähetetty':
        return 'CLOSED'
      case 'Hyväksytty':
        return 'APPROVED'
      case 'Maksettu':
        return 'PAID'
      case 'Hyväksyttävänä':
        return 'PENDING'
      default:
        return 'LOCKED'
    }
  }
}

converter.typeConverter = function(direction, value) {
  // direction is either ModelToView or ViewToModel
  // Return either a formatted value for the view or an un-formatted value for the model
  let return_value
  if (direction === 'ModelToView') {
    if (value === 'CONTRACT') {
      return_value = 'Urakka'
    } else if (value === 'MONEY') {
      return_value = 'Rahasiirto'
    } else if (value === 'STUDY') {
      return_value = 'Opintomatka'
    } else if (value === 'NEGOTIATION') {
      return_value = 'Neuvottelu'
    } else {
      return_value = 'Tuntilaskutus'
    }
  } else {
    if (value === 'Urakka') {
      return_value = 'CONTRACT'
    } else if (value === 'Rahasiirto') {
      return_value = 'MONEY'
    } else if (value === 'Opintomatka') {
      return_value = 'STUDY'
    } else if (value === 'Neuvottelu') {
      return_value = 'NEGOTIATION'
    } else {
      return_value = 'AMOUNT'
    }
  }
  return return_value
}

converter.percentConverter = function(direction, value) {
  let return_value = null
  if (direction == 'ModelToView') {
    var formatted = (Math.round(10000 * (value * 100)) / 10000).toFixed(2)
    if (isFinite(formatted)) {
      formatted = formatted.replace('.', ',')
      formatted = formatted + ' %'
      return_value = formatted
    }
  } else {
    var formatted = value.replace('%', '').replace(',', '.').replace(' ', '')
    if (isFinite(formatted)) {
      return_value = Math.round(10000 * (formatted / 100)) / 10000
    }
  }
  return return_value
}

converter.bigPercent = function(direction, value) {
  let return_value
  if (direction === 'ModelToView') {
    var formatted = value.toFixed(2)
    formatted = formatted.replace('.', ',')
    formatted = formatted + '%'
    return_value = formatted
  } else {
    var formatted = value.replace('%', '').replace(',', '.').replace(' ', '')
    if (isNaN(formatted)) {
      return_value = 0
    } else {
      return_value = formatted
    }
  }
  return return_value
}

converter.authTokenConverter = function(direction, value) {
  var return_value
  if (direction === 'ModelToView') {
    return_value = value + '?authtoken=' + Appregistry.models.person.get('authtoken')
  } else {
    return_value = value
  }
  return return_value
}


converter.createDateField = function(value, return_value) {
  var date = new Date(value)


  return_value += date.getDate()
  return_value += '.' + (date.getMonth() + 1)
  return_value += '.' + date.getFullYear()
  return_value += ' '
  var hours = date.getHours()
  if (hours < 10) {
    hours = '0' + hours
  }
  return_value += hours + '.'
  var minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return_value += minutes + '.'
  var seconds = date.getSeconds()
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return_value += seconds + '  '
  return return_value
}

converter.timestampConverter = function(direction, value) {
  var return_value = ''
  if (direction === 'ModelToView') {
    if (value !== '' && value != null) {
      return_value = converter.createDateField(value, return_value)
    }
  } else {
    return_value = value.getTime()
  }
  return return_value
}

converter.testConverter = function(direction, value) {
  if (value) {
    return 'new-shiny-item'
  } else {
    return ''
  }
}

converter.showConverter = function(direction, value) {
  if (value) {
    return 'not-hidden'
  } else {
    return 'hidden-true'
  }
}

converter.euroConverter = function(direction, value, el, model) {
  var return_value = ''
  if (direction == 'ModelToView') {
    if (isNaN(parseFloat(value))) {
      return_value = '0 €'
    } else {
      return_value = (parseFloat(value).toFixed(2) + ' €').replace('.', ',')
    }
  } else { // "view to model"
    return_value = parseFloat(value.replace(' ', '').replace('€', '').replace(',', '.')).toFixed(2)
    if (isNaN(return_value)) {
      return_value = 0
    }
  }
  return return_value
}

converter.preciseeuroConverter = function(direction, value) {
  var return_value = ''
  if (direction == 'ModelToView') {
    if (value == null) {
      return_value = ''
    } else {
      value = parseFloat(value)
      value = value.toFixed(5)
      return_value = value + ' €'
      return_value = return_value.replace('.', ',')
    }

  } else {
    return_value = value.replace(' ', '').replace('€', '').replace(',', '.')
    var val = parseFloat(return_value)
    if (isNaN(val)) {
      return_value = null
    } else {
      return_value = val.toFixed(5)
    }
  }
  return return_value
}


converter.personConverter = function(direction, value) {
  let return_value = value
  if (direction == 'ModelToView') {
    if (value != null && value.id) {
      return_value = value.id
    }
  } else {
    if (Appregistry) {
      return_value = Appregistry.people.get(value)
    }
  }
  return return_value
}

converter.idConverter = function(direction, value) {
  let return_value = value
  if (direction == 'ModelToView') {
    if (value != null && value.id) {
      return_value = value.id
    }
  }
  return return_value
}

converter.personDisable = function(direction, value) {
  if (direction == 'ModelToView') {
    if (value) {
      return false
    } else {
      return true
    }
  }
}

converter.exists = function(direction, value) {
  if (direction == 'ModelToView') {
    if (value) {
      return false
    } else {
      return true
    }
  }
}

converter.personListConverter = function(direction, value) {
  let return_value = ''
  if (direction == 'ModelToView') {
    if (value != null) {
      return_value = value
    }
  } else {
    return_value = value
  }
  return return_value
}

converter.customerJSONConverter = function(direction, value) {
  // direction is either ModelToView or ViewToModel
  // Return either a formatted value for the view or an un-formatted value for the model

  if (value == null || value === undefined) {
    return ''
  }
  if (direction == 'ModelToView') {
    return value.name
  } else {
    return value
  }
  return return_value
}

converter.customerConverter = function(direction, value) {
  // direction is either ModelToView or ViewToModel
  // Return either a formatted value for the view or an un-formatted value for the model

  if (value == null || value === undefined) {
    return ''
  }
  if (direction == 'ModelToView') {
    return value.get('name')
  } else {
    return value
  }
  return return_value
}

converter.dotConverter = function(direction, value) {
  // direction is either ModelToView or ViewToModel
  // Return either a formatted value for the view or an un-formatted value for the model
  let return_value = ''
  if (direction == 'ModelToView') {
    //var formatted = value.replace("%", "").replace(",",".").replace(" ","");
    var formatted = parseFloat(value)
    if (!isFinite(parseFloat(value))) {
      formatted = 0
    }
    return_value = formatted.toFixed(2).replace('.', ',')
  } else {
    var formatted = parseFloat(value.replace('%', '').replace(',', '.').replace(' ', ''))
    if (isFinite(formatted)) {
      return_value = formatted.toFixed(2)
    } else {
      return_value = 0
    }
  }
  return return_value
}

converter.integerConverter = function(direction, value) {
  let return_value = ''
  if (direction == 'ModelToView') {
    var formatted = parseInt(value)

    if (isNaN(formatted)) {
      return undefined
    }
    return_value = formatted
  } else {
    var formatted = parseInt(value)
    isNaN(formatted) == true ? return_value = 0 : return_value = formatted
  }
  return return_value
}

converter.isNotPaid = function(direction, value) {
  if (direction == 'ModelToView') {
    if (value && value !== 'PAID') {
      return true
    }
  }
  return false
}
converter.isPaid = function(direction, value) {
  if (direction == 'ModelToView') {
    if (value && value === 'PAID') {
      return true
    }
  }
  return false
}

converter.tesConverter = function(direction, value) {
  if (direction == 'ModelToView') {
    if (value) {
      return value.id
    }
  } else {
    return Appregistry.authModel.tesCollection.get(value)
  }
  return null
}
converter.noteRequestConverter = function(direction, value) {
  if (direction === 'ModelToView') {
    if (value) {
      return ''
    } else {
      return 'hidden'
    }
  }
}

export default converter

