import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { EditPerson } from './edit-person'
import { Paging } from './paging'

const WorkQueue = ({ token }) => {
  // "show" state controls whether to show new or old items
  const [show, setShow] = useState('new')
  // The fetched items for the grid
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [personId, setPersonId] = useState(0)

  const [selectedIds, setSelectedIds] = useState([])


  useEffect(() => {
    axios
      .get(`/api/admin/worklist?page=${page}&limit=100&newItems=${show === 'new'}`, {
        headers: {
          authtoken: token,
        },
      })
      .then(({ data }) => {
        console.log('reply', data.data)
        setItems(data.data)
        setTotalPages(Math.ceil(data.itemsCount / data.limit))
      })
  }, [page, show])

  const changeDone = (item, bool) => {
    console.log('item', item)
    axios
      .put(`/api/admin/worklist/${item.id}`, {
          ...item,
          done: bool,
        },
        {
          headers: {
            authtoken: token,
          },
        })
      .then(({ data }) => {
        setItems(items.filter(item => item.id !== data.id))
        setTotalPages(Math.ceil(items.length / 100))
      })
  }

  const decision = (item, decision) => {
    console.log('item', item)
    axios
      .put(`/api/admin/worklist/${item.id}`, {
          ...item,
          decision: decision,
        },
        {
          headers: {
            authtoken: token,
          },
        })
      .then(({ data }) => {
        setItems(items.filter(item => item.id !== data.id))
        setTotalPages(Math.ceil(items.length / 100))
      })
  }


  const deleteItem = (item) => {
    console.log('item', item)
    axios
      .delete(`/api/admin/worklist/${item.id}`,
        {
          headers: {
            authtoken: token,
          },
        })
      .then(({ data }) => {
        console.log(data, item)
        setItems(items.filter(i => i.id !== item.id))
        setTotalPages(Math.ceil(items.length / 100))
      })
  }

  // Handler for toggling "new" vs. "old" display
  const handleShowNew = () => {
    setShow('new')
  }
  const handleShowOld = () => {
    setShow('old')
  }

  const moveToNew = () => {
    console.log('moveToDone')
  }
  const destroyAll = () => {
    console.log('moveToDone')
  }

  const getActionButtons = (item) => {
    if (item.itemType === 'AdvancePayment') {
      return (
        <div className="flex space-x-2">
          <button
            type="button"
            onClick={() => decision(item, "APPROVED")}
            className="rounded-full bg-green-400 hover:bg-green-300 focus-visible:outline-green-500 px-2.5 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Hyväksy
          </button>
          <button
            type="button"
            onClick={() => decision(item, "DECLINED")}
            className="rounded-full bg-blue-400 hover:bg-blue-300 focus-visible:outline-blue-500 px-2.5 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Hylkää
          </button>
        </div>
      )
    }
    return (
      <button
        type="button"
        onClick={() => changeDone(item, true)}
        className="rounded-full bg-green-400 hover:bg-green-300 focus-visible:outline-green-500 px-2.5 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      >
        Valmis
      </button>
    )
  }

  const getDescription = (item) => {
    if (item.itemType === 'NewUserRegistered') {
      return (<a className="text-blue-900" onClick={() => setPersonId(item.entityId)}>
        {item.description}
      </a>)
    }
    return (<span>{item.description}</span>)
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Työjono</h1>
      <div className="mb-4 space-x-2">
        <button
          className={`px-4 py-2 rounded ${
            show === 'new' ? 'bg-green-500 text-white' : 'bg-gray-300'
          }`}
          onClick={handleShowNew}
        >
          Näytä uudet
        </button>
        <button
          className={`px-4 py-2 rounded ${
            show === 'old' ? 'bg-green-500 text-white' : 'bg-gray-300'
          }`}
          onClick={handleShowOld}
        >
          Näytä vanhat
        </button>
      </div>

      {/* Table rendered with Tailwind */}
      <table className="min-w-full divide-y divide-gray-200 mb-4">
        <thead className="bg-gray-50">
        <tr>
          <th className="px-4 py-2">
            <input
              type="checkbox"
              checked={selectedIds.length === items.length && items.length > 0}
            />
          </th>
          <th className="px-4 py-2 text-left">Asiakas</th>
          <th className="px-4 py-2 text-left">Kuvaus</th>
          <th className="px-4 py-2 text-left">Kohde id</th>
          <th className="px-4 py-2 text-left">Summa</th>
          <th className="px-4 py-2 text-left">Päivitetty</th>
          <th className="px-4 py-2 text-left">Eräpäivä</th>
          <th className="px-4 py-2">Toiminnot</th>
          <th className="px-4 py-2">Poista</th>
        </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item) => (
          <tr key={item.id} className="hover:bg-gray-50">
            <td className="px-4 py-2">
              <input
                type="checkbox"
                checked={selectedIds.includes(item.id)}
                onChange={(e) => handleToggleSelect(e, item.id)}
              />
            </td>
            <td className="px-4 py-2">{item.customer}</td>
            <td className="px-4 py-2">{getDescription(item)}</td>
            <td className="px-4 py-2">{item.entityId}</td>
            <td className="px-4 py-2">{item.amount}</td>
            <td className="px-4 py-2">{item.updated}</td>
            <td className="px-4 py-2">{item.duedate}</td>
            <td className="px-4 py-2">
              {getActionButtons(item)}

            </td>
            <td className="px-4 py-2">
              <button
                type="button"
                onClick={() => deleteItem(item)}
                className="rounded-full bg-orange-400 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-orange-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
              >
                Poista
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <Paging
        currentPage={page}
        totalPages={totalPages}
        onPageChange={(newPage) => setPage(newPage)}
      />


      {/* Grid Controls */}
      <div className="space-x-2 mb-4">
        <button className="px-4 py-2 bg-blue-500 text-white rounded" onClick={changeDone}>
          Siirrä valitut valmiiksi
        </button>
        <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={moveToNew}>
          Siirrä valitut uudeksi
        </button>
        <button className="px-4 py-2 bg-red-500 text-white rounded" onClick={destroyAll}>
          Poista valitut
        </button>
      </div>

      <div className="receipts"></div>

      {/* Slide-over panel using Tailwind */}
      <EditPerson {...{ personId, setPersonId, token }} />
    </div>
  )
}

export default WorkQueue
