import peopleItemTemplate from 'views/admin/marionette/people/editPerson.html'
import converter from 'libs/converter'

var peopleItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(peopleItemTemplate)({
      items: serialized,
    })
  },
  tagName: 'div',
  className: 'row-fluid',
  initialize: function(args) {
    this.model = this.collection.newModel(args.publicSettings)
    this.personBinder = new Backbone.ModelBinder()
    this.personSettingsBinder = new Backbone.ModelBinder()
    Backbone.Validation.bind(this)
  },
  events: {
    'click .savePerson': 'savePerson',
    'click .closeNow': 'closeNow',
  },
  onDestroy: function() {
    this.personBinder.unbind()
    this.personSettingsBinder.unbind()
    Backbone.Validation.unbind(this)
  },
  onRender: function() {
    this.personBinder.bind(this.model.get('person'), this.el, personBindings)
    this.personSettingsBinder.bind(this.model.get('person').get('personSettings'), this.el, personSettingsBindings)
    return this
  },
  savePerson: function() {
    var self = this
    if (this.model.isValid(true)) {
      this.model.save()
        .success(function(response) {
          self.collection.add(response, { merge: true })
          self.destroy()
        })
        .error(function(response) {
          $.jGrowl('Ei voitu luoda henkilöä! Tarkista onko henkilö tai käyttäjätunnus jo olemassa', { life: 10000 })
        })

    }

  },
  closeNow: function() {
    this.destroy()
  },
})

var personBindings = {
  firstName: '[name=firstName]',
  lastName: '[name=lastName]',
  phone: '[name=phone]',
  email: '[name=email]',
  role: '[name=role]',
  marketingname: '[name=marketingname]',
  address: '[name=address]',
  postalcode: '[name=postalcode]',
  iban: '[name=iban]',
  bic: '[name=bic]',
  username: '[name=username]',
  password: '[name=password]',
  sotu: '[name=sotu]',
  city: '[name=city]',
}
var personSettingsBindings = {
  taxpercent: { selector: '[name=taxpercent]', converter: converter.percentConverter },
  minimumhourlywage: { selector: '[name=minimumhourlywage]', converter: converter.euroConverter },
  osk: { selector: '[name=osk]', converter: converter.percentConverter },
  holidaybonus: { selector: '[name=holidaybonus]', converter: converter.percentConverter },
  accidentInsurance: { selector: '[name=accidentInsurance]', converter: converter.percentConverter },
}

export default peopleItemView

