import Backbone from 'backbone'

var searchModel = Backbone.Model.extend({
  defaults: {
    page: 1,
  },
  urlString: function() {
    var array = []
    var string = ''
    if (this.get('startDate')) {
      array.push('startDate=' + this.get('startDate'))
    }
    if (this.get('endDate')) {
      array.push('endDate=' + this.get('endDate'))
    }
    if (this.get('crit')) {
      array.push('crit=' + this.get('crit'))
    }
    if (this.get('idcrit')) {
      array.push('idcrit=' + this.get('idcrit'))
    }
    if (array.length > 0) {
      string = array.join('&')
    }
    return string
  },
})

export default searchModel
