import sharedCustomersItemView from 'views/admin/marionette/sharedcustomers/sharedCustomersItemView'
import sharedCustomersLayout from 'templates/admin/marionette/sharedcustomers/sharedcustomers.html'


var sharedCustomers = Backbone.Marionette.CompositeView.extend({
  childView: sharedCustomersItemView,
  childViewContainer: '.customers-list',
  template: function(serialized) {
    return _.template(sharedCustomersLayout)({
      items: serialized,
    })
  },
  onShow: function() {
    this.collection.fetch()
  },
})
export default sharedCustomers

