import Appregistry from 'libs/appregistry/appregistry'
import salaryHtml from 'views/admin/salaries/singlesalary.html'
import modelBinder from 'backbone.modelbinder'
import freeBillableItemsCompositeView from 'views/admin/salaries/freeBillableItemsCompositeView'
import assignedBillableItemsCompositeView from 'views/admin/salaries/assignedBillableItemsCompositeView'
import allowanceCompositeView from 'views/admin/salaries/allowances/allowanceCompositeView'
import converter from 'libs/converter'
import 'models/admin/salary/salaryListModel'
import payRowView from './payRowView'
/*
 single salary
 */
var singleSalaryView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    var distraint = serialized.person && serialized.person.distraint ? true : false
    return _.template(salaryHtml)({
      people: Appregistry.peopleCollection,
      tes: Appregistry.authModel.tesCollection,
      person: serialized.person || {},
      distraint: distraint,
      items: serialized,
    })
  },
  events: {
    'click .open_salary_item': 'openSalary',
    'click .add-billable-item-to-salary': 'addFreeBillableItem',
    'click .remove-billable-item-from-salary': 'removeBillableItem',
    'click .pay-salary': 'paySalary',
    'click .delete_salary_item': 'deleteSalary',
    'click .remove-all-items': 'removeAllItems',
    'click .add-all-items': 'addAllItems',
    'click .addAllWithBill': 'addWithBillId',
    'click .calculateAllowances': 'calculateAllowances',
  },
  modelEvents: {
    'remove': 'closeView',
  },
  className: function() {
  },
  tagName: 'div',
  initialize: function() {
    this.modelBinder = new modelBinder()
    this.fullSalaryBinder = new modelBinder()
    this.collection = Appregistry.peopleCollection
    Backbone.Validation.bind(this)
    this.model.off('change')
    this.model.on('change', this.changed, this)
    this.listenTo(this.model, 'allowaces:changed', this.saveAllowances)
  },
  onDestroy: function() {
    this.model.off()
    this.picker.stop()
    this.modelBinder.unbind()
    this.fullSalaryBinder.unbind()
  },
  regions: {
    assigneditems: '.assigned-items-container',
    freeitems: '.free-items-container',
    allowanceRegion: '.allowance-container',
    freeSubcontractorItemRegion: '.free-subcontractor-item-rows',
    assignedSubcontractorItemRegion: '.assigned-subcontractor-item-rows',
    freeContractorItemRegion: '.free-contractor-item-rows',
    assignedContractorItemRegion: '.assigned-contractor-item-rows',
    extAssignedItemRegion: '.assigned-ext-contractor-item-rows',
    extFreeItemRegion: '.free-ext-contractor-item-rows',
    payRows: '.payrows',
  },
  changed: function(model, event) {
    if (this.model.get('person')) {
      this.$el.find('.disable-selected').prop('disabled', true)
    }
    if (this.model.isValid(true) && event.changeSource == 'ModelBinder') {
      this.model.save(null, { wait: true }).success(function(model) {
      })
    }
    this.showAdditionalInfo()
  },
  saveModel: function(model, event) {
    var changes = Object.keys(this.model.get('fullSalary').changed)
    var self = this
    if (changes.length
      && event.changeSource == 'ModelBinder'
      && this.model.get('fullSalary').isValid(true)) {
      this.model.save(null, { wait: true }).success(function(model) {
        if (self.model.get('fullSalary').get('state') != 'OPEN') {
          self.$el.find('.show-if-not-paid').hide()
        }
      })
    }

  },
  saveAllowances: function() {
    this.model.save(null, { wait: true }).success(function(model) {
    })
  },
  onDetach: function() {
    var elements = this.$el.find('select')
    _.each(elements, function(elem) {
      var s = $(elem)
      if (s.data('select2')) {
        s.select2('destroy')
      }
    })
    this.picker.stop()
  },
  onRender: function() {
    this.modelBinder.unbind()
    this.modelBinder.bind(this.model, this.el, briefBinds)
    this.picker = this.$el.find('.date-picker').pickadate(window.helpers.pickadateOptions)
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    this.showAdditionalInfo()
    console.log('onRender')
    return this
  },
  onClose: function() {
    this.picker.stop()
  },
  showAdditionalInfo: function() {
    var person = this.model.get('person')
    if (person) {
      if (person.note) {
        this.$el.find('[name=note]').html(person.note)
      }
      if (person.distraint) {
        this.$el.find('.distrained').removeClass('hidden')
      }
      if (person.taxCardInvalid) {
        this.$el.find('.taxCardMissing').removeClass('hidden')
      }
    }
  },
  openSalary: function() {
    var isValid = this.model.isValid(true)
    if (isValid) {
      this.$el.find('.total_reimbursements').toggle()
      var self = this
      this.model.fetch()
        .success(function(salaryModel) {
          self.freeBillableItemsView = new freeBillableItemsCompositeView({ collection: self.model.get('freeItems') })
          self.getRegion('freeitems').show(self.freeBillableItemsView)

          self.assigneditems = new assignedBillableItemsCompositeView({ collection: self.model.get('fullSalary').get('assignedItems') })
          self.getRegion('assigneditems').show(self.assigneditems)
          // subcontractor items
          /*
          self.freeSubcontractorView = new freeSubcontractorCompositeview({ collection: self.model.get('subcontractorItems') })
          self.getRegion('freeSubcontractorItemRegion').show(self.freeSubcontractorView)

          var assignedSubcontractorItems = self.model.get('fullSalary').get('subcontractorBillableItems')
          self.assignedSubcontractorView = new assignedSubcontractorCompositeview({ collection: assignedSubcontractorItems })
          self.getRegion('assignedSubcontractorItemRegion').show(self.assignedSubcontractorView)

          // contractor items
          var contractorCollection = self.model.get('contractorItems')
          self.freeContractorView = new freeContractorCompositeview({ collection: contractorCollection })
          self.getRegion('freeContractorItemRegion').show(self.freeContractorView)

          var assignedContractorItems = self.model.get('fullSalary').get('contractorBillableItems')
          self.assignedContractorView = new assignedContractorCompositeview({ collection: assignedContractorItems })
          self.getRegion('assignedContractorItemRegion').show(self.assignedContractorView)

          var freeExtContractorItems = self.model.get('extContractorItems')
          self.freeExtContractorView = new freeExtContractorCompositeview({ collection: freeExtContractorItems })
          self.getRegion('extFreeItemRegion').show(self.freeExtContractorView)

          var ExtContractorItems = self.model.get('fullSalary').get('extContractorBillableItems')
          self.ExtContractorView = new assignedExtContractorCompositeview({ collection: ExtContractorItems })
          self.getRegion('extAssignedItemRegion').show(self.ExtContractorView)
        */
          var allowances = self.model.get('fullSalary').get('allowances')
          allowances.parent = self.model
          self.getRegion('allowanceRegion').show(new allowanceCompositeView({ collection: allowances }))
          self.fullSalaryBinder.bind(self.model.get('fullSalary'), self.el, modelBinds)

          self.model.get('fullSalary').on('change', self.saveModel, self)

          var payRows = self.model.get('fullSalary').get('payRows')
          self.payRows = self.getRegion('payRows').show(new payRowView({ model: payRows }))

          if (!Appregistry.isMobile) {
            self.$el.find('select').select2(Appregistry.selectOpts)
          }
        })
    } else {
      $.jGrowl('Ei voida näyttää, täytä tarvittavat tiedot ensin', { life: 3000 })
    }
  },
  addFreeBillableItem: function(event) {
    var id = $(event.currentTarget).attr('data-bind')
    var modelToAdd = this.model.get('freeItems').get(id)
    if (modelToAdd) {
      var self = this
      this.model.get('freeItems').remove(id)
      this.model.get('fullSalary').get('assignedItems').add(modelToAdd)
      //self.freeBillableItemsView.render()
      this.model.save(null, { wait: true }).success((model) => {
        self.payRows.reset()
        self.payRows = self.getRegion('payRows').show(new payRowView({ model: this.model.get('fullSalary').get('payRows') }))
      })
    }
  },
  removeBillableItem: function(event) {
    var id = $(event.currentTarget).attr('data-bind')
    var billableItem = this.model.get('fullSalary').get('assignedItems').get(id)
    if (billableItem) {
      var self = this
      this.model.get('fullSalary').get('assignedItems').remove(id)
      this.model.get('freeItems').add(billableItem)
      //self.freeBillableItemsView.render()
      //self.assigneditems.render()
      self.payRows.reset()
      self.payRows = self.getRegion('payRows').show(new payRowView({ model: this.model.get('fullSalary').get('payRows') }))
      this.model.save(null, { wait: true }).success((model) => {
        self.payRows.reset()
        self.payRows = self.getRegion('payRows').show(new payRowView({ model: this.model.get('fullSalary').get('payRows') }))
      })
    }

  },
  paySalary: function() {
    if (this.model.fullSalary) {
      if (confirm('Maksetaanko palkka?')) {
        this.model.get('fullSalary').set({ 'state': 'PAID' })
      }
    }
  },
  deleteSalary: function() {
    if (confirm('Poistetaanko palkka lopullisesti?')) {
      this.model.destroy()
      this.destroy()
    }
  },
  closeView: function() {
    this.destroy()
  },
  removeAllItems: function() {
    var allItems = this.model.get('fullSalary').get('assignedItems').models
    this.model.get('fullSalary').get('assignedItems').reset()
    this.model.get('freeItems').add(allItems)
    this.model.save()
  },
  addAllItems: function() {
    var allItems = this.model.get('freeItems').models
    this.model.get('fullSalary').get('assignedItems').add(allItems)
    this.model.get('freeItems').reset()
    this.model.save()
  },
  addWithBillId: function(event) {
    var self = this
    var id = $(event.currentTarget).attr('data-bind')
    if (!id) {
      return
    }
    var model = this.model.get('freeItems').get(id)
    if (!model) {
      return
    }
    var billId = model.get('billId')
    _.forEach(this.model.get('freeItems').models, function(freeitem) {
      if (freeitem && freeitem.attributes.billId == billId) {
        self.model.get('fullSalary').get('assignedItems').add(freeitem)
      }
    })
    self.freeBillableItemsView.render()
    this.model.save()
  },
  calculateAllowances: function() {
    this.model.save(null, {
      url: this.model.url() + '?calc=true',
    })
  },
})
var personBinder = function(direction,
                            value) {
  let return_value = ''
  if (direction == 'ModelToView') {
    if (value != null) {
      return_value = value.id
    }
  } else {
    return_value = Appregistry.peopleCollection.get(value)
  }
  return return_value
}

var urlConverter = function(direction,
                            value) {
  var return_value = ''
  if (direction == 'ModelToView') {
    return_value = value + '?authtoken=' + Appregistry.models.person.get('authtoken')
  } else {
    return_value = value
  }
  return return_value
}

var briefBinds = {
  person: [
    { selector: '[name=person]', converter: personBinder },
    { selector: '.disable-selected', elAttribute: 'enabled', converter: converter.personDisable },
  ],
  overridden_kilometers: { selector: '[name=overridden_kilometers]', converter: converter.preciseeuroConverter },
  intervalstart: '[name=intervalstart]',
  intervalstop: '[name=intervalstop]',
  payday: '[name=payday]',
  osk: { selector: '[name=osk]', converter: converter.percentConverter },
  overridenOsk: { selector: '[name=overridenOsk]', converter: converter.percentConverter },
  salary: { selector: '.salary', converter: converter.euroConverter },
  salaryurl: { selector: '[name=salaryurl]', elAttribute: 'href', converter: urlConverter },
  state: [
    { selector: '[name=salarystate]' },
    { selector: '.salary-state', elAttribute: 'enabled', converter: converter.isNotPaid },
    { selector: '.show-if-paid', elAttribute: 'hidden', converter: converter.isNotPaid },
    { selector: '.show-if-not-paid', elAttribute: 'hidden', converter: converter.isPaid },
  ],
}

var modelBinds = {
  amount: { selector: '[name=amount]', converter: converter.euroConverter },
  alvtotal: { selector: '[name=alvtotal]', converter: converter.euroConverter },
  alvamount: { selector: '[name=alvamount]', converter: converter.euroConverter },
  taxableamount: { selector: '[name=taxableamount]', converter: converter.euroConverter },
  holidaybonus: { selector: '[name=holidaybonus]', converter: converter.percentConverter },
  holidaybonusamount: { selector: '[name=holidaybonusamount]', converter: converter.euroConverter },
  kilometers: { selector: '[name=kilometers]', converter: converter.integerConverter },
  kmreimbursement: { selector: '.kmreimbursement', converter: converter.euroConverter },
  kmreimbursementamount: { selector: '.kmreimbursementamount', converter: converter.euroConverter },
  fulldaycompensation: { selector: '[name=fulldaycompensation]', converter: converter.integerConverter },
  fulldaycompensationamount: { selector: '[name=fulldaycompensationamount]', converter: converter.euroConverter },
  partialdaycompensation: { selector: '[name=partialdaycompensation]', converter: converter.integerConverter },
  partialdaycompensationamount: {
    selector: '[name=partialdaycompensationamount]',
    converter: converter.euroConverter,
  },
  lunch_compensation: { selector: '[name=lunch_compensation]', converter: converter.integerConverter },
  lunch_compensationamount: { selector: '[name=lunch_compensationamount]', converter: converter.euroConverter },
  total_reimbursements: { selector: '[name=total_reimbursements]', converter: converter.euroConverter },
  advancePayments: { selector: '[name=advancePayments]', converter: converter.euroConverter },
  oskamount: { selector: '[name=oskamount]', converter: converter.euroConverter },
  oskTotal: { selector: '[name=oskTotal]', converter: converter.euroConverter },

  personTyEl: { selector: '[name=personTyEl]', converter: converter.percentConverter },
  personTyElamount: { selector: '[name=personTyElamount]', converter: converter.euroConverter },
  personunemploymentInsurance: {
    selector: '[name=personunemploymentInsurance]',
    converter: converter.percentConverter,
  },
  taxpercent: { selector: '[name=taxpercent]', converter: converter.percentConverter },
  taxamount: { selector: '[name=taxamount]', converter: converter.euroConverter },
  travelcost: { selector: '[name=travelcost]', converter: converter.euroConverter },
  hotelcost: { selector: '[name=hotelcost]', converter: converter.euroConverter },
  othercost: { selector: '[name=othercost]', converter: converter.euroConverter },
  lastSaved: { selector: '[name=lastSaved]', converter: converter.timestampConverter },
  salaryType: { selector: '[name=salaryType]' },
  personunemploymentInsuranceamount: {
    selector: '[name=personunemploymentInsuranceamount]',
    converter: converter.euroConverter,
  },

  sotu: { selector: '[name=sotu]', converter: converter.percentConverter },
  sotuamount: { selector: '[name=sotuamount]', converter: converter.euroConverter },
  sotu_total: { selector: '[name=sotu_total]', converter: converter.euroConverter },
  unemploymentInsurance: { selector: '[name=unemploymentInsurance]', converter: converter.percentConverter },
  unemploymentInsuranceAmount: {
    selector: '[name=unemploymentInsuranceAmount]',
    converter: converter.euroConverter,
  },
  tyEL: { selector: '[name=tyEL]', converter: converter.percentConverter },
  tyELAmount: { selector: '[name=tyELAmount]', converter: converter.euroConverter },
  bruttoSalary: { selector: '[name=bruttoSalary]', converter: converter.euroConverter },
  netto: { selector: '[name=netto]', converter: converter.euroConverter },
  accidentInsurance: { selector: '[name=accidentInsurance]', converter: converter.percentConverter },
  accidentInsuranceAmount: { selector: '[name=accidentInsuranceAmount]', converter: converter.euroConverter },
  accidentInsurance_total: { selector: '[name=accidentInsurance_total]', converter: converter.euroConverter },

  reduceFromNettoString: { selector: '[name=reduceFromNettoString]' },
  reduceFromNetto: { selector: '[name=reduceFromNetto]', converter: converter.euroConverter },
  distraint: { selector: '[name=distraint]', converter: converter.euroConverter },
  manualDistraint: { selector: '[name=manualDistraint]', converter: converter.euroConverter },
  useFullReimbursements: { selector: '[name=useFullReimbursements]' },
  manualDistraintReference: { selector: '[name=manualDistraintReference]' },
  tes: { selector: '[name=tes]', converter: converter.tesConverter },
  totalBilling: { selector: '[name=totalBilling]', converter: converter.euroConverter },
  totalBillingWithoutAlv: { selector: '[name=totalBillingWithoutAlv]', converter: converter.euroConverter },
  reductionBilling: { selector: '[name=reductionBilling]', converter: converter.euroConverter },
  reductionBillingWithoutAlv: { selector: '[name=reductionBillingWithoutAlv]', converter: converter.euroConverter },
  nettoBilling: { selector: '[name=nettoBilling]', converter: converter.euroConverter },
  foreignAllowance: { selector: '[name=foreignAllowance]', converter: converter.euroConverter },
  primaryWork: { selector: '[name=primaryWork]' },
  arvonlisaveropercent: { selector: '[name=arvonlisaveropercent]', converter: converter.percentConverter },
  arvonlisavero: { selector: '[name=arvonlisavero]', converter: converter.euroConverter },
  billed_kmreimbursementamount: { selector: '[name=billed_kmreimbursementamount]', converter: converter.euroConverter },
  billed_kmreimbursement: { selector: '[name=billed_kmreimbursement]' },
  billed_kmreimbursement_unitprice: {
    selector: '[name=billed_kmreimbursement_unitprice]',
    converter: converter.euroConverter,
  },
  billed_fulldaycompensationamount: {
    selector: '[name=billed_fulldaycompensationamount]',
    converter: converter.euroConverter,
  },
  billed_fulldaycompensationamount_unitprice: {
    selector: '[name=billed_fulldaycompensationamount_unitprice]',
    converter: converter.euroConverter,
  },
  billed_fulldaycompensation: { selector: '[name=billed_fulldaycompensation]' },
  billed_partialdaycompensationamount: {
    selector: '[name=billed_partialdaycompensationamount]',
    converter: converter.euroConverter,
  },
  billed_partialdaycompensation_unitprice: {
    selector: '[name=billed_partialdaycompensation_unitprice]',
    converter: converter.euroConverter,
  },
  billed_partialdaycompensation: { selector: '[name=billed_partialdaycompensation]' },
  billed_lunchcompensationamount: {
    selector: '[name=billed_lunchcompensationamount]',
    converter: converter.euroConverter,
  },
  billed_lunchcompensation_unitprice: {
    selector: '[name=billed_lunchcompensation_unitprice]',
    converter: converter.euroConverter,
  },
  billed_lunchcompensation: { selector: '[name=billed_lunchcompensation]' },
  billed_foreignAllowance: { selector: '[name=billed_foreignAllowance]', converter: converter.euroConverter },
  billed_hotelcost: { selector: '[name=billed_hotelcost]', converter: converter.euroConverter },
  billed_hotelcost_alv: { selector: '[name=billed_hotelcost_alv]', converter: converter.euroConverter },
  billed_hotelcost_total: { selector: '[name=billed_hotelcost_total]', converter: converter.euroConverter },
  billed_othercost: { selector: '[name=billed_othercost]', converter: converter.euroConverter },
  billed_othercost_alv: { selector: '[name=billed_othercost_alv]', converter: converter.euroConverter },
  billed_othercost_total: { selector: '[name=billed_othercost_total]', converter: converter.euroConverter },
  billed_travelcost: { selector: '[name=billed_travelcost]', converter: converter.euroConverter },
  billed_travelcost_alv: { selector: '[name=billed_travelcost_alv]', converter: converter.euroConverter },
  billed_travelcost_total: { selector: '[name=billed_travelcost_total]', converter: converter.euroConverter },
  billableAmountToBePaid: { selector: '[name=billableAmountToBePaid]', converter: converter.euroConverter },
  contracting: { selector: '[name=contracting]', converter: converter.euroConverter },
  advancePaymentSalary: { selector: '[name=advancePaymentSalary]' },
  groupLifeInsurance: { selector: '[name=groupLifeInsurance]', converter: converter.percentConverter },
  groupLifeInsuranceAmount: { selector: '[name=groupLifeInsuranceAmount]', converter: converter.euroConverter },
  professionCode: { selector: '[name=professionCode]' },
  oskForTax: {
    selector: '[name=oskForTax]',
    converter: converter.euroConverter,
  },

}

export default singleSalaryView
  
