import customerResult from 'templates/user/customers/customerResult.html'

var billableItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(customerResult)({
      items: serialized,
    })
  },
  initialize: function() {
  },
  tagName: 'tr',
})
export default billableItemView

