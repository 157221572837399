import Backbone from 'backbone'
import helpers from 'components/helpers'

var requiredField = function(person, field) {
  if (person && person.get(field)) {
    return false
  }
  return 'Pakollinen kenttä'
}
var personSettingsModel = Backbone.Model.extend({})

var personModel = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a = a || {}
    a.personSettings = new personSettingsModel(a.personSettings || {})
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.personSettings) {
      this.attributes.personSettings.parse(data.personSettings)
      this.attributes.personSettings.set(data.personSettings)
    }
    data.personSettings = this.attributes.personSettings
    return data
  },
  validate: function(attrs, options) {
  },
  validation: {
    firstName: function() {
      return requiredField(this.get('person'), 'firstName')
    },
    lastName: function() {
      return requiredField(this.get('person'), 'lastName')
    },
    username: function() {
      return requiredField(this.get('person'), 'username')
    },
    password: function() {
      return requiredField(this.get('person'), 'password')
    },
    sotu: function(str) {
      var person = this.get('person')
      if (!requiredField(person, 'sotu')) {
        return 'Pakollinen kenttä'
      }
      return helpers.isSotu(person.get('hetu'))
      return false
    },

  },
})
var personDtoModel = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a = a || {}
    a.person = new personModel(a.person || {})
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.person) {
      this.attributes.person.parse(data.person)
      this.attributes.person.set(data.person)
      data.person = this.attributes.person
    }
    return data
  },
  defaults: {
    opened: 'closed',
  },
  urlRoot: '/api/admin/people',
  validate: function(attrs, options) {
    var errors = []
    var sotu = attrs.person.get('sotu')
    var isSotu = helpers.isSotu(sotu)
    if (isSotu) {
      errors.push(isSotu)
    }
    if (!attrs.person.get('firstName')) {
      errors.push('Etunimi puuttuu')
    }
    if (!attrs.person.get('lastName')) {
      errors.push('Sukunimi puuttuu')
    }
    if (errors.length > 0) {
      return errors
    }

  },
  validation: {
    person: {
      required: true,
    },
    firstName: function() {
      return requiredField(this.get('person'), 'firstName')
    },
    lastName: function() {
      return requiredField(this.get('person'), 'lastName')
    },
    username: function() {
      return requiredField(this.get('person'), 'username')
    },
    password: function() {
      return requiredField(this.get('person'), 'password')
    },
    sotu: function(str) {
      var person = this.get('person')
      if (!requiredField(person, 'sotu')) {
        return 'Pakollinen kenttä'
      }
      return helpers.isSotu(person.get('hetu'))
      return false
    },

  },

})
export default personDtoModel


