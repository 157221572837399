import confirm from './confirm.html'
import eulaView from './eulaView'
import notFoundView from './notFoundView'
import confirmedView from './confirmedView'
import modelBinder from 'backbone.modelbinder'

var confirmation = Backbone.Model.extend({
  url: '/api/registration/confirm',
})

export default Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(
      confirm,
      {
        items: serialized,
      },
      { items: serialized },
    )
  },
  className: 'row row-fluid',
  regions: {
    confirmRegion: '.confirm',
  },
  initialize: function(params) {
    this.model = new confirmation({ registrationToken: params.token })
  },
  onRender: function() {
    var self = this
    this.model
      .fetch({
        data: $.param({
          token: this.model.get('registrationToken'),
        }),
      })
      .success(function() {
        self.getRegion('confirmRegion').show(new eulaView())
      })
      .error(function() {
        self.getRegion('confirmRegion').show(new notFoundView())
      })
  },
  events: {
    'click .agreeEula': 'agreeEula',
  },
  agreeEula: function() {
    var self = this
    this.model.save().success(function() {
      self.getRegion('confirmRegion').show(new confirmedView())
    })
  },
})

