import Appregistry from 'libs/appregistry/appregistry'
import relationalSharedCustomerModel from 'models/relational/relationalSharedCustomerModel'
import modelBinder from 'backbone.modelbinder'
import customerTemplate from 'templates/admin/marionette/sharedcustomers/customer-save.html'

var billableItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(customerTemplate)({
      items: serialized,
      admin: Appregistry.models.person.get('role') == 'ROLE_ADMIN',
    })
  },
  initialize: function() {
    this.modelBinder = new modelBinder()
    if (!this.model) {
      this.model = new relationalSharedCustomerModel()
      this.model.urlRoot = 'api/sharedcustomers'
    }
    Backbone.Validation.bind(this)
  },
  className: 'section-content row new-customer-container',
  events: {
    'click .saveCustomer': 'saveNewCustomer',
    'click .cancelSave': 'cancelSave',
  },
  modelEvents: {},
  saveNewCustomer: function() {
    var self = this
    if (this.model.isValid(true)) {
      this.model.save({ wait: true })
        .success(function(response) {
          self.saveFn ? self.saveFn(response) : $.noop
          $.jGrowl('Asiakasta luotu', { life: 10000 })
          self.destroy()
        })
        .error(function(response) {
          $.jGrowl('Asiakasta ei voitu lisätä', { life: 10000 })
        })
    }
  },
  cancelSave: function() {
    this.destroy()
  },
  modelChanged: function() {
  },
  onDestroy: function() {
    this.modelBinder.unbind()
    Backbone.Validation.unbind(this)
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    this.$el.find('.glyphicon-question-sign').popover(Appregistry.popoverOpts)
    return this
  },
  bindModel: function() {
  },

})

export default billableItemView

