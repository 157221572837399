import adminShardCustomersCollection from 'collections/admin/marionette/adminShardCustomersCollection'
import sharedCustomersComposite from 'views/admin/marionette/sharedcustomers/sharedCustomersComposite'
import sharedCustomerEditView from 'views/admin/marionette/sharedcustomers/sharedCustomerEditView'
import newCustomerView from 'views/admin/marionette/sharedcustomers/newCustomerView'
import sharedCustomersLayout from 'templates/admin/marionette/sharedcustomers/sharedcustomers-container.html'

var sharedCustomers = Backbone.Marionette.View.extend({
  childViewContainer: '#bills-container',
  initialize: function(options) {
    this.collection = new adminShardCustomersCollection()
    this.sharedCustomersCompositeView = new sharedCustomersComposite({ collection: this.collection })
    this.collection.fetch({ wait: true })
      .then((a) => {
        if (options.id) {
          this.openCustomerEdit(parseInt(options.id))
        }
      })
  },
  regions: {
    newCustomerRegion: '.new-customer',
    editCustomerRegion: '.editable-customer',
    customersRegion: '.shared-customers',
  },
  template: function(serialized) {
    return _.template(sharedCustomersLayout, {
      items: serialized,
    }, { items: serialized })
  },
  events: {
    'click .createNewCustomer': 'createCustomer',
    'click .edit-customer': 'editCustomer',
    'click .delete-customer': 'deleteCustomer',
  },
  openCustomerEdit: function(id) {
    var model = this.collection.get(id)
    var editView = new sharedCustomerEditView({ model: model })
    this.getRegion('editCustomerRegion').show(editView)
    $(document).scrollTop(0)
  },
  editCustomer: function(event) {
    $('.ajax-loader').show()
    this.openCustomerEdit(event.currentTarget.parentElement.id)
  },
  deleteCustomer: function(event) {
    let isExecuted = confirm('Oletko varma poistosta?')
    if (isExecuted) {
      this.getRegion('editCustomerRegion').empty()
      var model = this.collection.get(event.currentTarget.parentElement.id)
      var self = this
      model.destroy({
        wait: true,
        dataType: 'text',
        success: function(resp) {
          $.jGrowl('Asiakas poistettu', { life: 30000 })
          self.collection.fetch()
        },
      })
    }

  },
  onRender: function() {
    this.getRegion('customersRegion').show(this.sharedCustomersCompositeView)
  },
  createCustomer: function() {
    var newModel = new this.collection.model()
    newModel.urlRoot = '/api/admin/sharedcustomer'
    var editView = new newCustomerView(
      {
        model: newModel,
        collection: this.collection,
      })
    editView.saveFn = function() {
      this.collection.add(newModel)
    }
    this.getRegion('editCustomerRegion').show(editView)
  },
})
export default sharedCustomers

