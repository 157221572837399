import React, { useEffect, useState } from 'react'
import TesList from './tes-list'
import axios from 'axios'

export const TesContainer = ({ token }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    void fetchData()
  }, [])
  const fetchData = async () => {
    try {
      axios
        .get('api/admin/tes', {
          headers: {
            authtoken: token,
          },
        })
        .then((reply) => {
          setData(reply.data)
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const updateData = async (tes) => {
    try {
      setLoading(true)
      axios
        .put(`api/admin/tes/${tes.id}`, tes, {
          headers: {
            authtoken: token,
          },
        })
        .then((reply) => {
          const updatedTes = reply.data
          const newData = [
            ...data.map((a) => {
              if (a.id === updatedTes.id) {
                return updatedTes
              }
              return a
            }),
          ]
          setLoading(false)
          setData(newData)
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const deleteTes = async (tes) => {
    try {
      axios
        .delete(`api/admin/tes/${tes.id}`, {
          headers: {
            authtoken: token,
          },
        })
        .then((reply) => {
          fetchData()
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const addNewTes = async () => {
    try {
      return axios
        .post(
          `api/admin/tes`,
          {},
          {
            headers: {
              authtoken: token,
            },
          }
        )
        .then((reply) => {
          setData([...data, reply.data])
          return reply.data
        })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  return (
    <div>
      <TesList
        loading={loading}
        data={data}
        token={token}
        updateData={updateData}
        addNewTes={addNewTes}
        deleteTes={deleteTes}
      />
    </div>
  )
}
