import Appregistry from 'libs/appregistry/appregistry'
import peopleItemTemplate from 'views/admin/marionette/people/editPerson.html'
import converter from 'libs/converter'

var loginModel = Backbone.Model.extend({
  urlRoot: '/api/admin/people/loginurl',
})

var taxPercent = Backbone.Model.extend({
  urlRoot: 'api/admin/tax-percent',
})

var peopleItemView = Backbone.Marionette.View.extend({
  template: function (serialized) {
    return _.template(peopleItemTemplate)({
      items: serialized,
      tes: Appregistry.authModel.tesCollection,
    })
  },
  tagName: 'div',
  className: 'row-fluid',
  initialize: function (args) {
    this.personBinder = new Backbone.ModelBinder()
    this.personSettingsBinder = new Backbone.ModelBinder()
    this.loginM = new loginModel({ id: args.model.id })
  },
  events: {
    'click .savePerson': 'savePerson',
    'click .closeNow': 'closeNow',
    'click .updateTaxPercent': 'updateTaxPercent',
  },
  onDestroy: function () {
    this.personBinder.unbind()
    this.personSettingsBinder.unbind()
    this.picker.stop()
  },
  onRender: function () {
    this.personBinder.bind(this.model, this.el, personBindings)
    this.personSettingsBinder.bind(this.model.get('personSettings'), this.el, personSettingsBindings)
    this.picker = this.$el.find('.date-picker').pickadate(window.helpers.pickadateOptions)
    var fn = function (a) {
      this.$el.find('#loginWithPerson').attr('href', a.loginurl)
      this.$el.find('#loginWithPerson').removeAttr('hidden')
    }.bind(this)
    this.loginM.fetch().then(fn, function (e) {
      console.log('error?', e)
    })
    return this
  },
  updateTaxPercent: function () {
    var self = this
    this.model
      .save({}, { url: `api/admin/tax-percent/${this.model.id}` })
      .success(function () {
        self.render()
      })
      .error(function (response) {
        $.jGrowl('Ei voitu päivittää tietoja', { life: 5000 })
      })
  },
  onDetach: function () {
    var elements = this.$el.find('select')
    _.each(elements, function (elem) {
      var s = $(elem)
      if (s.data('select2')) {
        s.select2('destroy')
      }
    })
    this.picker.stop()
  },
  savePerson: function () {
    var self = this
    if (this.personDTO.isValid()) {
      this.personDTO
        .save()
        .success(function () {
          self.personDTO.trigger('changedSuccess')
          self.destroy()
        })
        .error(function (response) {
          $.jGrowl('Ei voitu tallentaa, tarkista tiedot', { life: 5000 })
        })
    } else {
      $.jGrowl(this.personDTO.validationError.join(', '), { life: 1000 })

      console.log('was not valid')
    }
  },
  closeNow: function () {
    this.destroy()
  },
})

var personBindings = {
  firstName: '[name=firstName]',
  lastName: '[name=lastName]',
  phone: '[name=phone]',
  email: '[name=email]',
  role: '[name=role]',
  marketingname: '[name=marketingname]',
  address: '[name=address]',
  postalcode: '[name=postalcode]',
  iban: '[name=iban]',
  bic: '[name=bic]',
  username: '[name=username]',
  sotu: '[name=sotu]',
  city: '[name=city]',
  profession: '[name=profession]',
  professionCode: '[name=professionCode]',
  tes: { selector: '[name=tes]', converter: converter.tesConverter },
  promoCode: '[name=promoCode]',
}
var personSettingsBindings = {
  disableAddressSearch: '[name=disableAddressSearch]',
  useHourCalculation: '[name=useHourCalculation]',
  taxpercent: { selector: '[name=taxpercent]', converter: converter.percentConverter },
  extraTaxPercent: { selector: '[name=extraTaxPercent]', converter: converter.percentConverter },
  salaryMax: { selector: '[name=salaryMax]', converter: converter.euroConverter },
  minimumhourlywage: { selector: '[name=minimumhourlywage]', converter: converter.euroConverter },
  osk: { selector: '[name=osk]', converter: converter.percentConverter },
  holidaybonus: { selector: '[name=holidaybonus]', converter: converter.percentConverter },
  accidentInsurance: { selector: '[name=accidentInsurance]', converter: converter.percentConverter },
  tyEl: { selector: '[name=tyEl]', converter: converter.percentConverter },
  yelsum: { selector: '[name=yelsum]', converter: converter.euroConverter },
  taxCardType: '[name=taxCardType]',
  note: '[name=note]',
  distraint: '[name=distraint]',
  primaryWork: '[name=primaryWork]',
  workerType: '[name=workerType]',
  distraintType: '[name=distraintType]',
  person_sotu: { selector: '[name=person_sotu]', converter: converter.percentConverter },
  workerTYEL: { selector: '[name=workerTYEL]', converter: converter.percentConverter },
  employerTYEL: { selector: '[name=employerTYEL]', converter: converter.percentConverter },
  protectedPortion: { selector: '[name=protectedPortion]', converter: converter.euroConverter },
  distraintText: '[name=distraintText]',
  distraintSince: '[name=distraintSince]',
  useServiceChargeMax: '[name=useServiceChargeMax]',
  serviceChargeMax: { selector: '[name=serviceChargeMax]', converter: converter.euroConverter },
  taxpercentValidFrom: { selector: '[name=taxpercentValidFrom]' },
  taxpercentValidUntil: { selector: '[name=taxpercentValidUntil]' },
  distraintReference: { selector: '[name=distraintReference]' },
  entrepreneurProvision: { selector: '[name=entrepreneurProvision]', converter: converter.percentConverter },
  useExtraTaxPercent: '[name=useExtraTaxPercent]',
}

export default peopleItemView
