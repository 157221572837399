import TemplateModel from 'models/templateModel'
import Appregistry from 'libs/appregistry/appregistry'

var templateCollectoin = Backbone.Collection.extend({
  model: TemplateModel,
  url: function() {
    return 'api/person/' + this.person.id + '/templates'
  },
  initialize: function(options) {
    if (options != null && options.person !== undefined) {
      this.person = options.person
    } else {
      this.person = Appregistry.models.person
    }
  },
})

export default templateCollectoin

