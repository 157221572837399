import Appregistry from 'libs/appregistry/appregistry'
import bankCollection from 'views/admin/marionette/bank/bankCollection'
import bankLayoutHtml from 'views/admin/marionette/bank/bankLayout.html'
import bankModel from 'views/admin/marionette/bank/bankModel'
import 'jsgrid'

var bankLayout = Backbone.Marionette.View.extend({
  initialize: function () {
    this.collection = new bankCollection()
    this.collection.fetch()
  },
  regions: {
    //    peopleRegion: '.transaction-container',
  },
  template: function (serialized) {
    return _.template(bankLayoutHtml)({
      items: serialized.items,
    })
  },
  events: {},
  onRender: function () {
    var self = this
    var ell = this.$el.find('#ninjou-status-jsgrid')
    ell.jsGrid({
      width: '100%',
      autoload: true,
      paging: true,
      pageLoading: true,
      pageSize: 50,
      pageIndex: 1,

      controller: {
        loadData: function (filter) {
          var startIndex = (filter.pageIndex - 1) * filter.pageSize
          var promise = new Promise(function (resolve, reject) {
            // We call resolve(...) when what we were doing asynchronously was successful, and reject(...) when it failed.
            // In this example, we use setTimeout(...) to simulate async code.
            // In reality, you will probably be using something like XHR or an HTML5 API.
            self.collection.getPage(filter.pageIndex - 1).done(function (data) {
              resolve({
                itemsCount: data.itemsCount,
                data: data.data,
              })
            })
          })
          return promise
        },
      },

      fields: [
        {
          name: 'id',
          type: 'text',
          valueField: 'id',
          width: 30,
        },
        {
          type: 'text',
          name: 'personName',
          width: 120,
          editButton: false,
          self: self,
          deleteButton: false,
          itemTemplate: function (value, item) {
            var $result = jsGrid.fields.control.prototype.itemTemplate.apply(this, arguments)
            var $customLink = $('<a></a>')
              .text(item.personName)
              .click(
                function (e) {
                  e.stopPropagation()
                  var model = Backbone.Model.extend({
                    urlRoot: 'api/admin/bank/' + item.id + '/xml',
                  })
                  var model1 = new model()
                  model1.fetch()
                }.bind(self)
              )

            return $('<div>').append($customLink)
          },
        },
        {
          name: 'message',
          type: 'text',
          width: 120,
        },
        {
          title: 'Maksupäivä',
          name: 'payDate',
          type: 'text',
          width: 80,
        },
        {
          name: 'amount',
          title: 'amount',
          type: 'text',
          width: 40,
        },
        {
          name: 'iban',
          type: 'text',
          width: 80,
        },
        {
          name: 'bic',
          title: 'bic',
          type: 'text',
          width: 60,
        },
        {
          name: 'created',
          title: 'created',
          type: 'text',
          width: 60,
        },
        {
          name: 'salaryId',
          type: 'text',
          width: 50,
        },
        {
          name: 'state',
          type: 'text',
          width: 40,
        },
        {
          title: 'Viitenumero',
          name: 'referenceNumber',
          type: 'text',
          width: 60,
        },
        {
          name: 'responseCode',
          type: 'text',
          width: 80,
        },
        {
          name: 'timestamp',
          type: 'text',
          width: 80,
        },
        {
          type: 'control',
          width: 60,
          editButton: false,
          self: self,
          deleteButton: false,
          itemTemplate: function (value, item) {
            if (item.state === 'NEW') {
              var $customEditButton = $('<button>Hyväksy</button>')
                .attr({ class: 'customGridEditbutton jsgrid-button jsgrid-edit-button btn btn-success' })
                .click(
                  function (e) {
                    e.stopPropagation()
                    var r = confirm('Lähetetäänkö maksettavaksi?')
                    if (r == true) {
                      var model = new bankModel(item)
                      model.urlRoot = '/api/admin/bank'
                      model.set('state', 'APPROVED')
                      var self = this
                      model.save(null, {
                        wait: true,
                        success: function (item) {
                          $('#ninjou-status-jsgrid').jsGrid('updateItem', item)
                          self.render()
                        },
                      })
                    }
                  }.bind(self)
                )
              return $('<div>').append($customEditButton)
            }
          },
        },
        {
          type: 'control',
          width: 60,
          editButton: false,
          deleteButton: false,
          itemTemplate: function (value, item) {
            if (item.state === 'NEW') {
              var $customDeleteButton = $('<button>hylkää</button>')
                .attr({ class: 'customGridDeletebutton jsgrid-button jsgrid-delete-button btn btn-warning' })
                .click(
                  function (e) {
                    e.stopPropagation()
                    e.stopPropagation()
                    var r = confirm('Hylätäänkö maksu?')
                    if (r == true) {
                      var model = new bankModel(item)
                      model.urlRoot = '/api/admin/bank'
                      model.set('state', 'REJECTED')
                      var self = this
                      model.save(null, {
                        wait: true,
                        success: function (item) {
                          $('#ninjou-status-jsgrid').jsGrid('updateItem', item)
                          self.render()
                        },
                      })
                    }
                  }.bind(self)
                )
              return $('<div>').append($customDeleteButton)
            }
            if (item.state == 'APPROVED') {
              var $customDeleteButton = $('<button>Peruuta</button>')
                .attr({ class: 'customGridDeletebutton jsgrid-button jsgrid-delete-button btn btn-danger' })
                .click(
                  function (e) {
                    e.stopPropagation()
                    e.stopPropagation()
                    var r = confirm('Hylätäänkö maksu?')
                    if (r == true) {
                      var model = new bankModel(item)
                      model.urlRoot = '/api/admin/bank'
                      model.set('state', 'CANCELLED')
                      var self = this
                      model.save(null, {
                        wait: true,
                        success: function (item) {
                          $('#ninjou-status-jsgrid').jsGrid('updateItem', item)
                          self.render()
                        },
                      })
                    }
                  }.bind(self)
                )
              return $('<div>').append($customDeleteButton)
            }
          },
        },
      ],
    })
  },
})
export default bankLayout
