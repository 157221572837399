import remunerationModel from 'models/admin/remuneration/remunerationModel'

var adminBillsCollection = Backbone.Collection.extend({
  model: remunerationModel,
  url: '/api/admin/remunerations',
  initialize: function() {
  },
})

export default adminBillsCollection

