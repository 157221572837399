import 'backbone-relational'

var employercostModel = Backbone.Model.extend({})

var employerCostsCollection = Backbone.Collection.extend({
  model: employercostModel,
  initialize: function() {
  },
  url: function() {
    return '/api/admin/employercost'
  },
})

var publicSettings = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a = a || {}
    a.employercosts = new employerCostsCollection(a.employercosts || {})
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.employercosts) {
      this.attributes.employercosts.reset(data.employercosts || [])
      data.employercosts = this.attributes.employercosts
    }
    return data
  },
  url: '/api/publicsettings',
  defaults: {},
})

var settingsDtoModel = Backbone.Model.extend({
  constructor: function(a, b, c) {
    a = a || {}
    a.publicSettings = new publicSettings(a.publicSettings || {})
    Backbone.Model.apply(this, arguments)
  },
  parse: function(data, options) {
    data = data || {}
    if (this.attributes.publicSettings) {
      this.attributes.publicSettings.parse(data.publicSettings)
      this.attributes.publicSettings.set(data.publicSettings)
      data.publicSettings = this.attributes.publicSettings
    }
    return data
  },
  url: function() {
    return '/api/admin/settings'
  },
})
export default settingsDtoModel

