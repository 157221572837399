import Backbone from 'backbone'
import moment from 'moment'

var billBillableitemCollection = Backbone.Collection.extend({
  url: '/api/billableitem',
  model: billableitemModel,
  initialize: function() {

  },
  notTaken: function() {
    return _(this.filter(function(data) {
      return Boolean(data.get('taken')) == false
    }))
  },
  taken: function() {
    return _(this.filter(function(data) {
      return Boolean(data.get('taken')) == true
    }))
  },
})

var billableitemModel = Backbone.Model.extend({
  defaults: {
    opened: 'closed',
    kilometers: 0,
    vehicle: 0,
    state: 'OPEN',
  },
  initialize: function() {
  },
})


var singlesalaryModel = Backbone.Model.extend({
  urlRoot: '/api/admin/salarylist',
  initialize: function() {
  },
})





