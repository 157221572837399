import Backbone from 'backbone'

var tesDTO = Backbone.Model.extend({})

var tesDTOCollection = Backbone.Collection.extend({
  url: function() {
    return 'api/admin/tesdtos'
  },
  model: tesDTO,
})
export default tesDTOCollection

