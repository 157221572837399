import receiptView from './receiptView'
import receiptCompositeTemplate from './receiptCompositeTemplate.html'

export default Backbone.Marionette.CompositeView.extend({
  template: receiptCompositeTemplate,
  childView: receiptView,
  childViewContainer: 'tbody',
  onRender: function() {
    this.bindFileUpload()
  },
  bindFileUpload: function() {
    var id = this.options.billableItemId
    var self = this
    var progress = self.$el.find('.progress')
    var progressBar = self.$el.find('.progress .bar')
    this.$el.find('.receiptButton').fileupload({
      dropZone: $(this),
      dataType: 'json',
      url: '/api/billableitem/' + id + '/receipt',
      disableImageResize: false,
      imageMaxHeight: 800,
      add: function(e, data) {
        progress.show()
        progress.css('display', 'block')
        $.blueimp.fileupload.prototype.options.add.call(this, e, data)
      },
      progressall: function(e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10)
        progressBar.css('width', progress + '%')
      },
      done: function(e, data) {
        progress.css('display', 'none')
      },
    })
      .bind('fileuploaddone', function(e, data) {
        $.jGrowl('Kuitti tallennettu', { life: 2000 })
        _.each(data.result, function(model) {
          self.collection.add(model, { validate: true })
        })
      })
      .bind('fileuploadfail', function(e, data) {
        $.jGrowl('Kuittia ei voitu tallentaa', { life: 8000 })
        progress.css('display', 'none')
      })


  },
})

