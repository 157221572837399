import Backbone from 'backbone'

var calculationModel = Backbone.Model.extend({
  urlRoot: '/api/calc',
  defaults: {
    alv: 0,
    taxPercent: 0,
    price: 0,
    employercoststotal: 0,
    employeeCostTotal: 0,
    netto: 0,
    oskamount: 0,
    alvamount: 0,
  },
  initialize: function() {
  },
  parse: function(response) {
    return response
  },
})
export default calculationModel


