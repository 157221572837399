import remunerationEditItemView from 'views/admin/remuneration/remunerationEditItemView'
import remunerationCompositeView from 'views/admin/remuneration/remunerationCompositeView'
import remunerationHtml from 'templates/admin/remuneration/remuneration-layout.html'

var remunerationLayout = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(remunerationHtml)({
      items: serialized,
    })
  },
  tagName: 'div',
  className: 'row-fluid',
  regions: {
    remuneration: '.remuneration',
    remunerationList: '.remunerationTable',
  },
  initialize: function() {
  },
  events: {
    'click .addRemuneration': 'addRemuneration',
  },
  collectionEvents: {
    'editItem': 'editItem',
  },
  onRender: function() {
    this.getRegion('remunerationList').show(new remunerationCompositeView({ collection: this.collection }))
  },
  addRemuneration: function() {
    this.getRegion('remuneration').show(new remunerationEditItemView({ collection: this.collection }))
  },
  editItem: function(model) {

    this.getRegion('remuneration').show(new remunerationEditItemView({ model: model }))
  },

})


export default remunerationLayout
  
