import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'

const GoCardless = ({ token }) => {
  const [institutions, setInstitutions] = useState([])
  const [settings, setSettings] = useState(null)
  const [requisition, setRequisition] = useState(null)

  // Initialize the form with default values
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      gc_redirect_url: '',
      reference: '',
      currentGoCardlessConnection: {
        gocardlessAgreementDone: '',
        gocardlessAgreementValidUntil: '',
        gocardlessAgreement: '',
      },
    },
  })

  // Function to fetch institutions
  const fetchInstitutions = () => {
    axios
      .get(`api/admin/psd2/institutions`, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        console.log('Institutions reply', data)
        setInstitutions(data)
      })
      .catch((error) => {
        console.error('Error fetching institutions:', error)
      })
  }

  // Fetch settings on mount
  useEffect(() => {
    axios
      .get(`api/admin/settings/v2`, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        console.log('Settings reply', data)
        setSettings(data)
        // Update form values with the fetched settings
        reset(data)
      })
      .catch((error) => {
        console.error('Error fetching settings:', error)
      })
  }, [token, reset])

  // Handle form submission
  const onSubmit = (formData) => {
    console.log('Form submitted:', formData)
    axios
      .put(`api/admin/settings/v2`, formData, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        console.log('Settings updated reply', data)
        setSettings(data)
      })
      .catch((error) => {
        console.error('Error updating settings:', error)
      })
  }

  const connectToBank = (item) => {
    console.log('connectToBank', item)
    axios
      .post(`api/admin/psd2/connect`, item, {
        headers: { authtoken: token },
      })
      .then(({ data }) => {
        console.log('Settings updated reply', data)
        setSettings(data)
      })
      .catch((error) => {
        console.error('Error updating settings:', error)
      })
  }

  return (
    <div className="p-4">
      {/* Flex container to layout Settings form (left) and the connection card (right) */}

      <h2 className="text-xl font-bold mb-2">GoCardless asetukset</h2>
      <div className="flex flex-col md:flex-row md:space-x-8">
        {/* Left side: Settings Form */}
        <div className="md:w-1/2">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block mb-1 font-medium" htmlFor="gc_redirect_url">
                Redirect URL, palataan pankkitunnistautumisen jälkeen
              </label>
              <input
                id="gc_redirect_url"
                type="text"
                {...register('gc_redirect_url')}
                className="border p-2 w-full"
              />
            </div>
            <div>
              <label className="block mb-1 font-medium" htmlFor="reference">
                Reference, tällä kontrolloidaan että on vain yksi sopimus
              </label>
              <input
                id="reference"
                type="text"
                {...register('reference')}
                className="border p-2 w-full"
              />
            </div>

            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Tallenna asetukset
            </button>
          </form>
        </div>

        {/* Right side: Current GoCardless Connection Card */}
        <div className="md:w-1/2 mt-8 md:mt-0">
          {settings && settings.currentGoCardlessConnection && settings.currentGoCardlessConnection.logo ? (
            <div className="max-w-sm rounded overflow-hidden shadow-lg border border-gray-200 p-4 mx-auto">
              <img
                className="w-full h-32 object-contain"
                src={settings.currentGoCardlessConnection.logo}
                alt={`${settings.currentGoCardlessConnection.name} logo`}
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{settings.currentGoCardlessConnection.name}</div>
                <p className="text-gray-700 text-base">
                  <strong>Agreement Done:</strong> {settings.currentGoCardlessConnection.gocardlessAgreementDone}
                </p>
                <p className="text-gray-700 text-base">
                  <strong>Valid Until:</strong> {settings.currentGoCardlessConnection.gocardlessAgreementValidUntil}
                </p>
                <p className="text-gray-700 text-base">
                  <strong>BIC:</strong> {settings.currentGoCardlessConnection.bic}
                </p>
                <p className="text-gray-700 text-base">
                  <strong>Bank ID:</strong> {settings.currentGoCardlessConnection.bankId}
                </p>
                <p className="text-gray-700 text-base">
                  <strong>Reference:</strong> {settings.currentGoCardlessConnection.reference}
                </p>
                <p className="text-gray-700 text-base">
                  <strong>State:</strong> {settings.currentGoCardlessConnection.state}
                </p>
              </div>
              { settings.currentGoCardlessConnection.state !== "CONNECTED" &&
              <div className="px-6 py-4">
                <a
                  href={settings.currentGoCardlessConnection.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-500 hover:bg-blue-700 text-white text-sm px-4 py-2 rounded"
                >
                  Liitä pankkiin
                </a>
              </div>
              }
            </div>
          ) : (
            <div className="text-center text-gray-500">
              No GoCardless connection available.
            </div>
          )}
        </div>
      </div>

      {/* Button to fetch institutions */}
      <div className="mb-4 flex mt-8">
        <button
          onClick={fetchInstitutions}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Lataa pankin ja tee uusi sopimius
        </button>
      </div>

      {/* Render institutions table if available */}
      {institutions.length > 0 && (
        <div>
          <h1 className="text-2xl font-bold mb-4 text-center">Institutions Info</h1>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 mb-4">
              <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2">Logo</th>
                <th className="px-4 py-2">ID</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">BIC</th>
                <th className="px-4 py-2">Transaction Total Days</th>
                <th className="px-4 py-2">Max Access Valid Days</th>
                <th className="px-4 py-2">Countries</th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
              {institutions.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-4 py-2">
                    <a
                      onClick={() => connectToBank(item)}
                      className="cursor-pointer text-blue-500 hover:underline"
                    >
                      Yhdistä pankkiin
                    </a>
                  </td>
                  <td className="px-4 py-2">
                    <img src={item.logo} alt={`${item.name} logo`} className="h-10" />
                  </td>
                  <td className="px-4 py-2">{item.id}</td>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2">{item.bic}</td>
                  <td className="px-4 py-2">{item.transaction_total_days}</td>
                  <td className="px-4 py-2">{item.max_access_valid_for_days}</td>
                  <td className="px-4 py-2">{item.countries.join(', ')}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default GoCardless
