import tesModel from 'models/admin/tes/tesModel'

var tesCollection = Backbone.Collection.extend({
  url: function() {
    return 'api/admin/tes'
  },
  model: tesModel,
})
export default tesCollection

