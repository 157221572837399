import Appregistry from 'libs/appregistry/appregistry'
import itemTemplate from 'templates/admin/salaries/salary-item.html'

var salaryListItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(itemTemplate)({
      items: serialized,
      authtoken: Appregistry.models.person.get('authtoken'),
    })
  },
  initialize: function() {
  },
  tagName: 'tr',
  className: function() {
    var person = this.model.get('person')
    if (person && person.distraint) {
      return 'distraint'
    }
    return ''
  },
  modelEvents: {
    change: 'render',
  },
  modelChanged: function() {
  },
  onDestroy: function() {
  },
  onRender: function() {
    return this
  },
  events: {
    'click .deleteSalary': 'deleteSalary',
    'click .editSalary': 'editSalary',
    'click .cleanState': 'cleanSalary',
    'click .sendToTuloveroSalary': 'sendToTulovero',
  },
  sendToTulovero: function() {
    if (confirm('Lähetetäänkö tuloveroon?')) {
      var id = this.model.id
      this.model.save(
        {},
        {
          url: '/api/admin/salarylist/' + id + '/tulovero',
          wait: true,
        },
      )
    }
  },
  deleteSalary: function() {
    if (confirm('poistetaanko palkka?')) {
      this.model.destroy()
      this.destroy()
    }
  },
  cleanSalary: function() {
    if (confirm('Tyhjennetäänkö?')) {
      var id = this.model.id
      this.model.save(
        {
          wagereported: null,
        },
        {
          url: '/api/admin/salarylist/' + id + '/clean-state',
          wait: true,
        },
      )
    }
  },
  editSalary: function() {
    Appregistry.global_event_obj.trigger('editItem', this.model.id)
    //this.model.collection.trigger("editItem", this.model.id)
  },
})

export default salaryListItemView

