import Backbone from 'backbone'
import reportAlvRow from 'backbone-relational'

var alvPrice = Backbone.Model.extend({})

var monthlyReport = Backbone.Model.extend({
  defaults: {
    opened: 'closed',
  },
  urlRoot: '/api/admin/report',
  relations: [{
    type: 'HasMany',
    key: 'alvPrice',
    relatedModel: alvPrice,
  }],
})
export default monthlyReport


