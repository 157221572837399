import calculationTemplate from 'templates/calculation/calculation.html'
import calculationModel from 'models/calculationModel'
import converter from 'libs/converter'

var templateView = Backbone.Marionette.View.extend({
  initialize: function() {
    this.model = new calculationModel()
    this._modelBinder = new Backbone.ModelBinder()
  },
  events: {
    'click .calculate-salary': 'calculateSalary',
  },
  onDestroy: function() {
    this._modelBinder.unbind()
  },
  calculateSalary: function(e) {
    e.preventDefault()
    var self = this
    this.model.save(null, {
      success: function(item) {
        self.render()
      },
    })
  },
  setContent: function() {
    var bindings = {
      taxPercent: { selector: '[name=taxPercent]', converter: converter.percentConverter },
      alv: { selector: '[name=alv]' },
      price: { selector: '[name=price]', converter: converter.euroConverter },
      salary: { selector: '[name=salary]', converter: converter.euroConverter },
      totalBilling: { selector: '[name=totalBilling]', converter: converter.euroConverter },
      alvamount: { selector: '[name=alvamount]', converter: converter.euroConverter },
      costs: { selector: '[name=costs]', converter: converter.euroConverter },
      taxamount: { selector: '[name=taxamount]', converter: converter.euroConverter },

    }
    this._modelBinder.bind(this.model, this.el, bindings)
  },
  render: function() {
    this.$el.html(calculationTemplate)
    this.setContent()
    return this
  },
})
export default templateView

