import checkEmail from 'templates/password/checkEmail.html'

var registerView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(checkEmail, {
      items: serialized,
    }, { items: serialized })
  },
  className: 'row row-fluid',
})
export default registerView

