import React, { Fragment, useState, useEffect } from 'react'
import { numToFixed } from './bills'

export default function BillCard({ bill }) {
  const advances = bill?.advancePayments?.reduce((a, b) => {
    return a + b.amount
  },0)
  return (
    <div className="overflow-hidden bg-gray-50 shadow sm:rounded-lg">
      <div className="px-4 py-4 sm:px-4">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Lasku</h3>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Numero</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{bill?.id}</dd>
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">alv</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{numToFixed(bill.alv)} %</dd>
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Hinta</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{numToFixed(bill.totalpriceWithAlv)} €</dd>
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Viite</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{bill?.reference}</dd>
          </div>
          { advances > 0 && (
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Ennakkopyynnöt</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{numToFixed(advances)} €</dd>
          </div>
          )}
        </dl>
      </div>
    </div>
  )
}
