import Appregistry from 'libs/appregistry/appregistry'
import monthlyReport from 'models/admin/monthlyReport'
import reportView from './monthly-report.html'
import alvHtml from './alv-item.html'
import converter from 'libs/converter'
import moment from 'moment'


var alvItem = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(alvHtml)({
      items: serialized,
    })
  },
})

var alvCollection = Backbone.Collection.extend({})
var colView = Backbone.Marionette.CollectionView.extend({
  childView: alvItem,
})

var adminReporteView = Backbone.Marionette.View.extend({
  initialize: function() {
    this.model = new monthlyReport()
    this.modelBinder = new Backbone.ModelBinder()
  },
  template: function(serialized) {
    return _.template(reportView, {
      people: Appregistry.peopleCollection,
      items: serialized,
    }, { items: serialized })
  },
  events: {
    'click #calculate-report': 'fetchReport',
    'click #download-receipts': 'downloadReceipts',
  },
  onRender: function() {
    console.log('$(this.el)', $(this.el))
    $(this.el).find('[name=from]').val(moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY'))
    $(this.el).find('[name=to]').val(moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY'))
    $(this.el).find('[name=state]').val('PAID')
    this.modelBinder.bind(this.model, this.el, reportBindigs)
    $(this.el).find('.date-picker').pickadate(window.helpers.pickadateOptions)
    return this
  },
  regions: {
   // alvRegion: '.alv-rows-container',
  },
  downloadReceipts: function() {
  },
  fetchReport: function() {
    var self = this
    var from = $(this.el).find('[name=from]').val()
    var to = $(this.el).find('[name=to]').val()
    if (from && to) {
      this.model.fetch({
        data: $.param({
          from: from,
          to: to,
          state: $(this.el).find('[name=state]').val(),
        }),
        success: function(model) {
          var nCol = new alvCollection(model.get('alvPrice'))
          var v = new colView({ collection: nCol })
          // self.showChildView('alvRegion', v)
        },
      })

      var tyelhref = '/api/admin/reports/tyel?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to
      var link = $('<a>TyEl ilmoutus tiedosto</a>')
      link.attr('href', tyelhref)
      this.$el.find('.tyel-link').html(link)

      var receiptLink = '/api/admin/reports/receipts?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to
      var receiptDom = $('<a>Lataa kuitit palkoista, joita ei ole laskutettu asiakkailta</a>')
      receiptDom.attr('href', receiptLink)
      this.$el.find('.receipt-container').html(receiptDom)

      var AllReceiptLink = '/api/admin/reports/receipts/all?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to
      var AllReceiptDom = $('<a>Lataa kuitit kaikista maksetuista palkoista</a>')
      AllReceiptDom.attr('href', AllReceiptLink)
      this.$el.find('.all-receipt-container').html(AllReceiptDom)

      var allTaxfree = '/api/admin/reports/travel-expence/tax-free?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to
      var allTaxfreetDom = $('<a>Lataa verottomat matkalaskut</a>')
      allTaxfreetDom.attr('href', allTaxfree)
      this.$el.find('.taxfree-container').html(allTaxfreetDom)

      var allTaxable = '/api/admin/reports/travel-expence/taxable?authtoken=' + Appregistry.models.person.get('authtoken') + '&from=' + from + '&to=' + to
      var allTaxableDom = $('<a>Lataa veronalaiset matkalaskut</a>')
      allTaxableDom.attr('href', allTaxable)
      this.$el.find('.taxable-container').html(allTaxableDom)

    }
  },
})


var alvBindings = {
  alv: { selector: '[name=alv]', converter: converter.bigPercent },
  alvTotal: { selector: '[name=alvTotal]', converter: converter.euroConverter },
  price: { selector: '[name=price]', converter: converter.euroConverter },
}

var reportBindigs = {
  amount: { selector: '[name=amount]', converter: converter.euroConverter },
  alvtotal: { selector: '[name=alvtotal]', converter: converter.euroConverter },
  oskamount: { selector: '[name=oskamount]', converter: converter.euroConverter },
  //oskPurchases: {selector: '[name=oskPurchases]', converter: converter.euroConverter},
  fulldaycompensationamount: { selector: '[name=fulldaycompensationamount]', converter: converter.euroConverter },
  partialdaycompensationamount: {
    selector: '[name=partialdaycompensationamount]',
    converter: converter.euroConverter,
  },
  // lunch_compensationamount: {selector: '[name=lunch_compensationamount]', converter: converter.euroConverter},
  othercost: { selector: '[name=othercost]', converter: converter.euroConverter },
  travelcost: { selector: '[name=travelcost]', converter: converter.euroConverter },
  kmreimbursementamount: { selector: '[name=kmreimbursementamount]', converter: converter.euroConverter },
  foreignAllowance: { selector: '[name=foreignAllowance]', converter: converter.euroConverter },
  total_reimbursements: { selector: '[name=total_reimbursements]', converter: converter.euroConverter },
  accidentInsuranceAmount: { selector: '[name=accidentInsuranceAmount]', converter: converter.euroConverter },
  /*
  tyELAmount: {selector: '[name=tyELAmount]', converter: converter.euroConverter},
  unemploymentInsuranceAmount: {
      selector: '[name=unemploymentInsuranceAmount]',
      converter: converter.euroConverter
  },
  employercoststotal: {selector: '[name=employercoststotal]', converter: converter.euroConverter},
   */
  sotuamount: { selector: '[name=sotuamount]', converter: converter.euroConverter },

  taxamount: { selector: '[name=taxamount]', converter: converter.euroConverter },
  personTyElamount: { selector: '[name=personTyElamount]', converter: converter.euroConverter },
  /*
  personunemploymentInsuranceamount: {
      selector: '[name=personunemploymentInsuranceamount]',
      converter: converter.euroConverter
  },
  holidaybonusamount: {selector: '[name=holidaybonusamount]', converter: converter.euroConverter},
   */
  netto: { selector: '[name=netto]', converter: converter.euroConverter },
  // advancePayments: {selector: '[name=advancePayments]', converter: converter.euroConverter},
  taxableamount: { selector: '[name=taxableamount]', converter: converter.euroConverter },
  oskamountWithAlv: { selector: '[name=oskamountWithAlv]', converter: converter.euroConverter },
  osk_cost_1: { selector: '[name=osk_cost_1]', converter: converter.euroConverter },
  osk_cost_2: { selector: '[name=osk_cost_2]', converter: converter.euroConverter },
  osk_cost_3: { selector: '[name=osk_cost_3]', converter: converter.euroConverter },
  osk_cost_4: { selector: '[name=osk_cost_4]', converter: converter.euroConverter },
  alvamount: { selector: '[name=alvamount]', converter: converter.euroConverter },
  /*
  employeeTyELAndUnemploymentIncurance: {
      selector: '[name=employeeTyELAndUnemploymentIncurance]',
      converter: converter.euroConverter
  },
  employerTyELAndUnemploymentIncurance: {
      selector: '[name=employerTyELAndUnemploymentIncurance]',
      converter: converter.euroConverter
  },
   */
  // salary: {selector: '[name=salary]', converter: converter.euroConverter},
  hotelcost: { selector: '[name=hotelcost]', converter: converter.euroConverter },
  totalsalary: { selector: '[name=totalsalary]', converter: converter.euroConverter },
  reduceFromNetto: { selector: '[name=reduceFromNetto]', converter: converter.euroConverter },
  // taxableReimbursements: {selector: '[name=taxableReimbursements]', converter: converter.euroConverter},
  // taxFreeReimbursements: {selector: '[name=taxFreeReimbursements]', converter: converter.euroConverter},
  //arvonlisavero: { selector: '[name=arvonlisavero]', converter: converter.euroConverter },
  alvToPay: { selector: '[name=alvToPay]', converter: converter.euroConverter },
  billed_kmreimbursementamount: {
    selector: '[name=billed_kmreimbursementamount]',
    converter: converter.euroConverter,
  },
  billed_fulldaycompensationamount: {
    selector: '[name=billed_fulldaycompensationamount]',
    converter: converter.euroConverter,
  },
  billed_partialdaycompensationamount: {
    selector: '[name=billed_partialdaycompensationamount]',
    converter: converter.euroConverter,
  },
  billed_foreignAllowanceamount: {
    selector: '[name=billed_foreignAllowanceamount]',
    converter: converter.euroConverter,
  },
  billed_lunchcompensationamount: {
    selector: '[name=billed_lunchcompensationamount]',
    converter: converter.euroConverter,
  },
  billed_hotelcost_alv: { selector: '[name=billed_hotelcost_alv]', converter: converter.euroConverter },
  billed_hotelcost_without_alv: { selector: '[name=billed_hotelcost_without_alv]', converter: converter.euroConverter },
  billed_hotelcost: { selector: '[name=billed_hotelcost]', converter: converter.euroConverter },
  billed_travelcost_alv: { selector: '[name=billed_travelcost_alv]', converter: converter.euroConverter },
  billed_travelcost_without_alv: {
    selector: '[name=billed_travelcost_without_alv]',
    converter: converter.euroConverter,
  },
  billed_travelcost: { selector: '[name=billed_travelcost]', converter: converter.euroConverter },
  billed_othercost: { selector: '[name=billed_othercost]', converter: converter.euroConverter },
  billed_othercost_alv: { selector: '[name=billed_othercost_alv]', converter: converter.euroConverter },
  billed_othercost_without_alv: { selector: '[name=billed_othercost_without_alv]', converter: converter.euroConverter },
  billedTotal: { selector: '[name=billedTotal]', converter: converter.euroConverter },
  billedTotalAlv: { selector: '[name=billedTotalAlv]', converter: converter.euroConverter },
  billedTotal_without_alv: { selector: '[name=billedTotal_without_alv]', converter: converter.euroConverter },


}

export default adminReporteView

