import * as React from 'react'
import { NumericFormat } from 'react-number-format'
import { Controller } from 'react-hook-form'

export const ControlledNumber = ({
  errors,
  name,
  decimalScale,
  validate,
  control,
  className,
  inputStyles,
  isAllowed,
  onBlur,
}) => {
  const err = errors ? errors[name] : {}
  const error = err?.type ? 'border-red-500 border-1' : ''
  return (
    <Controller
      render={({ field: { onChange, name, value } }) => (
        <NumericFormat
          className={`${error} ${inputStyles} ${className}`}
          name={name}
          isAllowed={isAllowed}
          value={value}
          decimalScale={decimalScale}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      onBlur={onBlur}
      name={name}
      rules={validate}
      control={control}
    />
  )
}
