import peopleTemplate from 'templates/admin/marionette/people/peopleItem.html'

var peopleItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    var person = serialized.person.toJSON()
    var personSettings = serialized.person.get('personSettings').toJSON()
    return _.template(peopleTemplate)({
      person: person,
      personSettings: personSettings,
      items: serialized,
    })
  },
  tagName: 'tr',
  initialize: function() {
  },
  modelEvents: {
    'changedSuccess': 'modelChanged',
  },
  modelChanged: function() {
    this.render()
  },

})

export default peopleItemView

