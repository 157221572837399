import registerTemplate from 'js/views/register/register.html'
import modelBinder from 'backbone.modelbinder'

var newPersonModel = Backbone.Model.extend({
  validation: {
    firstName: {
      required: true,
      msg: 'Ei saa olla tyhjä',
    },
    lastName: {
      required: true,
      msg: 'Ei saa olla tyhjä',
    },
    email: {
      required: true,
      msg: 'Syötä oikea email',
      pattern: 'email',
    },
    address: {
      required: true,
      msg: 'Ei saa olla tyhjä',

    },
    postalcode: {
      required: true,
      msg: 'Ei saa olla tyhjä',

    },
    phone: {
      required: true,
      msg: 'Ei saa olla tyhjä',

    },
    password: {
      required: true,
      minLength: 6,
      msg: 'Vähintään 6 merkkiä pitkä',
    },
  },
  url: '/api/registration',
})

var registerView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(registerTemplate)({
      items: serialized,
    })
  },
  className: 'row row-fluid',
  initialize: function() {
    this.model = new newPersonModel()
    this.modelBinder = new modelBinder()
    Backbone.Validation.bind(this)
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
    Backbone.Validation.unbind(this)
  },
  events: {
    'click .registerNow': 'registerNow',
    'submit form': 'submit',

  },
  submit: function(event) {
    event.preventDefault()
  },
  registerNow: function(event) {
    event.preventDefault()
    if (this.model.isValid(true)) {
      this.model.save().success(function(response) {
        Backbone.history.navigate('email', { trigger: true })
      }).error(function(response) {
        var response = response.responseJSON
        var element = $('[name=' + response.errorField + ']')
        element.addClass('invalid')
        var div = $('<div></div>')
          .addClass('error-element')
          .html(response.message)
        element.after(div)
      })
    }
  },
})
export default registerView

