import Backbone from 'backbone'
import 'backbone.paginator'

var contractorModel = Backbone.Model.extend({})
var collection = Backbone.Collection.extend({
  model: contractorModel,
  url: 'api/admin/contractor',
})

export default collection

