import React from 'react'
import { Controller } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'

export const NinjouDatePicker = (props) => {
  const { control, name, className} = props
  const formatSelected = (value) => {
    if (typeof value === 'string') {
      return DateTime.fromFormat(value, 'dd.MM.yyyy').toJSDate()
    }
    return value
  }
  const parseDate = (e) => DateTime.fromJSDate(e).toFormat('dd.MM.yyyy')
  return (
    <Controller
      // defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field }) => (
        <ReactDatePicker
          className={className}
          dateFormat="dd.MM.yyyy"
          selected={formatSelected(field.value)}
          onChange={(e) => field.onChange(parseDate(e))}
        />
      )}
    />
  )
}