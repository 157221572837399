import employerCostTemplate from 'templates/admin/settings/employercost.html'
import converter from 'libs/converter'

var compiledTemplate = _.template(employerCostTemplate)

// view for single billableitem
var employerCostView = Backbone.View.extend({
  initialize: function() {
    this._modelBinder = new Backbone.ModelBinder()
    this.model.bind('change', this.saveModel, this)
  },
  events: {},
  render: function() {
    $(this.el).html(compiledTemplate)
    this.setContect()
    return this
  },
  setContect: function() {
    var costBindings = {
      name: '[name=name]',
      value: { selector: '[name=value]', converter: converter.percentConverter },
    }
    this._modelBinder.bind(this.model, this.el, costBindings)
  },
  saveModel: function() {
    this.model.save(null, { silent: true })
  },

})
export default employerCostView

