import customerTemplate from 'templates/admin/marionette/sharedcustomers/customer-table-item.html'

var sharedCustomersItemView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(customerTemplate)({
      items: serialized,
    })
  },
  tagName: 'tr',
  onRender: function() {
    $('.ajax-loader').hide()
    return this
  },
})

export default sharedCustomersItemView

