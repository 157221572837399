import * as React from 'react'
import spinner from './loading.webp'

const Loading = ({ loading }) => {
  if (!loading) return undefined

  return <img style={{ maxHeight: '30px' }} className="mr-4" src={spinner} alt="Loading" />
}

export default Loading
