import Backbone from 'backbone'

var personModel = Backbone.Model.extend({
  urlRoot: '/api/admin/people',
  initialize: function() {
  },
  validation: {
    firstName: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
    lastName: {
      required: true,
      msg: 'Pakollinen kenttä',
    },
  },
})
export default personModel


