import salaryListItemView from 'views/admin/salaries/salaryListItemView'
import salaryList from 'templates/admin/salaries/salary-list.html'


/*
 compisite view, renders all salaries
 */
var adminBillsView = Backbone.Marionette.CompositeView.extend({
  childView: salaryListItemView,
  childViewContainer: '#salaries-container',
  template: function(serialized) {
    return _.template(salaryList, {
      items: serialized,
    }, { items: serialized })
  },
  initialize: function() {
    this.sortDebounce = _.debounce(this.doSortNow, 300)
  },
  events: {},
  collectionEvents: {
    'change': 'changed',
    'remove': 'removeModel',
    'destroy': 'changed',
  },
  changed: function() {
    this.render()
  },
  appendHtml: function(cv,
                       iv,
                       index) {
    var $container = this.getItemViewContainer(cv)
    if (this.reverse) {
      $container.prepend(iv.el)
      this.reverse = !this.reverse
    } else {
      $container.append(iv.el)
    }
  },
  removeModel: function(model) {
    //this.onRender()
  },
  onShow: function() {
    this.collection.fetch()
  },
  onDestroy: function() {
    this.collection.reset()
  },
  onAddChild: function() {
    this.sortDebounce()
  },
  onRender: function() {
  },
  doSortNow: function() {
    $('.table').tablesorter()
  },
})
export default adminBillsView
    
