import Appregistry from 'libs/appregistry/appregistry'
import billContractorCompositeView from './billContractorCompositeView'
import modelBinder from 'backbone.modelbinder'
import deleteBillModal from 'templates/bill/delete-bill-modal.html'
import billTemplate from 'templates/admin/marionette/bills/single-bill.html'
import peopleSelectView from 'views/admin/marionette/peopleSelectView'
import billableItemCompositeView from 'views/admin/marionette/billableItem/billableItemCompositeView'
import converter from 'libs/converter'

var statusUpdatesCollection = Backbone.Collection.extend({
  model: Backbone.Model.extend({}),
})
/*
   Single bill biew
   */
export default Backbone.Marionette.View.extend({
  template: function(model) {
    var obj = model.sharedCustomer.toJSON()
    var cust = {}
    cust[obj.id] = obj
    _.forEach(model.person.get('sharedCustomers').toJSON(), function(customer) {
      cust[customer.id] = customer
    })
    var customers = _.values(cust)
    let isMaventa = (model.maventaResponses || []).length > 0
    return _.template(billTemplate)({
      sharedCustomers: customers,
      sharedCustomer: model.sharedCustomer.toJSON(),
      items: model,
      person: Appregistry.models.person,
      authtoken: Appregistry.models.person.get('authtoken'),
      isMaventa
    })
  },
  tagName: 'div',
  className: 'col-md-12',
  regions: {
    people: '.people',
    selectedCustomer: '.selected-customer',
    billableItems: '.billable-items',
    contractorItems: '.contractor-items',
  },
  initialize: function() {
    this.modelBinder = new modelBinder()
    this.billableItemCompositeView = new billableItemCompositeView({
      collection: this.model.get('billableitems'),
    })
    this.peopleSelectView = new peopleSelectView({
      collection: Appregistry.peopleCollection,
    })

    this.contractorView = new billContractorCompositeView({
      collection: this.model.get('contractorBillableItems'),
    })
    this.model.get('billableitems').billId = this.model.get('id')
    this.model.on('change', this.modelChanged, this)
    this.model.get('billableitems').on('change', this.billableItemChanged, this)
    this.statusUpdate = new statusUpdatesCollection({
      url: 'api/admin/ropo/updates/' + this.model.id,
    })
  },
  events: {
    'click .open_bill_item': 'openBill',
    'click .new-bill-billableitem': 'newBillableItem',
    'click .delete_bill_item': 'deleteBill',
    'click .destroyBill': 'destroyBillableItem',
    'click .date-input': 'openDateInput',
    'click .send-to-billing': 'sendToRopo',
    'click .hyvitys': 'sendRopoHyvitys',
    'click .showRopo': 'showRopo',
    'click .sendToMaventa': 'sendToMaventa',
  },
  showRopo: function(e) {
    e.preventDefault()
    var urlRoot = 'api/admin/bills/' + this.model.id + '/ropo'
    var a = Backbone.Model.extend({
      url: urlRoot,
    })
    var model = new a({})
    model.fetch({
      success: function(changed, data, b) {
        console.log(data)
      },
    })
  },
  sendToMaventa: function() {
    if (confirm('Haluatko varmasti käyttää maventaa ja rikkoa maailman?')) {
      var urlRoot = 'api/admin/bills/' + this.model.id + '/sendtomaventa'
      var a = Backbone.Model.extend({
        url: urlRoot,
      })
      var sendNow = new a({})
      var self = this
      sendNow.save(null, {
        success: function() {
          self.model.fetch({
            success: function() {
              self.$el.find('.state-select').trigger('change')
            },
          })
        },
        error: function() {
          self.model.fetch()
        },
      })
    }
  },
  sendRopoHyvitys: function() {
    if (confirm('Haluatko varmasti hyvittää')) {
      var urlRoot = 'api/admin/bills/' + this.model.id + '/sendtohyvitys'
      var a = Backbone.Model.extend({
        url: urlRoot,
      })
      var sendNow = new a({})
      var self = this
      sendNow.save(null, {
        success: function() {
          self.model.fetch({
            success: function() {
              self.$el.find('.state-select').trigger('change')
            },
          })
        },
        error: function() {
          self.model.fetch()
        },
      })
    }
  },
  sendToRopo: function() {
    if (confirm('haluatko varmasti lähettää ropo24?')) {
      var urlRoot = 'api/admin/bills/' + this.model.id + '/sendtobilling'
      var a = Backbone.Model.extend({
        url: urlRoot,
      })
      var sendNow = new a({})
      var self = this
      sendNow.save(null, {
        success: function() {
          self.model.fetch({
            success: function() {
              self.$el.find('.state-select').trigger('change')
            },
          })
        },
        error: function(error) {
          console.log("erroria", error)
          self.model.fetch()
        },
      })
    }
  },
  openDateInput: function() {
    this.$el.find('.date-input').stop()
    this.$el.find('.date-input').pickadate(window.helpers.pickadateOptions)
  },
  onDestroy: function() {
    this.model.off('change')
    if (this.billableItems) {
      this.billableItems.empty()
    }
    this.model.get('billableitems').off()
    this.modelBinder.unbind()
  },
  billableItemChanged: function() {
    var totalpriceWithAlv = this.model
      .get('billableitems')
      .pluck('totalpriceWithAlv')
      .reduce(function(a, b) {
        return parseFloat(a) + parseFloat(b)
      })
    this.model.set({
      totalpriceWithAlv: totalpriceWithAlv,
    })
  },
  saveModel: function() {
    this.model.save()
  },
  modelChanged: function(model, event) {
    if (event.changeSource === 'ModelBinder' && this.model.isValid(true) && this.relevantChanges()) {
      this.saveModel()
    }
  },
  relevantChanges: function() {
    var relevant = false
    _.each(Object.keys(this.model.changed), function(key) {
      if (key !== 'lastSaved' && key !== 'totalpriceWithAlv' && key !== 'billableitems') {
        relevant = true
      }
    })
    return relevant
  },
  openBill: function() {
    this.model.get('billableitems').fetch()
    this.model.set(
      {
        newItem: false,
      },
      {
        silent: true,
      },
    )

    this.getRegion('billableItems').show(this.billableItemCompositeView)
    //this.getRegion('contractorItems').show(this.contractorView)
    this.$el.find('.billableitems-container').toggle()
    this.bindModel()
    //this.statusUpdate.fetch()
  },
  onRender: function() {
    this.getRegion('people').show(this.peopleSelectView)
    this.bindModel()
    if (!Appregistry.isMobile) {
      this.$el.find('select').select2(Object.assign(Appregistry.selectOpts, { dropdownParent: this.$el }))
    }
    Backbone.Validation.bind(this)

    this.$el.append(deleteBillModal)
    return this
  },
  newBillableItem: function() {
    this.model.get('billableitems').add({
      person: this.model.get('person'),
    })
    this.model.save()
  },
  deleteBill: function() {
    this.$el.find('.deleteBillModal').modal('show')
  },
  destroyBillableItem: function() {
    var self = this
    this.$el.find('.deleteBillModal').on('hidden.bs.modal', function() {
      self.model.destroy().success(function() {
        $.jGrowl('Lasku poistettu', {
          life: 30000,
        })
      })
    })
    this.$el.find('.deleteBillModal').modal('hide')
  },
  bindModel: function() {
    var binds = {
      person: [
        {
          selector: '[name=person]',
          converter: converter.personConverter,
        },
        {
          selector: '[name=person]',
          elAttribute: 'enabled',
          converter: this.personDisabledConverter,
        },
      ],
      billingdate: '[name=billingdate]',
      lastSaved: {
        selector: '[name=lastSaved]',
        converter: converter.timestampConverter,
      },
      totalpriceWithAlv: {
        selector: '[name=totalpriceWithAlv]',
        converter: converter.euroConverter,
      },
      openamount: {
        selector: '[name=openamount]',
        converter: converter.euroConverter,
      },
      sharedCustomer: {
        selector: '[name=sharedCustomer]',
        converter: this.sharedCustomerConverter,
      },
      payDate: '[name=payDate]',
      specificationDetails: '[name=specificationDetails ]',
      id: '[name=id ]',
      newItem: {
        selector: '[name=newItem]',
        elAttribute: 'class',
        converter: this.newItemClassConverter,
      },
      openRequest: {
        selector: '[name=openRequest]',
        elAttribute: 'class',
        converter: this.openReqClassConverter,
      },
      inverseALV: {
        selector: '[name=inverseALV]',
      },
      billNumber: '[name=billNumber ]',
      billType: '[name=billType ]',
      exactDueDate: '[name=exactDueDate ]',
      separateReimbursements: { selector: '[name=separateReimbursements]' },
      billReimbursementsFromCustomer: { selector: '[name=billReimbursementsFromCustomer]' },
      sendNoteRequestDate: [
        {
          selector: '[name=sendNoteRequestDate]',
        },
        {
          selector: '.sendNoteRequestContainer',
          elAttribute: 'class',
          converter: converter.noteRequestConverter,
        },
      ],
      state: [
        {
          selector: '[name=state]',
        },
        {
          selector: '.disable-not-open',
          elAttribute: 'disabled',
          converter: this.disableNotOpen,
        },
        {
          selector: 'button',
          elAttribute: 'disabled',
          converter: this.disableNotOpen,
        },
      ],
      yourcode: '[name=yourcode]',
      vatZeroReason: '[name=vatZeroReason]',
      alv: '[name=alv]',
      useSameAlv: '[name=useSameAlv]',
    }
    if (this.model) {
      this.modelBinder.bind(this.model, this.el, binds)
    }
  },
  newItemClassConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value === true) {
        return 'new-shiny-item'
      }
      return ''
    }
  },
  openReqClassConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value === true) {
        return ''
      } else {
        return 'hidden'
      }
    }
  },
  sharedCustomerConverter: function(direction, value, el, model) {
    if (direction === 'ModelToView') {
      if (value) {
        return value.id
      }
    } else {
      var model = model.get('person').get('sharedCustomers').get(value)
      if (model.toJSON) {
        //return model.toJSON()
      }
      return model
    }
  },
  personDisabledConverter: function(direction, value) {
    if (direction === 'ModelToView') {
      if (value) {
        return false
      }
    }
    return true
  },
  disableNotOpen: function(direction, value) {
    if (direction === 'ModelToView') {
      if (value === 'OPEN') {
        return false
      }
    }
    return true
  },
})

