import bankModel from 'views/admin/marionette/bank/bankModel'
import 'backbone.paginator'

var bankCollection = Backbone.PageableCollection.extend({
  queryParams: {
    currentPage: 'page',
    pageSize: 'limit',
  },
  state: {
    pageSize: 50,
    // You can use 0-based or 1-based indices, the default is 1-based.
    // You can set to 0-based by setting ``firstPage`` to 0.
    firstPage: 0,
  },
  url: '/api/admin/bank',
  model: bankModel,
  initialize: function() {
  },
})

export default bankCollection

