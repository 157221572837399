import assignedBillableItemView from 'views/admin/salaries/assignedBillableItemView'
import freeItemsContainerHtml from 'templates/admin/salaries/assigned-items-container.html'

export default Backbone.Marionette.CompositeView.extend({
  template: freeItemsContainerHtml,
  childView: assignedBillableItemView,
  childViewContainer: function() {
    return '.assigned-items'
  },
})

