import confirmed from 'templates/password/changePassword.html'
import modelBinder from 'backbone.modelbinder'

var changeEmail = Backbone.Model.extend({
  url: '/api/password/change',
})

var eulaView = Backbone.Marionette.View.extend({
  template: function(serialized) {
    return _.template(confirmed, {
      items: serialized,
    }, { items: serialized })
  },
  className: 'row row-fluid',
  initialize: function(params) {
    this.modelBinder = new modelBinder()
    this.model = new changeEmail({ registrationToken: params.token })
  },
  onRender: function() {
    this.modelBinder.bind(this.model, this.el)
  },
  onDestroy: function() {
    this.modelBinder.unbind()
  },
  events: {
    'click .changePassword': 'changePassword',
  },
  changePassword: function(e) {
    e.preventDefault()
    this.model.save()
      .success(function() {
        Backbone.history.navigate('password/changed', { trigger: true })
      })
      .error(function(response) {
        _.each(response.responseJSON, function(error) {
          error = error || {}
          var element = $('[name=' + error.errorField || 'password' + ']')
          element.addClass('invalid')
          var div = $('<div></div>')
            .addClass('error-element')
            .html(error.message)
          element.after(div)
        })


      })

  },
})
export default eulaView

