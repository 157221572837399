import freebillableitem from 'templates/admin/salaries/assignedBillableitem.html'
import converter from 'libs/converter'
import modelBinder from 'backbone.modelbinder'

var assignedBillableItemView = Backbone.Marionette.View.extend({
  tagName: 'tr',
  className: function() {
    var type = this.model.get('rowType')
    if (type === 'EXT_CONTRACTOR_ITEM') {
      return 'ext-contractor-row'
    } else if (type === 'CONTRACTOR_ITEM') {
      return 'contractor-row'
    }
    return ''
  },
  template: function(serialized) {
    return _.template(freebillableitem)({
      model: serialized,
    })
  },
})
export default assignedBillableItemView

