import freeBillableItemView from 'views/admin/salaries/freeBillableItemView'
import freeItemsContainerHtml from 'templates/admin/salaries/free-items-container.html'

export default Backbone.Marionette.CompositeView.extend({
  template: freeItemsContainerHtml,
  childView: freeBillableItemView,
  childViewContainer: function() {
    return '.here-maybe'
  },
  onRender: function() {
    this.picker = this.$el.find('.date-picker').pickadate(window.helpers.pickadateOptions)
  },
  onClose: function() {
    this.picker.stop()
  },
})

