import React, { useState } from 'react'
import { TesSideOver } from './tes-side-over'
import { RemunerationSideOver } from './remuneration-side-over'

export default function TesList({ data, token, updateData, addNewTes, deleteTes, loading }) {
  const [selectedTes, setSelectedTes] = useState([])
  const [selectedRemuneration, setSelectedRemuneration] = useState({})

  const selectTes = (e, tes) => {
    e.preventDefault()
    setSelectedTes(tes)
  }
  const deleteTesNow = (e, tes) => {
    e.preventDefault()
    const reply = confirm(`Poistetaanko ${tes.name}`);
    if( reply ) {
      deleteTes(tes)
    }
  }
  const selectRemuneration = (tes, remu) => {
    setSelectedRemuneration(remu)
    setSelectedTes(remu)
  }

  const addNew = async (e) => {
    e.preventDefault()
    const tessed = await addNewTes()
    setSelectedTes(tessed)
  }

  return (
    <div>
      <TesSideOver loading={loading} selectedTes={selectedTes} setSelectedTes={setSelectedTes} updateData={updateData} token={token} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">TES listaus</h1>
            <p className="mt-2 text-sm text-gray-700">Listaus lisätyistä TES:a</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={addNew}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Lisää TES
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Nimi
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      lisät
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((tes) => (
                    <tr key={tes.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {tes.name}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {tes.remunerations?.map((remu) => (
                          <p>
                            <a onClick={(r) => selectRemuneration(tes, remu)}>
                              {remu.allowanceName}, {remu.validFromDate} - {remu.validToDate}{' '}
                            </a>
                          </p>
                        ))}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a onClick={(e) => selectTes(e, tes)} className="text-indigo-600 hover:text-indigo-900">
                          Muokkaa
                          <span className="sr-only">Muokkaa, {tes.name}</span>
                        </a>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a onClick={(e) => deleteTesNow(e, tes)} className="text-indigo-600 hover:text-indigo-900">
                          Poista
                          <span className="sr-only">Poista, {tes.name}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
